import React, { useState } from "react";
import styled from "styled-components/macro";
import { useInterfaceContext } from "@contexts/interfaceContext";
import clouds from "@assets/clouds.png";
import stars from "@assets/stars.png";
import Brightness2Icon from "@mui/icons-material/Brightness2";
import theme from "styled-theming";
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes,
  shadow,
} from "@styles/vars";

const ToggleWrapper = styled.div`
  .toggle-container {
    width: 140px;
    height: 64px;
    position: relative;
    cursor: pointer;
    border-radius: 32px;
  }

  .stars_img {
    position: absolute;
    right: 75px;
    z-index: 2;
    height: 40px;
    width: 40px;
    top: 10px;
    transition: 0.9s ease;
  }
  .clouds_img {
    position: absolute;
    right: 0px;
    z-index: 2;
    height: 40px;
    width: 100px;
    top: 24px;
    transition: 0.9s ease;
  }

  .tube {
    width: 140px;
    height: 64px;
    background-color: #ccc;
    border-radius: 32px;
    position: absolute;
    transition: background-color 0.9s ease;
  }

  .circle {
    /* width: 64px;
    height: 64px; */
    width: 52px;
    height: 52px;
    background: linear-gradient(138deg, #ffd338 12.41%, #ff9d42 91.44%);
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 6px;
    transition: left 0.9s ease;
  }

  .on .circle {
    left: 76px;
    background: #fafafc;

    .moon {
      scale: 2;
      text-align: start;
      position: absolute;
      top: 15px;
      right: 7px;
      transform: rotate(30deg);
    }
  }

  .on .tube {
    background-color: #222257;
  }

  .off .tube {
    background-color: #7ba7fe;
  }

  ${breakpoints.mobileM} {
  }

  ${breakpoints.mobileM} {
    .toggle-container {
      width: 80px;
      height: 44px;
      border-radius: 22px;
    }

    .stars_img {
      position: absolute;
      right: 75px;
      z-index: 2;
      height: 40px;
      width: 40px;
      top: 10px;
      transition: 0.9s ease;
      /* display: none; */

      right: 45px;
      height: 25px;
      width: 25px;
      top: 10px;
    }
    .clouds_img {
      position: absolute;
      right: 0px;
      z-index: 2;
      height: 40px;
      width: 100px;
      top: 24px;
      transition: 0.9s ease;
      /* display: none; */
      height: 30px;
      width: 60px;
      top: 15px;
    }

    .tube {
      width: 80px;
      height: 44px;
      border-radius: 22px;
      transition: background-color 0.9s ease;
    }

    .circle {
      width: 30px;
      height: 30px;
      top: 6px;
      left: 6px;
      transition: left 0.9s ease;
    }

    .on .circle {
      left: 70px;
      background: #fafafc;

      .moon {
        scale: 1.3;
        text-align: start;
        position: absolute;
        top: 5px;
        right: 30px;
        transform: rotate(30deg);
      }
    }
  }
  ${breakpoints.laptop} {
  }
  ${breakpoints.laptopL} {
    .toggle-container {
      width: 140px;
      height: 64px;
      position: relative;
      cursor: pointer;
      border-radius: 32px;
    }

    .stars_img {
      position: absolute;
      right: 75px;
      z-index: 2;
      height: 40px;
      width: 40px;
      top: 10px;
      transition: 0.9s ease;
    }
    .clouds_img {
      position: absolute;
      right: 0px;
      z-index: 2;
      height: 40px;
      width: 100px;
      top: 24px;
      transition: 0.9s ease;
    }

    .tube {
      width: 140px;
      height: 64px;
      background-color: #ccc;
      border-radius: 32px;
      position: absolute;
      transition: background-color 0.9s ease;
    }

    .circle {
      width: 52px;
      height: 52px;
      background: linear-gradient(138deg, #ffd338 12.41%, #ff9d42 91.44%);
      border-radius: 50%;
      position: absolute;
      top: 6px;
      left: 6px;
      transition: left 0.9s ease;
    }

    .on .circle {
      left: 76px;
      background: #fafafc;
      cursor: pointer;
      .moon {
        scale: 2;
        text-align: start;
        position: absolute;
        top: 15px;
        right: 7px;
        transform: rotate(30deg);
        cursor: pointer;
      }
    }
  }
`;

const ThemeToggleSwitch = () => {
  const { theme, toggleTheme } = useInterfaceContext();
  // const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    theme.toggleTheme();
  };

  return (
    <ToggleWrapper>
      <div
        className={`toggle-container ${theme.isDarkMode ? "on" : "off"}`}
        onClick={handleToggle}
      >
        {theme.isDarkMode === true ? (
          <img className="stars_img" src={stars} alt="stars" />
        ) : (
          <img className="clouds_img" src={clouds} alt="clouds" />
        )}

        <div className="tube">
          {theme.isDarkMode === false ? (
            <div className="circle"></div>
          ) : (
            <div className="circle" style={{ background: "none" }}>
              <Brightness2Icon className="moon" />
            </div>
          )}
        </div>
      </div>
    </ToggleWrapper>
  );
};

export default ThemeToggleSwitch;
