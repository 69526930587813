import React, { useState, useEffect } from "react";
import styled from "styled-components";
import quotesSvg from "@assets/quotes_svg.png";
import login_quote_bg from "@assets/login_quote_bg.jpeg";
import { breakpoints } from "@styles/vars";

const QuoteCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  //   text-align: left;
  height: 100%;
  margin: 0 auto;
  // transition: opacity 0.5s ease-out;
  background: url(${login_quote_bg});
  background-size: cover;

  .quotesSvg {
    width: 99px;
    height: 63px;
    position: relative;
    top: -28px;
    left: -167px;
  }
  ${breakpoints.tablet} {
    .quotesSvg {
      width: 66px;
      height: 43px;
      position: relative;
      top: -18px;
      left: -137px;
    }
  }

  ${breakpoints.laptop} {
    .quotesSvg {
      width: 99px;
      height: 63px;
      position: relative;
      top: -28px;
      left: -167px;
    }
  }

  ${breakpoints.desktop} {
    .quotesSvg {
      width: 154px;
      height: 100px;
      left: -235px;
    }
  }
`;
const QuoteContainer = styled.div`
  width: 387px;
  height: 174px;
  text-align: left;

  ${breakpoints.tablet} {
    width: 300px;
    height: 174px;
  }

  ${breakpoints.laptop} {
    width: 387px;
    height: 174px;
  }

  ${breakpoints.desktop} {
    width: 563px;
    height: 282px;
  }
`;

const QuoteText = styled.p`
  font-size: 24px;
  color: #000;
  font-weight: 600;
  //   font-style:italic;
  letter-spacing: 0.36px;
  transition: opacity 0.5s ease-out;

  &.hidden {
    opacity: 0;
  }

  ${breakpoints.tablet} {
    font-size: 22px;
  }

  ${breakpoints.laptop} {
    font-size: 24px;
  }

  ${breakpoints.desktop} {
    font-size: 36px;
  }
`;

const QuoteCard = ({ quotes }) => {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const changeQuote = () => {
      setIsHidden(true);
      setTimeout(() => {
        const newIndex = Math.floor(Math.random() * quotes.length);
        setCurrentQuoteIndex(newIndex);
        setIsHidden(false);
      }, 500);
    };
    changeQuote();

    const quoteInterval = setInterval(changeQuote, 10000);

    return () => {
      clearInterval(quoteInterval);
    };
  }, [quotes]);

  return (
    <QuoteCardContainer>
      {/* {label === "login" && ( */}
      <img src={quotesSvg} alt="quotes" className="quotesSvg" />
      {/* )} */}
      <QuoteContainer>
        <QuoteText className={isHidden ? "hidden" : ""}>
          {quotes[currentQuoteIndex]}
        </QuoteText>
      </QuoteContainer>
    </QuoteCardContainer>
  );
};

export default QuoteCard;
