// styled components
import {
  Container,
  FlexContainer,
  Content,
  LoginSideImage,
  StyledForm,
  SignUpContainer,
  LoginLink,
  TeamSizeWrapper,
  PhoneWrapper,
  IntrestedInWrapper,
  CaptchaWrapper,
  SelectWrapper,
  PrivacyPolicyWrapper,
  PrivacyLink,
} from "./style";

// components
import { useState, useEffect } from "react";
import Btn from "@ui/Btn";
import Message from "@ui/Message";
import LoginFormInput from "@ui/LoginFormInput";
import LoginImage from "@assets/loginImage.jpg";
import signup_quote_bg from "@assets/singup_quote_bg.png";
import logoLight from "@assets/logo/logo-light.png";
import { useAuth } from "@contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import toastAlert from "@widgets/Dox/CustomToastAlert";
import Loader from "@ui/Dox/Loader";
import axios from "axios";
import { countryData } from "@widgets/Dox/UploadContactDetails/countryCode";
import { colors } from "@styles/vars";
// import SignUpFormInput from "@ui/LoginFormInput/signup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SignUpBtn from "@ui/Btn/signupBtn";
import QuoteCard from "../Login/quotesCard";
import { useRef } from "react";
import Select from "react-select";
import SignUpFormInput from "@ui/LoginFormInput/signup";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { height } from "@mui/system";

const initialUser = {
  name: "",
  email: "",
  companyName: "",
  // designation: "",
  teamSize: 1,
  // phoneNumber: { countryCode: "", number: "" },
  countryCode: "",
  number: "",
  captcha: "",
  interestedIn: [],
};

const signUpQuotes = [
  "Don't miss out on life's opportunities. Sign up today and open the door to endless possibilities.",
  "Your journey begins with a single click – sign up and explore the digital universe.",
  "The world is at your fingertips. Sign up now and embark on your digital voyage.",
  "Don't hesitate; take the leap! Sign up and be part of something extraordinary.",
  "Your future is just one sign-up away. What are you waiting for?",
];

const SignUp = () => {
  let [user, setUser] = useState(initialUser);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(user.countryCode);
  const [searchTerm, setSearchTerm] = useState("");
  const [verify, setVerify] = useState(false);
  const recaptchaRef = useRef();

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  const handleChangePrivacy = (event) => {
    setPrivacyChecked(event.target.checked);
  };
  const handleChangeEmails = (event) => {
    setEmailChecked(event.target.checked);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const countryOptions = countryData.map((country) => ({
    value: country.dial_code,
    label: `${country.dial_code} ${country.name}`,
  }));

  const filteredOptions = countryOptions.filter(
    (option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      option.value.includes(searchTerm)
  );

  const handleOptionClick = (option) => {
    if (selectedOptions.length > 0) {
      setError("");
    }
    if (selectedOptions.includes(option)) {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected !== option)
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const teamSizeOptions = ["1-2", "3-5", "6-10", "11+"];

  const labels = [
    "Invoices",
    "Bill of Lading",
    "Purchase Order",
    "Passport",
    "Driving License",
    "Others",
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (selectedOption) => {
    setError("");
    setSelectedCountry(selectedOption);
    setUser({ ...user, countryCode: selectedOption.value });
  };

  const changeHandler = (e) => {
    setError("");
    auth.setAuthError("");
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const verifyCaptcha = async (value) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (value) {
      const response = await axios.post(`${baseUrl}/auth/verifyRecaptcha`, {
        recaptchaResponse: value,
      });
      if (response.status === 200 && response.data.success) {
        setVerify(true);
      }
    } else {
      setVerify(false);
    }
  };
  const signUpHandler = async (e) => {
    e.preventDefault();
    var data = user.email;
    var atSign = data.indexOf("@");
    var dotSign = data.lastIndexOf(".");

    const emailDomainFull = data?.split("@")[1];
    const emailDomainShort = emailDomainFull?.split(".")[0];

    const forbiddenDomains = [
      "gmail",
      "yahoo",
      "hotmail",
      "outlook",
      "icloud",
      "aol",
      "protonmail",
      "yandex",
      "gmx",
      "me",
      "fastmail",
      "hushmail",
      "msn",
      "live",
      "ymail",
    ];

    if (
      !user.name ||
      !user.email ||
      !user.companyName ||
      !user.countryCode ||
      !user.number
      // || !user.teamSize
      // || selectedOptions.length === 0
    ) {
      setSuccess("");
      toastAlert("All fields are required*", "error");
    } else if (
      atSign < 1 ||
      dotSign < atSign + 2 ||
      dotSign + 2 >= data.length ||
      /[A-Z]/.test(user.email)
    ) {
      toastAlert("Please enter valid email", "error");
    } else if (forbiddenDomains.includes(emailDomainShort)) {
      toastAlert("Please sign up with an organisation email", "error");
    } else if (!verify) {
      toastAlert("Please check captcha", "error");
    } else if (!privacyChecked) {
      toastAlert(
        "Please check the privacy policy and license agreement",
        "error"
      );
    }
    //  else if (!emailChecked) {
    //   toastAlert("You must agree to receive promotional and marketing emails", "error");
    // }
    else {
      const { countryCode, number, ...newUser } = user;
      user = {
        ...newUser,
        phoneNumber: { countryCode: user.countryCode, number: user.number },
        interestedIn: selectedOptions,
      };
      setUser(user);
      // await auth.SignUp(user);
      try {
        setIsLoading(true);
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(`${baseUrl}/v1/auth/sign-up`, {
          ...user,
        });
        if (response.status === 200) {
          toastAlert(
            `Signup successful! Please check your email to verify your account`,
            "success"
          );
          await axios.post(`${baseUrl}/workflow/flows`);
          navigate("/auth/checkEmail", { replace: true });
          setUser(initialUser);
          setSelectedOptions([]);
          setSelectedCountry("");
          // Reset CAPTCHA after successful sign-up
          recaptchaRef.current.reset();
          setVerify(false);
          setEmailChecked(false);
          setPrivacyChecked(false);
        }
      } catch (e) {
        setUser(initialUser);
        setSelectedOptions([]);
        setSelectedCountry("");
        setSuccess("");
        // Reset CAPTCHA after successful sign-up
        recaptchaRef.current.reset();
        setVerify(false);
        setEmailChecked(false);
        setPrivacyChecked(false);
        toastAlert(e.response.data.message, "error");
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const roleNavigate = (role) => {
    // if (role === "test") {
    //     navigate("/", { replace: true });
    // } else if (role === "test1") {
    //     navigate("/profile", { replace: true });
    // }
    navigate("/dashboard/home", { replace: true });
  };

  useEffect(() => {
    auth.setAuthError("");
    if (auth.user) {
      roleNavigate(auth.user.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Get started with Docxster | Sign Up</title>
        <meta
          content="Get started with advanced intelligent document processing within seconds"
          name="description"
        />
        <meta
          content="Get started with Docxster | Sign Up"
          property="og:title"
        />
        <meta
          content="Get started with advanced intelligent document processing within seconds"
          property="og:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/6527d5e25fcb40892475493c/65fca16c29947062d3a695bc_Docxster%20Sign%20Up.png"
          property="og:image"
        />
        <meta
          content="Get started with Docxster | Sign Up"
          property="twitter:title"
        />
        <meta
          content="Get started with advanced intelligent document processing within seconds"
          property="twitter:description"
        />
        <meta
          content="https://uploads-ssl.webflow.com/6527d5e25fcb40892475493c/65fca16c29947062d3a695bc_Docxster%20Sign%20Up.png"
          property="twitter:image"
        />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta
          content="Lu4kXBNETjFrY50mTESF268m8i5NLag5DTe8SUEplYM"
          name="google-site-verification"
        />
      </Helmet>
      <Container>
        <Content style={{ overflow: "hidden" }}>
          {/* <FlexContainer className="left-image">
            <LoginSideImage
            src={LoginImage}
            alt="image"
            style={{ objectFit: "contain" }}
          />
            <QuoteCard quotes={signUpQuotes} />
          </FlexContainer> */}

          <div className="white-bg"></div>
          <FlexContainer>
            <img
              src={logoLight}
              alt="Docxster-logo"
              className="docxster_logo"
            />
            <SignUpContainer>
              <h1 style={{ marginLeft: "3px" }}>SignUp</h1>
              <p
                className="success"
                style={{ color: colors.green, marginBottom: "5px" }}
              >
                {success ? success : ""}
              </p>
              <p
                className="error"
                style={{ color: colors.red, marginBottom: "5px" }}
              >
                {error ? error : ""}
              </p>
              <Message className="error">
                {auth.authError ? auth.authError : ""}
              </Message>

              <StyledForm
                action="#"
                method="post"
                onSubmit={(e) => e.preventDefault()}
              >
                <label className="label">Name</label>
                <SignUpFormInput
                  id="name"
                  title="Name"
                  type="text"
                  placeholder="Name"
                  value={user.name}
                  handler={changeHandler}
                />
                <label className="label">Company Name</label>
                <SignUpFormInput
                  id="companyName"
                  title="Company Name"
                  type="text"
                  placeholder="Company Name"
                  value={user.companyName}
                  handler={changeHandler}
                />
                <label className="label">Email</label>
                <SignUpFormInput
                  id="email"
                  title="Email"
                  type="email"
                  placeholder="Email"
                  value={user.email}
                  handler={changeHandler}
                />

                <label className="label">Phone</label>
                <PhoneWrapper>
                  {/* <select
                  className="countryCode"
                  id="countryCode"
                  value={user.countryCode}
                  onChange={changeHandler}
                >
                  <option value="" className="firstOption">
                    Country
                  </option>
                  {countryData.map((country) => (
                    <option
                      key={country.name}
                      value={country.dial_code}
                      style={{
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          color: "red !important",
                        }}
                      >
                        {" "}
                        {country.dial_code} {country.name}
                      </span>
                    </option>
                  ))}
                </select> */}

                  <Select
                    className="countryCode"
                    id="countryCode"
                    options={filteredOptions}
                    isSearchable
                    placeholder="Country"
                    value={selectedCountry}
                    onChange={handleChange}
                  />

                  <input
                    className="phoneInput"
                    id="number"
                    title="Phone Number"
                    type="number"
                    placeholder="Phone Number"
                    value={user.number}
                    onChange={changeHandler}
                  />
                </PhoneWrapper>
                {/* <label className="label"> Interested In</label>
                <IntrestedInWrapper>
                  <SelectWrapper>
                    <div className="dropdown" ref={dropdownRef}>
                      <div className="dropdown-header" onClick={toggleDropdown}>
                        {selectedOptions.length > 0 ? (
                          <>
                            {selectedOptions.join(", ")}{" "}
                            <KeyboardArrowDownIcon className="arrow" />
                          </>
                        ) : (
                          <>
                            <span className="firstOption"> Interested In</span>
                            <KeyboardArrowDownIcon className="arrow" />
                          </>
                        )}
                      </div>
                      {isOpen && (
                        <div className="dropdown-options">
                          {labels.map((option) => (
                            <label key={option}>
                              <input
                                type="checkbox"
                                value={option}
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleOptionClick(option)}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </SelectWrapper>
                </IntrestedInWrapper>
                <label className="label">Size of Team</label>
                <TeamSizeWrapper>
                  <select
                    className="dropdown"
                    id="teamSize"
                    value={user.teamSize}
                    onChange={changeHandler}
                  >
                    <option value="">Team Size</option>
                    {teamSizeOptions.map((val, ind) => (
                      <option key={ind} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </TeamSizeWrapper> */}
                <CaptchaWrapper>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfE0NYpAAAAACHLOJMz1eH_mdjknt3QFZdx1qGv"
                    onChange={verifyCaptcha}
                  />
                </CaptchaWrapper>
                <PrivacyPolicyWrapper>
                  <div className="checkbox-container">
                    <input
                      className="checkbox"
                      type="checkbox"
                      {...label}
                      checked={privacyChecked}
                      onChange={handleChangePrivacy}
                    />
                  </div>
                  <div className="text-container">
                    I have read and agree to
                    <PrivacyLink to="https://www.docxster.com/privacy-policy">
                      {" "}
                      privacy policy{" "}
                    </PrivacyLink>
                    and
                    <PrivacyLink to="https://www.docxster.com/license">
                      {" "}
                      license agreement
                    </PrivacyLink>
                  </div>
                </PrivacyPolicyWrapper>
                <PrivacyPolicyWrapper>
                  <div className="checkbox-container">
                    <input
                      className="checkbox"
                      type="checkbox"
                      {...label}
                      checked={emailChecked}
                      onChange={handleChangeEmails}
                    />
                  </div>
                  <div className="text-container">
                    I consent to being sent promotional and marketing emails by
                    Docxster
                  </div>
                </PrivacyPolicyWrapper>

                <SignUpBtn
                  text={!isLoading ? "SignUp" : <Loader />}
                  handler={signUpHandler}
                  type="submit"
                />
              </StyledForm>

              <p
                style={{
                  color: "#fff",
                  textAlign: "center ",
                  fontSize: "15px",
                }}
              >
                Already have an account?
                <LoginLink to="/auth/login" style={{ color: "#5776D3" }}>
                  {" "}
                  Login here
                </LoginLink>
              </p>
            </SignUpContainer>
          </FlexContainer>
        </Content>
      </Container>
    </>
  );
};

export default SignUp;
