export const colors = {
  pink: "#DE0D92",
  yellow: "#FDCA40",
  // yellow_bg_gradient:
  //   "radial-gradient(50% 50% at 50% 50%, #FFFAC8 27.4%, #FDEB45 100%)",
  peach: "#FF715B",
  // teal: "#34D1BF",
  teal: "#56d0d8",
  green: "#7ED321",
  red: "#ED0423",
  absolute_red: "#FF0000",
  // purple: "#6665DD",
  purple: "#5c6ecd",
  orange: "#F17105",
  azure: "#0496FF",
  blue: "#2662F0",
  electro: "#29E7CD",
  gray: "#636D73",
  magenta: "#B91A5C",
  chart_total: "#994CDA",
  chart_gst: "#62BCF1",
  secondary: "#90A1AC",
  light_gray: "#E4EAF0",
  dark: "#25292D",
  success: "#2e7d32",
  error: "#d32f2f",
  white: "#ffffff",
  body_bg: "#ffceff21",
  sidebar_bg: "#3d1242e0",
  sidebar_bg_gradient:
    "linear-gradient(90deg, #141426 27.4%, rgba(20, 20, 38, 0.95) 60.21%, #442B4A 99.99%)",
    progress_bg:
    "linear-gradient(102deg, #ED6804 0%, #E0192A 41.78%, #A508A5 84.79%)",
  sidebar_menu_text: "#fff1ff",
  sidebar_icon: "#efefef70",
  gst_paid: "rgba(0,86,146,1)",
  gst_received: "rgba(255,145,165,1)",
  free_users: "#5C6ECD",
  paid_users: "#56D0D8",
  active_users: "#FF7C7B",
  total_users: "#403D67",
};

export const confirmedPalette = {
  cold: colors.peach,
  fracture: colors.green,
  concussion: colors.azure,
  hepatitis: colors.purple,
  dermatitis: colors.electro,
  diabetes: colors.pink,
};

export const fonts = {
  // accent: '"Rubik", sans-serif',
  accent: '"Inter", sans-serif',
  // body: '"Roboto", sans-serif',
  body: '"Inter", sans-serif',
};

export const textSizes = {
  8: "0.5rem",
  10: "0.625rem",
  12: "0.75rem",
  14: "0.875rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  24: "1.5rem",
  26: "1.625rem",
  28: "1.75rem",
  32: "2rem",
  40: "2.5rem",
};

// export const light = {
//   bodyBg: "#F1F5F8",
//   widgetBg: "#fff",
//   highlight: "#FAFBFD",
//   text: "#414D55",
//   shadow: "linear-gradient(270deg, rgba(255, 255, 255, 0.0001) 0%, #fff 100%)",
// };
export const light = {
  bodyBg: "#FFF",
  widgetBg: "#fff",
  highlight: "#FAFBFD",
  text: "#FFF",
  // text: "#484848" ,
  heading: "#484848",
  shadow: "linear-gradient(270deg, rgba(255, 255, 255, 0.0001) 0%, #fff 100%)",
};

// export const dark = {
//   bodyBg: "#090A0A",
//   widgetBg: "#171819",
//   highlight: "#131414",
//   text: "#9EA7AC",
//   shadow: "linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #171819 100%)",
//   shadowDarker:
//     "linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #090A0A 100%)",
// };

export const dark = {
  bodyBg: "#141426",
  widgetBg: "#171819",
  highlight: "#131414",
  text: "#FFF",
  heading: "#FFF",
  shadow: "linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #171819 100%)",
  shadowDarker:
    "linear-gradient(270deg, rgba(23, 24, 25, 0.0001) 0%, #090A0A 100%)",
};

export const textGradient = {
  backgroundClip: "text",
  webkitBackgroundClip: "text",
  webkitTextFillColor: "transparent",
  background:
    " linear-gradient(141deg, #ED6804 0%, #E0192A 49.27%, #A508A5 100%)",
};

export const backgroundGradient = {
  background:
    "var(--brand, linear-gradient(141deg, #ED6804 0%, #E0192A 49.27%, #A508A5 100%))",
  boxShadow:
    " 5px 5px 4px 0px rgba(255, 255, 255, 0.10) inset, -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  backdropFilter: " blur(21px)",
};

export const effects = {
  dashedDark: `1px dashed ${colors.dark}`,
  dashedLight: `1px dashed #A2C0D4`,
  widgetShadow: "0 1px 8px rgba(20, 46, 110, 0.1)",
  darkerShadow: "0 1px 8px rgba(0, 0, 0, 0.3)",
};

export const breakpoints = {
  mobileS: "@media screen and (min-width: 320px)",
  landscapeS: "@media screen and (min-width: 567.98px)",
  mobileM: "@media screen and (min-width: 374.98px)",
  mobileL: "@media screen and (min-width: 413.98px)",
  tablet: "@media screen and (min-width: 767.98px)",
  laptop: "@media screen and (min-width: 1023.98px)",
  laptopL: "@media screen and (min-width: 1279.98px)",
  desktop: "@media screen and (min-width: 1599.98px)",
};

export const flex = {
  col: `
      display: flex;
      flex-direction: column;
    `,
  row: `
      display: flex;
      flex-direction: row;
    `,
  center: `
     align-items: center;
     justify-content: center;
    `,
  between: `
     align-items: center;
     justify-content: space-between;
    `,
};

export const shadow = {
  borderShadow: `
    border: 1px solid #77777726;
    box-shadow: 0 1px 8px rgb(20 46 110 / 10%);
    `,

  boxShadow: ` border: 1px solid #77777726;
    box-shadow: 0 1px 8px rgb(20 46 110 / 10%);
    `,
  chartShadow: ` border: 1px solid #77777726;
  box-shadow: 0 1px 19px 4px rgb(93 95 100 / 24%);
    `,
};
