// styled components
import { Content, Widgets } from "./style";

// components
import { Helmet } from "react-helmet";

import Grid from "@layout/Grid";

// utils
import PropTypes from "prop-types";

// hooks
// import useWindowSize from "@hooks/useWindowSize";
import useMobileDetect from "use-mobile-detect-hook";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef, useEffect } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";

const Page = ({ title, children, hasBadge, hasTitle = true, qty }) => {
  const pageRef = useRef(null);
  const device = useMobileDetect();
  //   const { width } = useWindowSize();
  //   const isTablet = width > 767.98;
  const { setPageTitle } = useInterfaceContext();

  // get the current location from the router
  const { pathname } = useLocation();
  // detect if the current location is dashboard
  //   const isDashboard = pathname.includes("dashboard");
  // modify the path to get corresponding layout from the store
  // const layoutKey = pathname.replace("/", "");
  const layoutKey = pathname.split("/")[2];
  // console.log("layout", pathname.split("/")[2]);
  const layouts = useSelector((state) => state["layout"].layout);
  const current = layouts[layoutKey];

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollTop = 0;
    }
    if (title) {
      setPageTitle(title);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Content ref={pageRef}>
      <Helmet>
        <title>{`Docxster | ${title}`}</title>
      </Helmet>
      <Widgets>
        {current ? (
          <Grid id={layoutKey} layouts={current} desktop={device.isDesktop()}>
            {children}
          </Grid>
        ) : (
          children
        )}
      </Widgets>
    </Content>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hasBadge: PropTypes.bool,
  hasTitle: PropTypes.bool,
  qty: PropTypes.number,
};

export default Page;
