// styling
import { colors } from "@styles/vars";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import ChartTooltip from "@components/Chart/Tooltip";
import Widget from "@components/Widget";
import WidgetHeader from "@components/Widget/WidgetHeader";
import WidgetBody from "@components/Widget/WidgetBody";
import Legend from "@ui/Legend";
import LegendItem from "@ui/Legend/LegendItem";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function SAPiechart({ value }) {
  const COLORS = [colors.yellow, colors.red];
  const GRADIANT_COLORS = [
    // {
    //   start: " #E43020",
    //   startOpacity: "0%",
    //   end: "#A509A5",
    //   endOpacity: "100%",
    // },
    {
      start: "rgb(228,48,32,1)",
      startOpacity: "0%",
      // end: "rgba(165,9,165,1)",
      end: "rgb(228,48,32,1)",
      endOpacity: "100%",
    },
    {
      start: "rgba(49, 57, 179, 0.73)",
      startOpacity: "30%",
      end: "#1B5DC3",
      endOpacity: "100%",
    },
    {
      start: "#A509A5",
      startOpacity: "0%",
      end: "#E43020",
      endOpacity: "100%",
    },

    {
      start: "#E43020",
      startOpacity: "0%",
      end: "#A509A5",
      endOpacity: "0%",
    },
  ];
  // const GRADIANT_COLORS = [
  //   {
  //     start: "#A509A5",
  //     startOpacity: "0%",
  //     end: "#E43020",
  //     endOpacity: "100%",
  //   },
  //   {
  //     start: "#3139B3",
  //     startOpacity: "0%",
  //     end: "#1B5DC3",
  //     endOpacity: "100%",
  //   },
  //   {
  //     start: "#3139B3",
  //     startOpacity: "0%",
  //     end: "#1B5DC3",
  //     endOpacity: "100%",
  //   },
  //   {
  //     start: "#3139B3",
  //     startOpacity: "73%",
  //     end: "#1B5DC3",
  //     endOpacity: "100%",
  //   },
  //   {
  //     start: "#E43020",
  //     startOpacity: "0%",
  //     end: "#A509A5",
  //     endOpacity: "100%",
  //   },
  // ];

  const data = [
    { value: value.DocumentCount },
    { value: value.DocumentLimit - value.DocumentCount },
    // { value: 25 },
  ];
  return (
    <Widget
      name="HepatitisChart"
      style={{
        // overflow: "hidden",
        // alignItems: "center",
        // justifyContent: "center",
        borderRadius: "30px",
      }}
    >
      <WidgetHeader title="Total Usage">
        <Legend>
          {/* <LegendItem color="yellow" legend="Free" /> */}
          <LegendItem color="blue" legend="Free" />
          <LegendItem color="magenta" legend="Used" />
          {/* <LegendItem color="purple" legend="C" /> */}
        </Legend>
      </WidgetHeader>
      <WidgetBody
        style={{
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "40px",
        }}
      >
        {/* <PieChart width={340} height={340}> */}
        <PieChart width={350} height={350}>
          <defs>
            {data.map((entry, index) => (
              <radialGradient key={`gradient${index}`} id={`gradient${index}`}>
                <stop
                  offset={GRADIANT_COLORS[index % COLORS.length].startOpacity}
                  stopColor={GRADIANT_COLORS[index % COLORS.length].start}
                />
                <stop
                  offset={GRADIANT_COLORS[index % COLORS.length].endOpacity}
                  stopColor={GRADIANT_COLORS[index % COLORS.length].end}
                />
              </radialGradient>
            ))}
          </defs>
          <Pie
            data={data}
            cx="50%"
            cy="52%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={130}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={`url(#gradient${index})`} />
            ))}
          </Pie>
          <Tooltip content={<ChartTooltip />} />
        </PieChart>
      </WidgetBody>
    </Widget>
  );
}
