// styling
import styled from "styled-components/macro";
import { breakpoints, flex, textSizes } from "@styles/vars";

// components
// import Field from "@ui/Field";

// utils
import PropTypes from "prop-types";
// import LoginField from "@ui/Field";
import SignUp from "@widgets/Dox/SignUp";
import SignUpField from "@ui/Field/singup";

const Wrapper = styled.div`
  ${flex.col};
  position: relative;
  // width: 294px;
  width: 100%;
  i {
    position: absolute;
    top: 30px;
  }

  ${breakpoints.desktop} {
    width: 400px;
  }
`;

const Label = styled.label`
  font-size: ${textSizes["14"]};
  width: 100%;
  // width: fit-content;
  margin-bottom: 8px;
`;

const SignUpFormInput = ({
  id,
  handler,
  type = "text",
  placeholder,
  value = "",
  icons,
}) => {
  return (
    <Wrapper>
      {/* <Label htmlFor={id}>{title}</Label> */}
      <i className={`icon ${icons}`}></i>
      <SignUpField
        type={type}
        value={value}
        id={id}
        placeholder={placeholder}
        handler={handler}
      />
    </Wrapper>
  );
};

SignUpFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password", "email", "number"]),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SignUpFormInput;
