import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { colors, textSizes, flex, shadow } from "@styles/vars";
import api from "api";
import { useAuth } from "@contexts/AuthProvider";
import AlertIcon from "@assets/alert.png";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "react-select";
import { useInterfaceContext } from "@contexts/interfaceContext";

const PopUpWrapper = styled.div`
  .dropdown {
    width: 220px;
    height: 215px;
    background: white;
    padding: 3px;
    border-radius: 5px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
    // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11);

    .css-rtl-13cymwt-control {
      width: 220px !important;
    }

    .css-rtl-t3ipsp-control {
      width: 220px !important;
    }

    .css-rtl-1nmdiq5-menu {
      margin-bottom: 30px !important;

      .css-rtl-1n6sfyn-MenuList {
        // background: cyan !important;
        height: 150px !important;
      }
    }
  }
`;

const DropDownContainer = styled.div`
  .items {
    ${flex.row}
    justify-content: space-between;
    align-items: center;
    width: 130px;
    color: #3e4041;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #cfd1d3;
    border-radius: 6px;
    padding: 2px 10px;

    .content {
      p:first-of-type {
        width: 100px;
        overflow: hidden;
        height: 20px;
        cursor: pointer;
      }
    }

    .btn_wrapper {
      .arrow {
        width: 17.25px;
        height: 12px;
        // color: #adadad;
        color: rgba(68, 43, 74, 0.99);
        transform: scale(2.2);
        cursor: pointer;
      }
    }
  }

  .error {
    ${flex.row}
    align-items: center;
    justify-content: space-around;
    width: 130px;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
    background: rgba(89, 210, 252, 0.5);

    p {
      color: rgba(35, 77, 91, 0.5);
      font-size: 12px;
      font-weight: 600;
    }

    .alert {
      width: 12px;
      height: 9px;
      filter: sepia(100%) hue-rotate(90deg);
    }
  }
`;

const CustomDropDownPopUp = ({
  tableItems,
  tableAmount,
  row,
  showQB,
  buyerName,
  handler,
}) => {
  const auth = useAuth();
  const popUpRef = useRef(null);
  const [tableItemsArr, setTableItemsArr] = useState([]);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [popupPosition, setPopupPosition] = useState(null);
  const [itemObj, setItemObj] = useState({});
  const { qbItems, setQBItems } = useInterfaceContext();

  const showPopUpHandler = (e, val) => {
    setIsShowPopup(true);
    let x = e.clientX;
    let y = e.clientY;
    if (val === "alert") {
      setPopupPosition({ x, y });
    } else {
      if (x && y) {
        // x = x - 165;
        // y = y + 25;
        x = x;
        y = y + 5;
        setPopupPosition({ x, y });
      }
    }
  };

  const findItems = () => {
    let targetObj = tableItems.find((ele) => ele.row == row);

    let targetAmountObj = tableAmount.find(
      (ele) => ele.aliasLabel === "Line_Amount" && ele.row == row
    );
    if (tableItemsArr && targetObj && targetAmountObj) {
      const regexPattern = new RegExp(`^${targetObj.text}$`);
      const match =
        tableItemsArr &&
        tableItemsArr.find((ele) => regexPattern.test(ele.Name));
      tableItemsArr &&
        tableItemsArr.find((ele) => {
          // console.log(ele);
          if (regexPattern.test(ele.Name)) {
            let newItem = {
              name: ele.Name,
              id: ele.Id,
              amount: targetAmountObj.text,
              row: targetAmountObj.row,
            };
            setItemObj(newItem);

            const existingIndex = qbItems.findIndex(
              (item) => item.row === newItem.row
            );

            if (existingIndex === -1) {
              // Row doesn't exist, add it to the array
              setQBItems((prevData) => [...prevData, newItem]);
            } else if (
              qbItems[existingIndex].name !== newItem.name ||
              qbItems[existingIndex].amount !== newItem.amount ||
              !match
            ) {
              // Row exists, update it in the array
              setQBItems((prevData) => {
                const updatedItems = [...prevData];
                updatedItems[existingIndex] = newItem;
                return updatedItems;
              });
            }
          }
        });

      if (!match) {
        setItemObj({});
        setQBItems(qbItems.filter((ele) => ele.row !== targetObj.row));
      }
    } else {
      setItemObj({});
    }
  };

  const handleSelect = (selectedOption) => {
    let targetAmountObj =
      tableAmount &&
      tableAmount.find(
        (ele) => ele.aliasLabel === "Line_Amount" && ele.row == row
      );

    setItemObj({
      name: selectedOption.value,
      id: selectedOption.id,
      amount: targetAmountObj.text,
      row: targetAmountObj.row,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleSelect(selectedOption);
  };

  const formattedOptions = tableItemsArr.map((item) => ({
    value: item.FullyQualifiedName,
    label: item.FullyQualifiedName,
    id: item.Id,
  }));

  useEffect(() => {
    // debugger
    const fetchData = async () => {
      try {
        const res = await api.post(
          "/integrations/quickbooksonline/getItem",
          {},
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
        if (res.status == 200) {
          setTableItemsArr(res.data.Item);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setIsShowPopup(false);
        setPopupPosition(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Close the dropdown after an option is selected
    const updateHandler = () => {
      handler("", itemObj);
    };
    if (selectedOption) {
      setIsShowPopup(false);
      updateHandler();
    }
  }, [selectedOption]);

  useEffect(() => {
    findItems();
  }, [tableAmount, tableItems, tableItemsArr]);

  // useEffect(() => {
  //   findItems();
  // }, [tableItemsArr]);
  return (
    <DropDownContainer
      style={{
        visibility: showQB && buyerName ? "visible" : "hidden",
      }}
    >
      {itemObj && itemObj.id ? (
        <div
          className="items"
          id={row}
          onClick={(e) => showPopUpHandler(e, "arrow")}
        >
          <div className="content">
            <Tooltip title={itemObj.name} placement="top">
              <p>{itemObj.name}</p>
            </Tooltip>
            <p style={{ fontSize: "12px" }}>#{itemObj.id}</p>
          </div>
          <div className="btn_wrapper">
            <KeyboardArrowDownIcon
              className="arrow"
              // onClick={(e) => showPopUpHandler(e, "arrow")}
            />
          </div>
        </div>
      ) : (
        <div className="error" onClick={(e) => showPopUpHandler(e, "alert")}>
          <img src={AlertIcon} alt="alert" className="alert" />
          <p>Select the items</p>
        </div>
      )}

      {isShowPopup && (
        <PopUpWrapper
          ref={popUpRef}
          style={{
            position: "absolute",
            top: popupPosition && popupPosition.y,
            left: popupPosition && popupPosition.x,
            zIndex: 2,
          }}
        >
          <div className="dropdown">
            <Select
              value={selectedOption}
              onChange={handleSelectChange}
              options={formattedOptions}
              issearchable={true}
              menuIsOpen={true}
              placeholder="Select an items..."
            />
          </div>
        </PopUpWrapper>
      )}
    </DropDownContainer>
  );
};

export default CustomDropDownPopUp;
