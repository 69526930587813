export const dash = {
  documentUsage: [
    {
      label: "Jan",
      values: {
        invoice: 10,
        bol: 25,
        kyc: 10,
      },
    },
    {
      label: "Feb",
      values: {
        invoice: 10,
        bol: 25,
        kyc: 10,
      },
    },
  ],
  totalSales: {
    Invoice: [
      {
        name: "Sales",
        value: 658,
      },
      {
        name: "GST",
        value: 100,
      },
      {
        name: "GrandTotal",
        value: 758,
      },
    ],
    BillOfLading: [
      {
        name: "FrieghtCharges",
        value: 150,
      },
      {
        name: "GST",
        value: 150,
      },
      {
        name: "GrandTotal",
        value: 150,
      },
    ],
  },
  documentCount: {
    DocumentCount: 42,
    DocumentLimit: 100,
  },
  gstPaidReceived: {
    paid: 560,
    received: 709,
  },
};
