// styling
import styled from "styled-components/macro";
import { colors, textSizes, fonts, flex, shadow } from "@styles/vars";
import { darken } from "polished";

// utils
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

const Button = styled.button`
  width: 30px;
  height: 30px;
  font-size: 0.875rem;
  background-color: ${colors.white};
  padding: 8px 14px;
  ${shadow.borderShadow};
  border-radius: 50%;
  transition: color var(--transition), background-color var(--transition);
  transition: transform 0.2s ease-in;
  ${flex.row};
  ${flex.center};

  .icon {
    color: ${colors.gray};
    transition: color var(--transition);
    // border-right: 1px solid #ddd;
    padding-right: 8px;
    margin-right: 8px;
  }

  .text {
    // display: none;
  }

  &:hover {
    // background-color: ${colors.white};
    color: #777;
    transform: scale(0.9);

    .icon {
      color: #777;
    }
  }
  &.error {
    background-color: ${colors.error};

    // &:hover, &:focus {
    //   background-color: ${darken(0.1, colors.error)};
    // }
  }

  &.disabled {
    background-color: ${colors.gray};
    pointer-events: none;
  }
  &.disable {
    background-color: #a2a2a3;
    pointer-events: none;
  }
  &.primary {
    background-color: ${colors.sidebar_bg};
    color: ${colors.sidebar_menu_text};
    box-shadow: 0 1px 6px 4px rgb(145 145 146 / 29%);

    .icon {
      color: ${colors.sidebar_menu_text};
    }
  }
`;

const DoxIconBtn = ({
  text,
  handler,
  type = "button",
  isVisible = true,
  className,
  icon,
  iconStyles,
  style,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Button
          as={motion.button}
          className={className ? className : ""}
          onClick={handler}
          type={type}
          initial={false}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={style}
        >
          {icon && <i className={`icon icon-${icon}`} style={iconStyles} />}
        </Button>
      )}
    </AnimatePresence>
  );
};

DoxIconBtn.propTypes = {
  text: PropTypes.string,
  handler: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default DoxIconBtn;
