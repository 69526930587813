import React from "react";
import { MainContainer, CentralContainer } from "./style";
import logoLight from "@assets/logo/logo-light.png";
import EmailIcon from "@fonts/menu_icons/emailIcon";

const CheckEmail = () => {
  const navigateToEmail = () => {
    window.location.href = "https://www.google.com/mail";
  };

  // const resendEmail = async () => {};

  return (
    <MainContainer>
      <CentralContainer>
        <img src={logoLight} alt="Docxster-logo" className="docxster_logo" />

        <div className="content">
          <div className="logo">
            <EmailIcon />
          </div>
          <p className="heading">Check your email</p>
          <p className="text">open your mail to verify</p>
          <button className="email_btn" onClick={navigateToEmail}>
            Open email
          </button>
          {/* <p className="text">
            {" "}
            Didn't receive the email?{" "}
            <span className="link" onClick={resendEmail}>
              Click to resend
            </span>
          </p> */}
        </div>
      </CentralContainer>
    </MainContainer>
  );
};

export default CheckEmail;
