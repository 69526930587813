import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "api";
import FileDownload from "js-file-download";

// styling
import {
  DetailsWrapper,
  SidebarWrapper,
  Content,
  InvoiceSection,
  Sidebar,
  Paging,
  TableSection,
} from "../../components/Dox/ViewDetails/style.js";

// material icons
import InfoIcon from "@mui/icons-material/Info";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Editable from "@ui/Dox/Editable/index.js";
import DoxBtn from "@ui/Dox/DoxBtn";
import WidgetsLoader from "@components/WidgetsLoader";
import { useAuth } from "@contexts/AuthProvider";
import { nanoid } from "nanoid";
import CanvasImage from "../../components/Dox/ViewDetails/CanvasImage.jsx";
import instance from "@utils/instance.js";
import DoxIconBtn from "@ui/Dox/DoxIconBtn/index.jsx";
import DownloadIcon from "@ui/Dox/DownloadIcon/index.jsx";
import { useInterfaceContext } from "@contexts/interfaceContext.js";
import EditableMultiline from "@ui/Dox/EditableMultiline/index.js";
import CustomSelectBox from "@ui/Dox/CustomSelectBox/index.jsx";

const ReviewDetails = () => {
  const [data, setData] = useState({});
  const [customSection, setCustomSection] = useState({});
  const [moderated, setModerated] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pagesLength, setPagesLength] = useState(1);
  const [pageDetails, setPageDetails] = useState([]);
  const [pageFields, setPageFields] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [fieldLabels, setFieldLabels] = useState([]);
  const [tableData, setTableData] = useState({});
  // const [newFields, setNewFields] = useState({});
  // const [isChanged, setIsChanged] = useState(false);
  // const [isShowField, setIsShowField] = useState(false);
  // const fieldNameRef = useRef("");
  // const fieldValueRef = useRef("");
  const navigate = useNavigate();
  const { state } = useLocation();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { notification, setNotification } = useInterfaceContext();
  const { payload, config, ext } = state;

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(500);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          sidebarRef.current.getBoundingClientRect().right -
            mouseMoveEvent.clientX
        );
      }
    },
    [isResizing]
  );

  useEffect(() => {
    // pointerEvents works for both mouse and touch
    window.addEventListener("pointermove", resize);
    window.addEventListener("pointerup", stopResizing);
    return () => {
      window.removeEventListener("pointermove", resize);
      window.removeEventListener("pointerup", stopResizing);
    };
  }, [resize, stopResizing]);

  const body = {
    companyId: payload.companyId,
    documentId: payload.documentId,
    documentType: payload.documentSubType,
  };

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;

  const updateField = (e, id, isTable = false) => {
    if (e.key === "Enter" || e.type === "blur") {
      let updatedFields = [];

      if (isTable) {
        const tableCells = pageFields
          .filter((field) => field.label === "table")[0]
          .cells.map((cell) =>
            cell.id === id ? { ...cell, text: e.target.value } : cell
          );
        updatedFields = pageFields.map((ele) =>
          ele.label === "table" ? { ...ele, cells: tableCells } : ele
        );
      } else {
        updatedFields = pageFields.map((ele) =>
          ele.id === id ? { ...ele, ocr_text: e.target.value } : ele
        );
      }

      const currentPageInfo = data.Pages.filter(
        (ele) => ele.pageNo === pageNumber
      )[0];
      currentPageInfo.moderated = updatedFields;

      // get fields of all pages to updateDocuments as moderated
      const moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { id: page._id, moderated: page.moderated }
          : { id: page._id, moderated: page.prediction }
      );

      setPageFields(updatedFields);
      setModerated(moderatedFields);
      if (isTable) {
        setCurrentTableData(updatedFields);
      }
    }
  };

  const updateByBox = (e, obj, isResized = false) => {
    if (isResized && !pageFields.find((ele) => ele.id === obj.id)) {
      return;
    }

    let updatedFields = [];
    if (obj.row) {
      const tableCells = pageFields
        .filter((field) => field.label === "table")[0]
        .cells.map((cell) => (cell.id === obj.id ? { ...cell, ...obj } : cell));
      updatedFields = pageFields.map((ele) =>
        ele.label === "table" ? { ...ele, cells: tableCells } : ele
      );
    } else if (obj.type) {
      updatedFields = pageFields.map((ele) =>
        ele.id === obj.id ? { ...ele, ...obj } : ele
      );
    } else {
      updatedFields = [
        ...pageFields,
        { ...obj, page_no: pageNumber, type: "field" },
      ];
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedFields);
    }
  };

  const deleteByBox = (e, obj) => {
    if (!pageFields.find((ele) => ele.id === obj.id)) {
      return;
    }
    let updatedFields = [];
    if (obj.row) {
      const tableCells = pageFields
        .filter((field) => field.label === "table")[0]
        .cells.filter((cell) => cell.id !== obj.id);
      updatedFields = pageFields.map((ele) =>
        ele.label === "table" ? { ...ele, cells: tableCells } : ele
      );
    } else if (obj.type) {
      updatedFields = pageFields.filter((ele) => ele.id !== obj.id);
    }

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    if (obj.row) {
      setCurrentTableData(updatedFields);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const submitHandler = async () => {
    const obj = {
      _id: data._id,
      documentType: body.documentType,
      moderatedFields: moderated,
    };

    if (moderated.length === 0) {
      // get fields of all pages to updateDocuments as moderated
      const moderatedFields = data.Pages.map((page) =>
        page.moderated.length > 0
          ? { id: page._id, moderated: page.moderated }
          : { id: page._id, moderated: page.prediction }
      );
      obj.moderatedFields = moderatedFields;
    }

    try {
      setIsLoading(true);
      const res = await api.post(
        "/document/updateDocumentData",
        { ...obj },
        {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        }
      );
      // set notification for new file uploaded
      const oldNotify = notification.isMarkAsRead
        ? notification.notifications.slice(0, 5)
        : notification.notifications;

      const notifications = [
        {
          id: nanoid(),
          type: body.documentType,
          msg: `${payload.fileName} is reviewd by ${auth.userInfo.userData.name}.`,
          tab: "Reviewed",
        },
        ...oldNotify,
      ];

      setNotification({
        isMarkAsRead: false,
        notifications: notifications,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
    goBack();
  };

  const getDetails = async () => {
    console.log("data", body);
    let fileInfo;
    try {
      setIsLoading(true);
      const res = await instance.post(
        "/admin/showDocumentDetails",
        body,
        config
      );
      // console.log(res);
      fileInfo = res.data;
      const pageData = res.data.result;
      const label =
        fileInfo.documentType === "Invoice"
          ? "customSection1"
          : "customSection2";
      // setDetails(fileInfo);
      setCustomSection({
        label: label,
        text: fileInfo[label],
      });
      setData(pageData);
      setPagesLength(pageData.Pages.length);
      getCurrentPageDetails(pageData);
      getHeaders();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getHeaders = async () => {
    try {
      setIsLoading(true);
      const res = await instance.get("/dashboard/getLabels", {}, config);

      const headerLabels =
        body.documentType === "Invoice"
          ? res.data.data.invoiceTables
          : body.documentType === "BillOfLading"
          ? res.data.data.billOfLadingTables
          : [];
      const feildLabels =
        body.documentType === "Invoice"
          ? res.data.data.invoiceLabels
          : body.documentType === "BillOfLading"
          ? res.data.data.billOfLadingLabels
          : [];

      setFieldLabels(feildLabels);
      setTableHeaders(headerLabels);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  // get csv file
  const getCsv = async () => {
    const URL = `${process.env.REACT_APP_BASE_URL}/document/downloadExtractedCSV`;
    // download CSV api call
    instance({
      url: URL,
      method: "post",
      responseType: "blob",
      headers: { "x-access-token": auth.user.accessToken },
      data: {
        documentIds: [payload.documentId],
        documentType: payload.documentSubType,
      },
    })
      .then((resp) => {
        FileDownload(resp.data, "Extracted.csv");
      })
      .catch((e) => console.log(e));
  };

  const getCurrentPageDetails = (data) => {
    console.log(data);
    const page =
      data.Pages && data.Pages.filter((ele) => ele.pageNo === pageNumber)[0];
    console.log("page", page);
    setPageDetails(page);
    const fields =
      page && page.moderated.length > 0
        ? page && page.moderated
        : page && page.prediction;
    setPageFields(fields);
    setCurrentTableData(fields);
    // console.log("fields", fields);
  };

  const updateSectionHandler = async (e) => {
    if (e.target.value !== "") {
      setCustomSection({ ...customSection, text: e.target.value });
      try {
        const res = await instance.post(
          "/document/updateCustomSection",
          {
            sectionName: customSection.label,
            sectionValue: e.target.value,
          },
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const changeHeaderHandler = (e) => {
    if (!auth.isAdmin()) {
      return;
    }
    const id = e.target.name;
    const selected = tableHeaders.filter(
      (ele) => ele.key === e.target.value
    )[0];

    const selectedCell = tableData.tableHeaders.filter(
      (ele) => ele.id === id
    )[0];

    let updatedFields = [];

    const tableCells = pageFields
      .filter((field) => field.label === "table")[0]
      .cells.map((cell) =>
        cell.col === selectedCell.col
          ? { ...cell, aliasLabel: selected.value, label: selected.key }
          : cell
      );
    updatedFields = pageFields.map((ele) =>
      ele.label === "table" ? { ...ele, cells: tableCells } : ele
    );

    const currentPageInfo = data.Pages.filter(
      (ele) => ele.pageNo === pageNumber
    )[0];
    currentPageInfo.moderated = updatedFields;

    // get fields of all pages to updateDocuments as moderated
    const moderatedFields = data.Pages.map((page) =>
      page.moderated.length > 0
        ? { id: page._id, moderated: page.moderated }
        : { id: page._id, moderated: page.prediction }
    );

    setPageFields(updatedFields);
    setModerated(moderatedFields);
    setCurrentTableData(updatedFields);
  };
  const setCurrentTableData = (fields) => {
    const tableInfo =
      fields && fields.filter((field) => field.label === "table")[0];

    const tableHeaders =
      tableInfo && tableInfo.cells.filter((cell) => cell.row === 1);
    setTableData({ ...tableInfo, tableHeaders: tableHeaders });
  };
  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    getCurrentPageDetails(data);
  }, [pageNumber]);

  const previousHandler = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextHandler = () => {
    if (pageNumber < pagesLength - 1) {
      setPageNumber(pageNumber + 1);
    }
  };
  console.log("pageDetails", pageDetails);
  return (
    <DetailsWrapper>
      {!isLoading ? (
        <>
          <Content>
            <CanvasImage
              image={
                pageDetails.images &&
                pageDetails.images.original_with_long_expiry
              }
              // image={pages.images.original && pages.images.original_with_long_expiry}
              info={pageFields}
              resizeHandler={updateByBox}
              fieldlabels={fieldLabels.length > 0 && fieldLabels}
              tableHeaders={tableHeaders}
              updateHandler={updateByBox}
              deleteHandler={deleteByBox}
            />
          </Content>
          {/* resizer class is enable resize bar  */}
          <SidebarWrapper ref={sidebarRef} style={{ width: sidebarWidth }}>
            <div className="resizer" onPointerDown={startResizing} />
            <Sidebar style={{ display: "flex" }}>
              <InvoiceSection className="paging-section">
                <Paging style={{ position: "unset", fontSize: "14px" }}>
                  <span
                    className={`prev ${pageNumber > 0 ? "primary" : "disable"}`}
                    onClick={previousHandler}
                  >
                    <ArrowLeftIcon />
                  </span>
                  <span>Page {pageNumber + 1}</span>
                  <span
                    className={`next ${
                      pageNumber < pagesLength - 1 ? "primary" : "disable"
                    }`}
                    onClick={nextHandler}
                  >
                    <ArrowRightIcon />
                  </span>
                </Paging>
                <DoxIconBtn
                  icon="close"
                  iconStyles={{ margin: "0px", padding: "0px" }}
                  handler={goBack}
                />
              </InvoiceSection>
              <InvoiceSection style={{ paddingTop: "15px", height: "100%" }}>
                <div className="title">
                  <span style={{ display: "inline-flex" }}>
                    <InfoIcon className="icon" />
                    <Editable
                      text={customSection.text}
                      name={customSection.label}
                      handler={updateSectionHandler}
                      style={{
                        width: "100%",
                      }}
                      editable={auth.isAdmin()}
                    />
                  </span>
                  <DownloadIcon className="download-csv" handler={getCsv} />
                </div>
                <form className="details">
                  {pageDetails &&
                    pageFields.map(
                      (ele, i) =>
                        ele.label !== "table" && (
                          <div className="info-wrapper" key={ele.id}>
                            <span className="name">
                              {ele.aliasLabel
                                ? ele.aliasLabel.replaceAll("_", " ")
                                : "NA"}
                            </span>

                            <EditableMultiline
                              className="info"
                              text={ele.ocr_text}
                              name={ele.label}
                              handler={(e) => updateField(e, ele.id)}
                              //   editable={auth.isAdmin()}
                            />
                          </div>
                        )
                    )}
                  {tableData.tableHeaders && (
                    <TableSection>
                      <div className="title">
                        <span style={{ display: "inline-flex" }}>
                          <InfoIcon className="icon" /> Table
                        </span>
                      </div>
                      <div
                        className="table"
                        style={{
                          gridTemplateColumns: `repeat(${
                            tableData.tableHeaders &&
                            tableData.tableHeaders.length
                          }, 1fr)`,
                        }}
                      >
                        {tableData.tableHeaders &&
                          tableData.tableHeaders.map(
                            (ele) =>
                              ele.row === 1 && (
                                <div className="th" key={ele.id}>
                                  <CustomSelectBox
                                    className="info"
                                    selected={ele}
                                    items={tableHeaders}
                                    handler={changeHeaderHandler}
                                  />
                                </div>
                              )
                          )}

                        {tableData.cells &&
                          tableData.cells.map((td) => (
                            <div className="td" key={td.id}>
                              <EditableMultiline
                                className="info"
                                text={td.text}
                                name={td.label}
                                handler={(e) => updateField(e, td.id, true)}
                                // editable={auth.isAdmin()}
                              />
                            </div>
                          ))}
                      </div>
                    </TableSection>
                  )}
                </form>
              </InvoiceSection>

              <InvoiceSection
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: 0,
                }}
              >
                <DoxBtn
                  text="submit"
                  icon="check"
                  handler={submitHandler}
                  style={{
                    margin: "18px 5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </InvoiceSection>
            </Sidebar>
          </SidebarWrapper>
        </>
      ) : (
        <WidgetsLoader />
      )}
    </DetailsWrapper>
  );
};

export default ReviewDetails;
