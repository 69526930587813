import styled from "styled-components/macro";
import theme from "styled-theming";
// import login_bg from "@assets/login_bg.jpeg";
import login_bg from "@assets/login_bg.gif";

import {
  dark,
  light,
  flex,
  colors,
  breakpoints,
  textSizes,
} from "@styles/vars";
import { Link } from "react-router-dom";

export const BrandLogo = styled.div`
  // position: absolute;
  background: white;
  // z-index: 9;
  width: 120px;
  height: 120px;

  img {
    width: 100%;
    height: 100%;
  }
`;
export const Container = styled.div`
  position: relative;
  ${flex.col};
  ${flex.center};
  // padding: 24px;
  height: 100vh;
  // background: url(${login_bg});
  background-color: rgba(20, 20, 38, 1);

  ${breakpoints.mobileM} {
    padding: 24px;
  }

  ${breakpoints.laptop} {
    background: url(${login_bg});
    background-size: 94%;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(20, 20, 38, 1);
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  ${flex.col};
  justify-content: center;
  align-items: center;
  // padding: 24px;
  // gap: 16px;
  color: #fff;
  width: 100%;

  .docxster_logo {
    // width: 40%;
    // align-self: flex-start;
    // margin-left: 20px;
    // width: 209px;
    width: 80vw;
    height: 74px;
    position: relative;
    top: 5px;
    // left: -107px;
  }

  h1 {
    margin: 0 0 10px 15px;
    align-self: flex-start;
    font-size: 32px;
    font-weight: 400 !important;
  }

  input {
    background-color: #e1e3e63d;
    // margin: 18px 0;
    margin: 0 0 20px 0;
    border: 1px solid #3c3c3c1a;
  }

  .label {
    display: none;
    font-size: 12px;
    text-align: left;
  }

  button {
    max-width: 100%;
    margin: 0px 0px 10px 0;
    font-size: ${textSizes["16"]};
    text-transform: uppercase;
  }

  ${breakpoints.mobileM} {
    h1 {
      margin: 0 0 10px 15px;
    }

    .docxster_logo {
      width: 189px;
      height: 67px;
      position: relative;
      // left: -66px;
      left: -50px;
      top: -10px;
    }
  }

  ${breakpoints.tablet} {
    flex: 1;
    h1 {
      margin: 0 0 10px 20px;
    }
    .docxster_logo {
      width: 175px;
      height: 60px;
      position: relative;
      top: -4px;
      // left: -80px;
      left: -60px;
    }
  }

  ${breakpoints.laptop} {
    // h1 {
    //   margin: 0 0 10px 0px;
    // }
    .docxster_logo {
      width: 209px;
      height: 74px;
      position: relative;
      top: 5px;
      // left: -107px;
      left: -40px;
    }
  }

  ${breakpoints.desktop} {
    h1 {
      margin: 0 0 10px 0px;
    }
    // h1 {
    //   font-size: 38px;
    // }
    .docxster_logo {
      width: 285px;
      height: 101px;
      // left: -156px;
      left: -60px;
      top: 22px;
    }

    .label {
      font-size: 14px;
      text-align: left;
      margin-bottom: 5px;
      display: block;
    }

    input {
      margin: 0px 0 20px 0;
    }
  }
`;

export const SignUpContainer = styled.div`
  // width: 294px;
  width: 90vw;
  margin-bottom: 10px;

  ${flex.col};
  align-items: center;

  ${breakpoints.mobileS} {
    width: 305px;
  }

  ${breakpoints.desktop} {
    width: 400px;
  }
`;

export const Message = styled.p`
  font-size: ${textSizes["14"]};

  .error {
    color: ${colors.red};
  }
  .success {
    color: ${colors.green};
  }
`;

export const Error = styled.p`
  font-size: ${textSizes["14"]};
  color: ${colors.red};
`;

export const Success = styled.p`
  font-size: ${textSizes["14"]};
  color: ${colors.green};
`;

export const Content = styled.div`
  ${flex.col};
  ${flex.center};
  text-align: center;
  // gap: 24px;
  // padding: 24px;
  width: 100%;
  height: 100%;

  // width: 998px;
  // height: 563px;

  // border-radius: 40px;
  overflow: hidden;
  background-color: ${theme("theme", {
    light: light.highlight,
    dark: dark.highlight,
  })};
  // background: #141426;
  backdrop-filter: blur(17px) saturate(167%);
  -webkit-backdrop-filter: blur(17px) saturate(167%);
  background-color: rgba(74, 74, 74, 0.3);
  // box-shadow: 0px 0px 8px 2px #afacac50;

  .left-image {
    padding: 0;
    height: 100%;
    display: none;
    // background-color: white;
  }

  ${breakpoints.mobileM} {
    border-radius: 15px;
    .left-image {
      display: none;
    }
    // width: 352px;
    // height: 646px;

    max-width: 345px;
    width: 99%;
    /* height: 754px; */
    height: 850px;
  }

  ${breakpoints.tablet} {
    // flex-direction: row;
    flex-direction: row-reverse;
    .left-image {
      display: block;
    }
    // width: 750px;
    // height: 563px;

    max-width: 375px;
    width: 47%;
    /* height: 563px; */
    height: 660px;
  }

  ${breakpoints.laptop} {
    // width: 998px;
    // height: 563px;

    // width: 399px;
    /* height: 563px; */
    height: 660px;
  }

  ${breakpoints.desktop} {
    // width: 1366px;
    // height: 849px;

    max-width: none;
    width: 30%;
    min-height: 849px;
    height: 80%;

    // width: 538px;
    // height: 849px;
  }

  .white-bg {
    width: 100%;
    height: 100%;
    background: linear-gradient(150deg, rgba(255, 255, 255, 0.2) 5%, #000);
    position: absolute;
    // top: 7%;
    // left: 35%;
  }
`;

export const LoginSideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledForm = styled.form`
  width: 100%;
  // ${flex.col};
  // justify-content: space-between;
  // align-items: center;

  ${breakpoints.desktop} {
    .label {
      font-size: 14px;
      text-align: left;
      font-weight: 400;
      display: block;
    }
  }

  .dropzone {
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    // width: 120px;
    margin: 0 auto 24px;
    background-color: ${theme("theme", {
      light: light.highlight,
      dark: dark.highlight,
    })};
    ${flex.col};
    ${flex.center};
    font-size: ${textSizes["18"]};
    cursor: pointer;
    border: 2px dashed transparent;
    transition: border-color var(--transition);

    .icon {
      opacity: 0.5;
    }

    &:hover,
    &:focus,
    &.active {
      border-color: ${theme("theme", {
        light: colors.light_gray,
        dark: colors.dark,
      })};
    }

    .hint {
      display: none;
    }
  }

  ${breakpoints.tablet} {
    button[type="submit"] {
      // max-width: 200px;
      // margin: 0 auto;
    }

    .dropzone {
      // width: 200px;
    }
  }

  ${breakpoints.laptopL} {
    .wrapper {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 350px minmax(0, calc(100% - 350px));
    }

    button[type="submit"] {
      // margin: 0 0 0 auto;
    }

    .dropzone {
      width: 350px;
      padding: 20px;
      margin-bottom: 0;

      .icon {
        font-size: ${textSizes["32"]};
      }

      .hint {
        display: block;
        margin-top: 20px;
        font-size: ${textSizes["14"]};
        opacity: 0.5;
        text-align: center;
      }
    }
  }

  .checkboxForm {
    ${flex.row}
    justify-content: start;

    & .checkbox {
      padding: 4px 4px;
      // margin:0px 4px;
      font-size: 1rem;
    }

    ${breakpoints.desktop} {
      .label {
        display: block !important;
        font-size: 14px;
        text-align: left;
        font-weight: 400;
      }
    }
  }
`;

export const TeamSizeWrapper = styled.div`
  // width: 294px;
  width: 100%;
  .dropdown {
    // width: 350px;
    // height: 35px;
    // width: 294px;
    width: 100%;
    height: 37px;
    border-radius: 30px;
    padding-left: 10px;
    font-size: 0.875rem;
    margin: 0px 0 20px 0;
    // color: #909AA4;
  }

  ${breakpoints.desktop} {
    width: 400px;
    .dropdown {
      height: 50px;
      width: 400px;
    }
  }
`;

export const PhoneWrapper = styled.div`
  // width: 350px;
  // width: 294px;
  width: 100%;
  ${flex.row};
  align-items: center;
  gap: 10px;
  .countryCode {
    max-width: 104px;
    width: 100%;
    height: 37px;
    border-radius: 30px;
    font-size: 0.875rem;
    margin: 0px 0 20px 0;
  }

  .css-rtl-t3ipsp-control {
    height: 37px;
    border-radius: 30px;
    font-size: 0.875rem;

    color: black !important;
    border: none;
    box-shadow: none;

    .css-rtl-1fdsijx-ValueContainer {
      color: black !important;
      font-size: 0.875rem;
    }

    &:hover {
      border: none !important;
      outline: none !important;
      border-color: #fff !important;
    }
  }

  .css-rtl-13cymwt-control {
    height: 37px;
    border-radius: 30px;
    // padding-left: 12px;
    font-size: 0.875rem;
    text-align: left !important;
  }

  #react-select-2-listbox {
    color: black !important;
    width: 294px;
    text-align: left !important;
  }
  
  .css-rtl-1nmdiq5-menu{
    width:294px;
  }

  .phoneInput {
    // width: 180px;
    width: 100%;
    height: 37px;
    border-radius: 30px;
    padding-left: 15px;
    background: #fff;
    font-size: 0.875rem;
    color: black;

    &:placeholder {
      color: #909aa4;
    }
  }

  ${breakpoints.desktop} {
    width: 400px;
    gap: 20px;
    .countryCode {
      width: 130px;
      height: 50px;
      font-size: 0.875rem;
    }

    .phoneInput {
      width: 250px;
      height: 50px;
      font-size: 0.875rem;
    }
  }
`;

export const IntrestedInWrapper = styled.div`
  color: #fff;
  width: 100%;
  .MuiButtonBase-root {
    color: #fff !important;
  }

  .dropdown {
    width: 100%;
  }
`;

export const CaptchaWrapper = styled.div`
  /* height: 40px; */
  width: 120px;
  margin: 0 0 20px;
`;

export const LoginLink = styled(Link)`
  // font-size: ${textSizes["14"]};
  font-size: 15px;
`;

export const SelectWrapper = styled.div`
  .dropdown {
    position: relative;
    // width: 350px;
    // height: 35px;
    // width: 294px;
    width: 100%;
    height: 37px;
    background: white;
    color: black;
    // color:#6e6161;
    border-radius: 30px;
    margin: 0px 0 20px 0;
    font-size: 0.875rem !important;
  }

  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    // height: 35px;
    height: 37px;
    padding: 0 16px;
    margin: 0px 0 20px 0;
    font-size: 0.875rem;

    .arrow {
      font-size: 18px;
    }
  }

  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    max-height: 180px;
    overflow: auto;
    padding: 3px;
    border-radius: 10px;
    // padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    display: block;
    font-size: 15px;
    font-weight: 500;
    &:hover {
      // background: #f1ebeb !important;
    }
  }

  input[type="checkbox"] {
    margin: 5px 20px;
    height: 15px;
    width: 15px;
  }

  ${breakpoints.desktop} {
    width: 400px;
    .dropdown {
      width: 400px;
      height: 50px;
    }

    .dropdown-header {
      height: 50px;
      padding: 0 16px;
    }
  }
`;

export const PrivacyPolicyWrapper = styled.div`
  display:flex;
  font-size:12px;
  margin:10px 0;
  text-align:start;
  .checkbox-container{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
    .checkbox{
      margin:0;
      width:15px;
      height:15px;
    }
  }
`;

export const PrivacyLink = styled(Link)`
  color:#5776D3;
`;

PrivacyLink.defaultProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};
