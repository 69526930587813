import React from "react";

const VerticalTrapezoidalMenu = () => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="99" height="676" viewBox="0 0 99 676" fill="none">
    //   <path d="M0 47.1894C0 41.3697 0 38.4598 0.694577 35.8935C1.92769 31.3374 4.73245 27.3642 8.61272 24.6768C10.7984 23.163 13.5403 22.1887 19.0241 20.2402L60.3241 5.56504C70.5967 1.91487 75.733 0.0897808 79.8931 0.380248C87.3267 0.899292 93.8554 5.50803 96.8334 12.3388C98.5 16.1615 98.5 21.6124 98.5 32.5143V643.114C98.5 654.338 98.5 659.949 96.7794 663.825C93.7037 670.753 86.9762 675.346 79.4042 675.689C75.1684 675.881 69.9417 673.838 59.4884 669.752L18.1884 653.609C12.9338 651.555 10.3064 650.528 8.21361 649.002C4.49897 646.292 1.83138 642.385 0.659973 637.939C0 635.434 0 632.613 0 626.972V47.1894Z" fill="url(#paint0_linear_65_2213)"/>
    //   <defs>
    //     <linearGradient id="paint0_linear_65_2213" x1="7.71584" y1="-8.00004" x2="160.269" y2="5.59754" gradientUnits="userSpaceOnUse">
    //       <stop stopColor="#141426"/>
    //       <stop offset="1" stopColor="#3C2945"/>
    //     </linearGradient>
    //   </defs>
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      // height="676"
      height="80vh"
      viewBox="0 0 76 676"
      fill="none"
    >
      <path
        d="M0 47.1894C0 41.3697 0 38.4598 0.694577 35.8935C1.92769 31.3374 4.73245 27.3642 8.61272 24.6768C10.7984 23.163 13.5403 22.1887 19.0241 20.2402L60.3241 5.56504C70.5967 1.91487 75.733 0.0897805 79.8931 0.380248C87.3267 0.899292 93.8554 5.50803 96.8334 12.3388C98.5 16.1615 98.5 21.6124 98.5 32.5143V643.114C98.5 654.338 98.5 659.949 96.7794 663.825C93.7037 670.752 86.9762 675.346 79.4042 675.689C75.1684 675.881 69.9417 673.838 59.4884 669.752L18.1884 653.609C12.9338 651.555 10.3064 650.528 8.21361 649.002C4.49897 646.292 1.83138 642.385 0.659973 637.939C0 635.434 0 632.613 0 626.972V47.1894Z"
        fill="url(#paint0_linear_176_2416)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_176_2416"
          x1="7.71584"
          y1="-8.00004"
          x2="160.269"
          y2="5.59754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#141426" />
          <stop offset="1" stopColor="#3C2945" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VerticalTrapezoidalMenu;
