import SettingIcon from "@fonts/menu_icons/settingIcon";
import DocumentIcon from "@fonts/menu_icons/documentIcon";
import SubscriptionIcon from "@fonts/menu_icons/subscriptionIcon";
import AccountIcon from "@fonts/menu_icons/accountIcon";
import ReviewIcon from "@fonts/menu_icons/reviewIcon";
import DashboardIcon from "@fonts/menu_icons/dashboardIcon";
import FaqIcon from "@fonts/menu_icons/faqIcon";
import WorkflowIcon from "@fonts/menu_icons/workflowIcon";
import DocManagementIcon from "@fonts/menu_icons/docManagementIcon";

export const menu = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    link: "/dashboard/home",
  },
  {
    icon: DocumentIcon,
    name: "Documents",
    link: "/dashboard/documents/Invoice",
  },
  {
    icon: WorkflowIcon,
    name: "Automation",
    link: "/dashboard/automation",
  },
  {
    icon: DocManagementIcon,
    name: "Files",
    link: "/dashboard/Files",
  },
  {
    icon: FaqIcon,
    name: "FAQ",
    link: "/dashboard/faq",
  },
  {
    icon: SubscriptionIcon,
    name: "Subscription",
    link: "/dashboard/subscription",
  },
  {
    icon: SettingIcon,
    name: "Settings",
    link: "/dashboard/settings",
  },
];

export const superAdminMenu1 = [
  {
    icon: AccountIcon,
    name: "Accounts",
    link: "/superAdmin/account",
  },
  {
    icon: DashboardIcon,
    name: "Dashboard",
    link: "/superAdmin/superAdminDashboard",
  },
];

export const superAdminMenu2 = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    link: "/superAdmin/userDashboard",
  },
  {
    icon: ReviewIcon,
    cat: "Review",
    links: [
      { name: "Invoices", link: "/superAdmin/review/Invoice" },
      { name: "Bill of Lading", link: "/superAdmin/review/BillOfLading" },
    ],
  },
];
