import Dashboard from "@assets/menu_icons/Dashboard.svg";
// import Dashboard from "@assets/menu_icons/Group.svg";
import Document from "@assets/menu_icons/Documents.svg";
import Training from "@assets/menu_icons/Training.svg";
import Faq from "@assets/menu_icons/faq.svg";
import Subscription from "@assets/menu_icons/Subscription.svg";
import Setting from "@assets/menu_icons/Setting.svg";
import Account from "@assets/menu_icons/Accounts.svg";
import Review from "@assets/menu_icons/Review.svg";
import SettingIcon from "@fonts/menu_icons/settingIcon";
import TrainingIcon from "@fonts/menu_icons/trainingIcon";
import DocumentIcon from "@fonts/menu_icons/documentIcon";
import SubscriptionIcon from "@fonts/menu_icons/subscriptionIcon";
import AccountIcon from "@fonts/menu_icons/accountIcon";
import ReviewIcon from "@fonts/menu_icons/reviewIcon";
import DashboardIcon from "@fonts/menu_icons/dashboardIcon";
import FaqIcon from "@fonts/menu_icons/faqIcon";

export const menu = [
  {
    // icon: "meter",
    // icon:Dashboard,
    icon: DashboardIcon,
    // icon: "fa-solid fa-chart-line icon",
    // icon_w: Dash,
    name: "Dashboard",
    link: "/dashboard/home",
  },
  {
    // icon: "file-text",
    // icon: Document,
    icon: DocumentIcon,
    cat: "Documents",
    // name: "Documents",
    links: [
      { name: "Invoices", link: "/dashboard/documents/Invoice" },
      { name: "Bill of Lading", link: "/dashboard/documents/BillOfLading" },
      { name: "Purchase Order", link: "/dashboard/documents/PurchaseOrder" },
      { name: "Passport", link: "/dashboard/documents/Passport" },
      { name: "Driving License", link: "/dashboard/documents/DrivingLicense" },
      // { name: "KYC", link: "/dashboard/documents/Kyc" },
    ],
  },
  {
    // icon: "accessibility",
    // icon: Training,
    icon: TrainingIcon,
    cat: "Training",
    // name: "Training",
    links: [
      { name: "Invoices", link: "/dashboard/training/Invoice" },
      { name: "Bill of Lading", link: "/dashboard/training/BillOfLading" },
      { name: "Purchase Order", link: "/dashboard/training/PurchaseOrder" },
      { name: "Passport", link: "/dashboard/training/Passport" },
      { name: "Driving License", link: "/dashboard/training/DrivingLicense" },
      // { name: "KYC", link: "/dashboard/training/Kyc" },
    ],
  },
  {
    // icon: "question",
    // icon: Faq,
    icon: FaqIcon,
    name: "Help / FAQ",
    link: "/dashboard/faq",
  },
  {
    // icon: "lifebuoy",
    // icon: Subscription,
    icon: SubscriptionIcon,
    name: "Subscription",
    link: "/dashboard/subscription",
  },
  // {
  //   icon: "lifebuoy",
  //   name: "Help",
  //   link: "/dashboard/help",
  // },
  {
    // icon: "settings",
    // icon: Setting,
    icon: SettingIcon,
    name: "Settings",
    link: "/dashboard/settings",
  },
];

export const superAdminMenu1 = [
  {
    // icon: "profile",
    // icon: Account,
    icon: AccountIcon,
    name: "Accounts",
    link: "/superAdmin/account",
  },
  {
    // icon: "meter",
    // icon: Dashboard,
    icon: DashboardIcon,
    name: "Dashboard",
    link: "/superAdmin/superAdminDashboard",
  },
];

export const superAdminMenu2 = [
  {
    // icon: "meter",
    // icon: Dashboard,
    icon: DashboardIcon,
    name: "Dashboard",
    link: "/superAdmin/userDashboard",
  },
  {
    // icon: "file-text",
    // icon: Review,
    icon: ReviewIcon,
    cat: "Review",
    links: [
      { name: "Invoices", link: "/superAdmin/review/Invoice" },
      { name: "Bill of Lading", link: "/superAdmin/review/BillOfLading" },
      // { name: "KYC", link: "/superAdmin/documents/Kyc" },
    ],
  },
];
