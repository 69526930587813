import React from "react";

const TrainingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 513 513"
      fill="none"
      className="icon"
    >
      <path
      className="gradient"
        d="M498.389 162.624L350.357 14.5925C330.901 -4.86417 299.371 -4.86417 279.914 14.5925C260.457 34.0492 260.457 65.5792 279.914 85.0359L323.169 128.291L128.291 323.188L85.0359 279.932C65.5792 260.476 34.0492 260.476 14.5925 279.932C-4.86417 299.389 -4.86417 330.919 14.5925 350.376L162.624 498.408C182.081 517.864 213.611 517.864 233.068 498.408C252.524 478.951 252.524 447.421 233.068 427.964L189.812 384.709L384.672 189.812L427.927 233.068C447.384 252.524 478.914 252.524 498.371 233.068C517.828 213.611 517.828 182.081 498.389 162.624ZM40.3515 469.955L43.0263 472.63C60.3944 489.998 84.9443 495.44 107.076 489.522L23.4781 405.924C17.5421 428.037 22.9834 452.587 40.3515 469.955ZM472.612 43.0447L469.937 40.3698C452.569 23.0017 428.019 17.5605 405.888 23.4781L489.485 107.076C495.421 84.9626 489.98 60.4128 472.612 43.0447Z"
        fill="white"
      />
    </svg>
  );
};

export default TrainingIcon;
