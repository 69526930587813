import styled from "styled-components/macro";
import theme from "styled-theming";
import {
  colors,
  dark,
  breakpoints,
  effects,
  flex,
  fonts,
  light,
  textSizes,
  shadow,
} from "@styles/vars";

export const Search = styled.form`
  position: relative;
  flex-grow: 1;

  ${breakpoints.tablet} {
    margin-left: 26px;
    max-width: 540px;
  }

  ${breakpoints.laptopL} {
    margin-left: 0;
    width: 100%;
  }
`;

export const Label = styled.label`
  color: ${colors.gray};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  padding: 10px 10px 10px 50px;
  font-size: ${textSizes["14"]};
  background-color: ${theme("theme", {
    light: light.highlight,
    dark: dark.widgetBg,
  })};
  transition: box-shadow var(--transition);

  &:hover {
    box-shadow: ${theme("theme", {
      light: `0 0 0 2px #A2C0D4`,
      dark: `0 0 0 2px ${colors.dark}`,
    })};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  /* gap: 0px; */
  // flex-grow: 1;

  ${breakpoints.mobileM} {
    gap: 0px;
  }

  ${breakpoints.tablet} {
    gap: 20px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  height: 60px;

  &.user-info {
    /* background-color: ${colors.white}; */
    background: var(
      --bg,
      linear-gradient(122deg, #141426 4.81%, #3c2945 100.76%)
    );
    border: 1px solid #fff;
    /* 
    background-color: ${theme("theme", {
      light: light.highlight,
      dark: dark.widgetBg,
    })}; */
    box-shadow: 0 0 4px 1px #0000001f;
    padding: 2px 3px;
    /* border-radius: 8px; */
    border-radius: 30px;
    margin: 0;
  }
  .info {
    ${flex.col}
    /* margin: 0 20px 0 0; */
    position: relative;

    .position {
      font-family: ${fonts.accent};
      font-size: ${textSizes["10"]};
      text-transform: uppercase;
    }
  }

  .trigger {
    /* color: ${theme("theme", {
      light: "#BBCDD9",
      dark: colors.gray,
    })}; */
    color: #fff;
    margin: 10px 15px;
    scale: 1.2;
  }

  ${breakpoints.mobileS} {
    &.user-info {
      width: 165px;

      margin-left: 10px;
    }
    .trigger {
      .icon-chevron-down {
        visibility: hidden;
      }
    }
  }
  ${breakpoints.mobileL} {
    &.user-info {
      width: 190px;
    }
  }

  ${breakpoints.tablet} {
    gap: 10px;
    height: 45px;

    &.user-info {
      width: unset;
      .user_profile {
        width: 40px;
        height: 40px;
      }
    }

    .trigger {
      .icon-chevron-down {
        visibility: visible;
      }
    }
  }

  ${breakpoints.laptopL} {
    margin-right: 20px;
    gap: 20px;
    height: 60px;

    &.user-info {
      .user_profile {
        width: 50px;
        height: 50px;
      }
    }
  }

  ${breakpoints.desktop} {
    margin-right: 0;
  }

  @media screen and (max-width: 426px) {
    gap: 10px;
    // background-color: cyan;
    &.user-info {
      padding: 1px 2px;
      /* border-radius: 4px; */
      border-radius: 30px;
    }
    .info {
      ${flex.col}
      // margin: 0 20px 0 0;
      margin:5px 10px;
      position: relative;
      // display: none;
      .position {
        font-family: ${fonts.accent};
        font-size: ${textSizes["0.5"]};
      }
    }
  }
`;

export const Menu = styled.div`
  position: absolute;
  // bottom: -100px;
  top: 40px;
  left: -66px;
  padding: 20px;
  gap: 16px;
  border-radius: 8px;
  font-size: ${textSizes["14"]};
  ${flex.col}
  box-shadow: ${effects.widgetShadow};
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  min-width: 160px;
  width: max-content;
  background-color: ${theme("theme", {
    light: light.widgetBg,
    dark: dark.widgetBg,
  })};
  background: linear-gradient(122deg, #141426 4.81%, #3c2945 100.76%), #c4c4c4;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      transition: color var(--transition);
      font-size: ${textSizes["16"]};
    }

    &:hover,
    &:focus {
      i {
        color: ${theme("theme", {
          light: colors.blue,
          dark: "#fff",
        })};
      }
    }
  }

  ${breakpoints.mobileM} {
    padding: 10px;
    left: -40px;
    min-width: 130px;
  }

  ${breakpoints.tablet} {
    padding: 20px;
    left: -66px;
    min-width: 160px;
  }
`;

export const MenuNotify = styled.div`
  position: absolute;
  bottom: -100px;
  // right: -99px;
  left: -266px;
  top: 32px;
  overflow: scroll;
  height: fit-content;
  max-height: 230px;
  padding: 10px 0;
  // gap: 16px;
  border-radius: 16px;
  border: 1px solid #d2d2d2;
  font-size: ${textSizes["14"]};
  ${flex.col}
  box-shadow: ${effects.widgetShadow};
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  min-width: 160px;
  width: max-content;
  /* background-color: ${theme("theme", {
    light: light.widgetBg,
    dark: dark.widgetBg,
  })}; */

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  .item {
    cursor: pointer;
    display: block;
    padding: 12px 14px;
    border-top: 1px solid #e7e7e7;
    background: #fbfbfbfc;
    color: #787777;
    font-weight: 500;
    font-size: 13px;

    /* border: 2px solid var(--stroke, #fff);
    background: radial-gradient(
      107.32% 141.42% at 0% 0%,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(21px); */

    &:last-child {
      border-bottom: 1px solid #e7e7e7;
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      transition: color var(--transition);
      font-size: ${textSizes["16"]};
    }

    &:hover,
    &:focus {
      i {
        color: ${theme("theme", {
          light: colors.blue,
          dark: "#fff",
        })};
      }
    }
  }

  ${breakpoints.mobileM} {
    left: -180px;

    .item {
      padding: 8px;
      font-size: 12px;
    }
  }

  ${breakpoints.tablet} {
    left: -266px;

    .item {
      padding: 12px 14px;
      font-size: 13px;
    }
  }

  ${breakpoints.laptop} {
    /* left: -266px; */

    .item {
      /* padding: 12px 14px; */
      /* font-size: 13px; */
    }
  }
`;

export const Header = styled.header`
  padding: 16px 20px;
  display: flex;
  /* display: inline-block; */
  /* align-items: baseline; */
  align-items: center;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
  /* height: 110px; */
  background-color: ${theme("theme", {
    light: light.bodyBg,
    dark: dark.bodyBg,
  })};
  /* background-color: transparent; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: top 0.5s ease-in-out;
  gap: 16px;
  /* padding: 20px 30px !important; */
  border-bottom: 3px solid transparent;
  border-bottom: 2px solid #646464;
  /* border-radius: 20px 20px 0 0; */
  // border-image: linear-gradient(90deg, #552e59 0%, #fff8ff 100%) 0 0 100% 0;

  &.hidden {
    top: -200px;
  }

  &.sticky {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    top: 0;
  }

  button.square {
    background-color: ${theme("theme", {
      light: light.widgetBg,
      dark: dark.widgetBg,
    })};

    &:hover,
    &:focus {
      background-color: ${colors.blue};
    }
  }

  /* ${breakpoints.mobileM} {
    padding: 10px 5px !important;
    padding: 0;
    padding: 20px 30px;
    height: fit-content;
  } */

  ${breakpoints.tablet} {
    padding: 20px 30px !important;
    padding-left: 95px !important;
    /* padding: 0; */
    /* position: static; */
    height: fit-content;
  }

  ${breakpoints.laptopL} {
    padding: 20px 30px !important;
    /* padding: 0; */
    position: static;
    box-shadow: none !important;
  }

  ${breakpoints.desktop} {
    padding: 0 30px;
    .logo-wrapper {
      width: 240px;
    }

    ${Search} {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 426px) {
    display: flex;
    padding: 16px 10px;
    gap: 10px;
  }
`;

export const ContentTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 18px;
  /* font-size: 28px; */
  font-size: 42px;
  font-weight: 400;
  color: ${theme("theme", {
    light: light.heading,
    dark: dark.heading,
  })};
  /* border: 3px solid transparent; */
  // border-image: linear-gradient(
  //     90deg,
  //     rgb(85, 46, 89) 0%,
  //     rgb(255, 248, 255) 100%
  //   )
  //   0 0 100% / 1 / 0 stretch;
  border-image: linear-gradient(
      90deg,
      #fc9132 0%,
      #fa0137 23.23%,
      rgba(213, 10, 133, 0.77) 50.83%,
      rgba(185, 16, 193, 0.19) 81.04%,
      rgba(185, 16, 193, 0.06) 96.14%
    )
    0 0 100% / 1 / 0 stretch;

  /* @media screen and (max-width: 426px) {
    font-size: 24px;
  } */

  ${breakpoints.mobileM} {
    font-size: 30px;
  }
  ${breakpoints.tablet} {
    font-size: 32px;
  }
  ${breakpoints.laptop} {
    font-size: 36px;
  }
  ${breakpoints.laptopL} {
    font-size: 42px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* gap: 20px; */
  /* margin-bottom: 14px; */

  .select {
    height: 36px;
    min-width: 145px;
    /* width: auto; */
    /* background: #141426; */
    background: ${theme("theme", {
      light: "fff",
      dark: "#141426",
    })};
    color: #fff;
    border-radius: 18px;
    padding: 0 10px;
    color: #d1d1d1;
    color: ${theme("theme", {
      light: "#212121",
      dark: "#d1d1d1",
    })};
    font-size: 18px;
    font-weight: 400;
    border-radius: 50px;
    border: 1px solid var(--stroke, #fff);
    border: ${theme("theme", {
      light: "1px solid var(--stroke, #000)",
      dark: "1px solid var(--stroke, #fff)",
    })};

    option {
      font-size: 14px;
      padding: 5px 0;
      margin: 10px;
    }
  }

  button {
    background-color: ${colors.white};
    padding: 8px 12px;
    ${shadow.borderShadow};
    border-radius: 12px;
    transition: color var(--transition), background-color var(--transition);
    transition: transform 0.2s ease-in;

    .icon {
      color: ${colors.gray};
      transition: color var(--transition);
      border-right: 1px solid #ddd;
      padding-right: 8px;
    }

    .text {
      // display: none;
    }

    &:hover {
      // background-color: ${colors.white};
      color: #777;
      transform: scale(0.9);

      .icon {
        color: #777;
      }
    }
  }

  ${breakpoints.mobileM} {
    img {
      height: 44px;
      width: 32px;
      margin-right: 10px;
      scale: 1.5;
    }
  }
  ${breakpoints.tablet} {
    img {
      height: 54px;
      width: 42px;
      margin-right: 5px;
    }
  }

  ${breakpoints.laptop} {
    img {
      /* background: crimson; */
    }
  }

  ${breakpoints.laptopL} {
    // margin-top: 40px;
    img {
      height: 84px;
      width: 72px;
      margin-right: 10px;
    }
  }

  ${breakpoints.desktop} {
    flex-direction: row;
    ${flex.between};
  }
`;
