import { useState, useEffect } from "react";
import instance from "@utils/instance";
import api from "api";
import { useAuth } from "@contexts/AuthProvider";

export const useGetDashData = (urlArr) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setErro] = useState("");
  const auth = useAuth();

  const urls = [
    "/dashboard/getDocumentCount",
    "/dashboard/getTotalSales",
    "/dashboard/getGstReceivedVsPaid",
    "/dashboard/getDocumentUsage",
  ];
  const fetchDashData = (urls) => {
    Promise.all(
      urls.map((endpoint) =>
        api.get(endpoint, {
          headers: { "x-access-token": auth.user.accessToken },
        })
      )
    ).then(
      ([
        { data: documentCount },
        { data: totalSales },
        { data: gstPaidReceived },
        { data: documentUsage },
      ]) => {
        // console.log({ user, repos, followers, following });
        // [{data: user}, {data: repos}, {data: followers}, {data: following}]
        console.log({
          documentCount,
          totalSales,
          gstPaidReceived,
          documentUsage,
        });
      }
    );
  };

  useEffect(() => {
    fetchDashData(urls);
    // disable-eslint-next-line
  }, []);

  return { data, loading, error };
};
