import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import Widget from "@components/Widget";
import WidgetHeader from "@components/Widget/WidgetHeader";
import WidgetBody from "@components/Widget/WidgetBody";
import Legend from "@ui/Legend";
import LegendItem from "@ui/Legend/LegendItem";
import { colors } from "@styles/vars";

const DobuleBarChart = ({ value, width, height }) => {
  const [data, setData] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date();
  const month = months[date.getMonth()];

  const getMonthIndex = value.map((ele) => ele.name);
  // // // Add remaining months
  const obj = months.map((ele, i) =>
    getMonthIndex.includes(ele)
      ? value.filter((e) => e.name === ele)[0]
      : {
          name: ele,
          paid: i % 2 !== 0 ? 25 + i : 2 * i,
          free: i % 2 === 0 ? 25 + i : 2 * i,
        }
  );
  const orderData = (arr, month) => {
    // re-order{rotate} months, currentMonth at last
    while (arr[arr.length - 1].name !== month) {
      let last = arr.pop();
      arr.unshift(last);
    }
    // // shorten month name
    let data = arr.map((ele) => ({
      ...ele,
      name: ele.name.substring(0, 3),
    }));
    return data;
  };

  useEffect(() => {
    setData(orderData(obj, month));
  }, []);

  return (
    <Widget
      name="HepatitisChart"
      style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)" }}
    >
      <WidgetHeader title="FREE vs PAID">
        <Legend>
          <LegendItem color="paid_users" legend="paid users" />
          <LegendItem color="free_users" legend="free users" />
        </Legend>
      </WidgetHeader>
      <WidgetBody
        style={{
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          // fontFamily: "Roboto",
          fontFamily: "Inter, sans-serif",
          fontStyle: "normal",
          fontWeight: "none",
          fontSize: "12px",
        }}
      >
        <ResponsiveContainer>
          <BarChart
            // width={850}
            // height={300}
            // width={width}
            // height={height}
            data={data}
            barGap={-10}
            barSize={20}
            margin={{
              top: 40,
              right: 20,
              left: 10,
              bottom: 20,
            }}
            style={{ borderRadius: "20px" }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              horizontal="true"
              vertical=""
            />
            <XAxis dataKey="name" />
            <YAxis domain={[0, (dataMax) => dataMax * 2]} />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="paid" fill="#5C6ECD" radius={[10, 10, 0, 0]} />
            <Bar dataKey="free" fill="#56D0D8" radius={[10, 10, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </WidgetBody>
    </Widget>
  );
};

export default DobuleBarChart;
