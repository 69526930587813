import React from "react";
import styled from "styled-components";

const StyledSVG = styled.svg`
  &:hover .gradient,
  &:focus .gradient {
    fill: url(#linearGradient);
  }
  &:hover .fillWhite,
  &:focus .fillWhite {
    fill: #fff;
  }
`;

const FaqIcon = ({ active }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 427 427"
      fill="none"
      className="icon"
    >
      <path
        className="gradient"
        d="M0.333984 213.333C0.333984 95.6968 95.6973 0.333496 213.334 0.333496C330.971 0.333496 426.334 95.6968 426.334 213.333C426.334 330.97 330.971 426.333 213.334 426.333C95.6973 426.333 0.333984 330.97 0.333984 213.333Z"
        // fill="white"
        fill={active ? "url(#linearGradient)" : "white"}
      />
      <path
        className="fillWhite"
        d="M215.468 113.067H213.335C192.001 113.067 172.801 123.734 162.135 140.8C153.601 151.467 155.735 164.267 166.401 170.667C177.068 177.067 189.868 174.934 196.268 164.267C200.535 157.867 206.935 155.734 213.335 155.734H215.468C226.135 155.734 234.668 164.267 234.668 174.934C234.668 183.467 228.268 192 221.868 194.134L206.935 198.4C198.401 200.534 192.001 209.067 192.001 217.6V234.667C192.001 247.467 200.535 256 213.335 256C224.001 256 234.668 247.467 234.668 236.8C260.268 228.267 277.335 204.8 277.335 177.067C277.335 142.934 249.601 113.067 215.468 113.067Z"
        // fill="#9A9A9A"
        fill={active ? "white" : "#9A9A9A"}
      />
      <path
        className="fillWhite"
        d="M213.333 320C225.115 320 234.667 310.449 234.667 298.667C234.667 286.885 225.115 277.333 213.333 277.333C201.551 277.333 192 286.885 192 298.667C192 310.449 201.551 320 213.333 320Z"
        // fill="#9A9A9A"
        fill={active ? "white" : "#9A9A9A"}
      />
    </StyledSVG>
  );
};

export default FaqIcon;
