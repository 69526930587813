// styled components
import { Header } from "./newStyle";

// components
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Logo from "@ui/Dox/Logo";
import MenuButton from "@ui/MenuButton";
// import Content1 from "./Content";

// hooks
import { useSidebarContext } from "@contexts/sidebarContext";
import { useWindowSize } from "react-use";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useAuth } from "@contexts/AuthProvider";
import { menu, superAdminMenu1, superAdminMenu2 } from "@constants/newMenu";
import { useEffect } from "react";
import Content from "./Content/newSidebar";
import CurrentUser from "@layout/Panel/CurrentUser";

const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebarContext();
  const { direction, companyId, setCompanyId, isShowMenu1, setIsShowMenu1 } =
    useInterfaceContext();
  const { width } = useWindowSize();
  const auth = useAuth();
  // const companyId = searchParams.get("id");
  const isMobile = width < 768,
    isDesktop = width >= 768;
  // localStorage.removeItem("companyId")
  // if (companyId) {
  //   setIsShowMenu1(false);
  //   // console.log("sidebar if", isShowMenu1);
  //   // setIsShowMenu1(!isShowMenu1);
  // } else {
  //   setIsShowMenu1(true);
  //   // console.log("sidebar else", isShowMenu1);
  // }

  useEffect(() => {
    if (companyId) {
      setIsShowMenu1(false);
    } else {
      setIsShowMenu1(true);
    }
  }, [companyId]);
  return (
    <SwipeableDrawer
      open={!isDesktop ? isSidebarOpen : true}
      onClose={toggleSidebar}
      onOpen={toggleSidebar}
      PaperProps={{
        style: {
          width: isMobile ? "50%" : "var(--drawer-width)",
          height: "calc(100% + 1px)",
          flexDirection: "column",
          padding: isMobile ? "0px" : "25px",
          paddingTop: isMobile ? "25px" : "45px",
          paddingRight: "0px",
          borderRadius: isMobile ? "0 40px 40px 0" : "0doc",
          // borderRightColor: theme === "dark" ? colors.dark : colors.light_gray,
          right: direction === "rtl" ? 0 : "auto",
          left: direction === "rtl" ? "auto" : 0,
        },
        className: "sidebar",
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
      transitionDuration={{
        enter: 400,
        exit: 250,
      }}
      sx={{
        zIndex: 20000,
      }}
      variant={isDesktop ? "persistent" : "temporary"}
    >
      <Header>
        {width > 767 && (
          <Logo role={auth.user.role} style={{ paddingRight: "40px" }} />
        )}

        {/* <MenuButton /> */}
        {width < 767 && <CurrentUser />}
      </Header>
      {!auth.isSuperAdmin() ? (
        <Content data={menu} />
      ) : (
        <Content data={isShowMenu1 ? superAdminMenu1 : superAdminMenu2} />
      )}
    </SwipeableDrawer>
  );
};

export default Sidebar;
