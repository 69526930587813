import {
  Container,
  EmailContainer,
  FileUploadContainer,
  ImportWrapper,
  PopUp,
  EmailPopUp,
  ProgressWrapper,
  DocUploadPopUp,
} from "./style";
import { useState, useEffect } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";
import DropFiles from "@components/DropFiles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import DoxBtn from "@ui/Dox/DoxBtn";
import ShowFiles from "../ShowFiles";
import api from "api";
import { useAuth } from "@contexts/AuthProvider";
import Progress from "@ui/Progress";
import { colors } from "@styles/vars";
import convertPdfToImages from "@utils/pdfToImage";
import b64toBlob from "@utils/b64ToBlob";
import { nanoid } from "nanoid";
import instance from "@utils/instance";
import gmailIcon from "@assets/gmail_icon.png";
import mailIcon from "@assets/mailIcon.png";
import CopyToClipboard from "@ui/CopyToClipboard";
import GoogleDriveImport from "./drive";
import UploadFileIcon from "@fonts/menu_icons/uploadFileIcon";
const originalBaseURL = instance.defaults.baseURL;

const UploadPopUp = () => {
  const {
    isUploadPopUpShow,
    setIsUploadPopUpShow,
    notification,
    setNotification,
    documentType,
    showDrivePopup,
    setShowDrivePopup,
    isUploading,
    setIsUploading,
    uploadCount,
    setUploadCount,
    totalUploadCount,
    setTotalUploadCount,
    currentWorkflowId,
  } = useInterfaceContext();
  const [selectedFileType, setSelectedFileType] = useState("");
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  // const [showDrivePopup, setShowDrivePopup] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  // const [uploadCount, setUploadCount] = useState(0);
  // eslint-disable-next-line
  // const [isUploading, setIsUploading] = useState(false);
  const [showDocUploadPopup, setShowDocUploadPopup] = useState(false);
  const hint = "Drag files here or click to select file";
  const auth = useAuth();

  const changeHandler = (inputFiles) => {
    setError("");
    if (inputFiles.length < 51) {
      setFiles(inputFiles);
    } else {
      // setError("Only 50 files allowed");
      toast.error("Only 50 files allowed", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const play = () => {
    // new Audio("/src/assets/answer-tone-2870.mp3").play();
    new Audio(
      "https://phoneky.co.uk/content/mp3tones/tone/2020/classic/1secondton_87a501f05076308.mp3"
    ).play();
  };

  const fileTypeHandler = (e) => {
    const type = e.target.value;
    setSelectedFileType(type);
  };

  const closePopUpHandler = () => {
    setSelectedFileType("");
    setFiles([]);
    setError("");
    // setIsUploading(false);
    setIsUploadPopUpShow(false);
    // setUploadCount(0);
  };

  const uploadHandler = async () => {
    const filesData = [...files];
    setFiles([]);
    let count = 0;
    let notify = [];
    let error403ToastShown = false;
    setError("");
    const uploadId = nanoid(5);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": auth.user.accessToken,
      },
    };

    try {
      setIsUploadPopUpShow(false);
      setIsUploading(true);

      // Number of parallel uploads allowed
      const maxParallelUploads = 10;

      const uploadPromises = [];

      setTotalUploadCount({
        ...totalUploadCount,
        [uploadId]: filesData.length,
      });
      setUploadCount({ ...uploadCount, [uploadId]: 0 });

      const incrementCount = () => {
        count += 1;
        setUploadCount((prev) => ({ ...prev, [uploadId]: prev[uploadId] + 1 }));
      };

      for (let i = 0; i < filesData.length; i++) {
        if (uploadPromises.length >= maxParallelUploads) {
          // Wait for any one of the current uploads to complete before adding more to the queue.
          await Promise.race(uploadPromises);
        }

        let file = filesData[i];
        let name = file.name;
          // if (process.env.REACT_APP_OCR_TEST === "true") {
          //   if (documentType === "Invoice" || documentType === "BankStatement") {
          //     instance.defaults.baseURL = "https://ocr-test.docxster.ai/api";
          //   }
          // }
        const formData = new FormData();
        // formData.append("document", documentType);
        formData.append("document", file, name);
        formData.append("workflowId",currentWorkflowId );
        const uploadPromise = instance
          .post("/document/document-upload", formData, config)
          .then((res) => {
            instance.defaults.baseURL = originalBaseURL;
            if (res.status === 200) {
              incrementCount();
              notify.push({
                id: nanoid(),
                type: documentType,
                msg: `${name} is ready for review.`,
                tab: "In-Review",
                user: auth.user.email,
              });
            }
            if (Math.round((count / filesData.length) * 100) === 100) {
              count = 0;
              play();
            }
          })
          .catch((e) => {
            instance.defaults.baseURL = originalBaseURL;
            if (e.response.status === 500) {
              // setError("Something went wrong. Try again.");
              toast.error("Something went wrong. Try again.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            if (e.response.status === 403 && !error403ToastShown) {
              toast.error(e.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
            error403ToastShown = true;
          });
        instance.defaults.baseURL = originalBaseURL;
        uploadPromises.push(uploadPromise);

        // Remove completed promises to maintain the parallel limit
        uploadPromise.finally(() => {
          const index = uploadPromises.indexOf(uploadPromise);
          if (index !== -1) {
            uploadPromises.splice(index, 1);
          }
        });
      }

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
    } catch (e) {
      if (e.response.status === 500) {
        instance.defaults.baseURL = originalBaseURL;
        // setError("Something went wrong. Try again.");
        toast.error("Something went wrong. Try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } finally {
      if (notify.length > 0) {
        const length = notify.length > 5 ? 0 : 5;
        const oldNotify = !notification.isMarkAsRead
          ? notification.notifications
          : notification.notifications.slice(0, length);
        const notifications = [...notify, ...oldNotify];
        setNotification({
          isMarkAsRead: false,
          notifications: notifications,
        });
      }

      setTimeout(() => {
        // if (setError === "") {
        // setIsUploading(false);
        // closePopUpHandler();
        // }
      }, 1000);
    }
  };

  const showEmailPopUp = () => {
    // setIsUploadPopUpShow(false);
    setShowEmailPopup(true);
  };

  useEffect(() => {
    switch (documentType) {
      case "Invoice":
        setEmailValue("invoice@docxster.com");
        break;
      case "BillOfLading":
        setEmailValue("billoflading@docxster.com");
        break;
      case "PurchaseOrder":
        setEmailValue("purchaseorder@docxster.com");
        break;
      case "Passport":
        setEmailValue("passport@docxster.com");
        break;
      case "DrivingLicense":
        setEmailValue("license@docxster.com");
        break;
      default:
        setEmailValue("");
    }

    // if(documentType)
  }, [documentType]);
  return (
    <>
      {/* {showDocUploadPopup && <DocUploadPopUp></DocUploadPopUp>} */}
      <EmailPopUp style={{ display: showEmailPopup ? "flex" : "none" }}>
        <EmailContainer>
          <span className="title">Import via email</span>
          <span className="close-icon" onClick={() => setShowEmailPopup(false)}>
            <i className={`icon icon-close`}></i>
          </span>
          {/* <img src={gmailIcon} alt="gmail" className="emailIcon" /> */}
          <img src={mailIcon} alt="gmail" className="emailIcon" />
          <div className="queWrapper">
            <div className="queRow">
              <InfoIcon className="info" />
              <label>How does this work?</label>
            </div>
            <p>
              Simply send files as email attachments to the receiving address
              below and Docxster will process them. (Maximum attachments per
              email: 20)
            </p>
          </div>
          <div className="copyToClipboardWrapper">
            <div className="queRow">
              <label>Send emails to us at</label>
            </div>
            <CopyToClipboard value={emailValue} />
          </div>
        </EmailContainer>
      </EmailPopUp>

      <PopUp style={{ display: isUploadPopUpShow ? "flex" : "none" }}>
        <Container>
          <span className="title">Upload Files</span>
          <FileUploadContainer>
            <span className="close-icon" onClick={closePopUpHandler}>
              <i className={`icon icon-close`}></i>
            </span>
            <div className="pop-up-body">
              {files.length > 0 ? (
                <ShowFiles files={files} />
              ) : (
                <DropFiles
                  multiple={true}
                  type="doc"
                  handler={changeHandler}
                  style={{ marginTop: "4px" }}
                >
                  <UploadFileIcon />
                  <i className="icon icon-image" aria-label={hint} />
                  <span className="hint">{hint}</span>
                </DropFiles>
              )}
            </div>

            {error && <p className="text success">{error}</p>}
            {/* {isUploading && !error && (
              <ProgressWrapper>
                <Progress
                  value={Math.round((uploadCount / totalUploadCount) * 100)}
                  color={colors.sidebar_bg}
                  style={{ width: "50%" }}
                />
                {uploadCount}
              </ProgressWrapper>
            )} */}
            {/* {
              playAudio && <audio controls autoPlay src="/src/assets/mixkit-correct-answer-tone-2870.wav" style={{display:"none"}}></audio>
            
            } */}
            {/* {playAudio && (
              <AudioPlayer
                // style={{ display: "none" }}
                src="/src/assets/mixkit-correct-answer-tone-2870.wav"
                autoPlay
                controls
                // onEnded={() => setPlayAudio(false)} // Reset the play state after audio finishes
              />
            )} */}
            <DoxBtn
              text="Submit"
              handler={uploadHandler}
              // className={files.length > 0 && !isUploading ? null : "disabled"}
            />
          </FileUploadContainer>
          <ImportWrapper onClick={showEmailPopUp}>
            <div className="left">
              {/* <img src={gmailIcon} alt="gmail" className="icon" /> */}
              <img src={mailIcon} alt="gmail" className="mail" />
              <label>Email</label>
            </div>
            <ArrowForwardIcon className="arrow" />
          </ImportWrapper>
          <GoogleDriveImport mainPopUpHandler={closePopUpHandler} />
          {/* <button
            onClick={play}
            style={{ background: "green", color: "white", padding: "10px" }}
          >
            play
          </button> */}
        </Container>
      </PopUp>
    </>
  );
};

export default UploadPopUp;
