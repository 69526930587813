// styling
import styled from "styled-components/macro";
import { colors, textSizes, fonts, flex, breakpoints } from "@styles/vars";
import { darken } from "polished";

// utils
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

const Button = styled.button`
  // border-radius: 8px;
  border-radius: 40px;
  // background-color: ${colors.blue};
  background: linear-gradient(
    90deg,
    rgba(252, 145, 51, 0.85) 0.82%,
    rgba(250, 1, 55, 0.89) 46.34%,
    rgba(185, 16, 193, 0.71) 96.93%
  );
  color: #fff;
  // font-size: ${textSizes["14"]};
  font-size: 20px;
  font-weight: 700;
  font-family: ${fonts.accent};
  // height: 40px;
  width: 100%;
  // height: 35px;
  // width: 350px;
  height: 37px;
  width: 324px;
  // margin:30px 0;
  display: flex;
  ${flex.center};
  gap: 8px;
  line-height: 1;
  transition: background-color var(--transition);

  &:hover,
  &:focus {
    background-color: ${darken(0.1, colors.blue)};
  }

  &.success {
    background-color: ${colors.success};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.success)};
    }
  }

  &.error {
    background-color: ${colors.error};

    &:hover,
    &:focus {
      background-color: ${darken(0.1, colors.error)};
    }
  }

  &.disabled {
    background-color: ${colors.green};
    pointer-events: none;
  }

  ${breakpoints.desktop} {
    width: 400px;
  }
`;

const SignUpBtn = ({
  text,
  handler,
  type = "button",
  isVisible = true,
  className,
  icon,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Button
          as={motion.button}
          className={className ? className : ""}
          onClick={handler}
          type={type}
          initial={false}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {icon && <i className={`icon icon-${icon}`} />} {text}
        </Button>
      )}
    </AnimatePresence>
  );
};

// Btn.propTypes = {
//     text: PropTypes.string.isRequired,
//     handler: PropTypes.func,
//     type: PropTypes.oneOf(['button', 'submit', 'reset']),
//     isVisible: PropTypes.bool,
//     className: PropTypes.string,
//     icon: PropTypes.string
// }

export default SignUpBtn;
