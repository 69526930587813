import styled from "styled-components/macro";
import theme from "styled-theming";
import login_bg from "@assets/login_bg.gif";
import {
  flex,
  breakpoints,
} from "@styles/vars";

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  ${flex.row}
  ${flex.center}
  /* background: #141426; */


  ${breakpoints.mobileS} {
    background: none;
    background-color: rgba(20, 20, 38, 1);
  }
  ${breakpoints.laptop} {
    background: url(${login_bg});
    background-size: 94%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(20, 20, 38, 1);
  }
`;

export const CentralContainer = styled.div`
  width: 375px;
  height: 563px;
  border-radius: 8px;
  /* border: 2px solid #fff; */
  transform: rotate(-0.062deg);
  background: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);

  ${flex.col};
  ${flex.center};

  .docxster_logo {
    position: absolute;
    top: 0px;
    scale: 0.6;
    left: -40px;
  }

  .content {
    ${flex.col};
    ${flex.center};
    gap: 12px;
    .logo {
      width: 56px;
      height: 56px;
      ${flex.col};
      ${flex.center};
      border-radius: 28px;
      border: 10px solid var(--Primary-50, #f9f5ff);
      background: var(--Primary-100, #f4ebff);
    }

    .heading {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      margin: 8px 0;
    }

    .email_btn {
      width: 324px;
      padding: 10px 18px;
      justify-content: center;
      align-self: stretch;
      margin: 16px 0;
      border-radius: 8px;
      border: 1px solid var(--e-24949, #e24949);
      background: var(
        --Brand,
        linear-gradient(102deg, #ed6804 0%, #e0192a 41.78%, #a508a5 84.79%)
      );
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .text {
      color: #f1f1f1;
      font-size: 14px;
      /* font-weight: 600; */

      .link {
        color: #00a3ff;
        cursor: pointer;
      }
    }

    .text-blur {
      color: #d9d9d9;
      font-size: 16px;
      font-weight: 400;
    }
  }

  ${breakpoints.laptop} {
    width: 375px;
    height: 563px;
  }

  ${breakpoints.desktop} {
    width: 30%;
    min-height: 849px;
    height: 80%;
  }
`;
