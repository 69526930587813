import React, { useEffect, useState } from "react";
import Widget from "@components/Widget";
import WidgetHeader from "@components/Widget/WidgetHeader";
import WidgetBody from "@components/Widget/WidgetBody";
import Legend from "@ui/Legend";
import ScrollContainer from "react-indiana-drag-scroll";
import LegendItem from "@ui/Legend/LegendItem";
import styled from "styled-components/macro";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { breakpoints } from "@styles/vars";

const Container = styled.div`
  // color: blue;

  ${breakpoints.mobileM} {
    &.areaChart {
      width: 410px;
      height: 250px;
      color: red;
    }
  }
  ${breakpoints.tablet} {
    &.areaChart {
      width: 750px;
      height: 300px;
    }
  }
  ${breakpoints.laptop} {
    &.areaChart {
      width: 850px;
      height: 300px;
    }
  }
`;

const LineGraph = ({ value }) => {
  const [data, setData] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date();
  const month = months[date.getMonth()];

  const getMonthIndex = value.map((ele) => ele.month);
  // Add remaining months
  const obj = months.map((ele, i) =>
    getMonthIndex.includes(ele)
      ? value.filter((e) => e.month === ele)[0]
      : {
          month: ele,
          A: i % 2 !== 0 ? 25 + i : 2 * i,
          B: i % 2 === 0 ? 25 + i : 2 * i,
          C: i % 2 === 0 ? 25 + i : 2 * i,
        }
  );

  const orderData = (arr, month) => {
    // re-order{rotate} months, currentMonth at last
    while (arr[arr.length - 1].month !== month) {
      let last = arr.pop();
      arr.unshift(last);
    }
    // shorten month name
    let data = arr.map((ele) => ({
      ...ele,
      month: ele.month.substring(0, 3),
    }));
    return data;
  };

  useEffect(() => {
    setData(orderData(obj, month));
  }, []);

  return (
    <Widget
      month="HepatitisChart"
      style={{
        fontSize: "12px",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "none",
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)"
      }}
    >
      <WidgetHeader title="USERS vs PLANS">
        <Legend>
          <LegendItem color="orange" legend="A" />
          <LegendItem color="blue" legend="B" />
          <LegendItem color="purple" legend="C" />
        </Legend>
      </WidgetHeader>
      <WidgetBody
        style={{
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
          // background: "#2B2B4B",
        }}
      >
        {/* <Container> */}
          <ResponsiveContainer >
            <AreaChart
              className="areaChart"
              // width={850}
              // height={300}
              // style={{
              //   width:"100%",
              //   height:"100%"
              // }}

              // data={value}
              as={ScrollContainer}
              data={data}
              // data={data1}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorA" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8440EE" stopOpacity={0.8} />
                  <stop offset="100%" stopColor="#FD749B" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorB" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FCBD77" stopOpacity={0.7} />
                  <stop offset="100%" stopColor="#FD79B3" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="month"
                // tick={{ stroke: "#ffffFf" }}
                style={{ fontWeight: "none" }}
              />
              <YAxis
                // tick={{ stroke: "#ffffff" }}
                domain={[0, (dataMax) => dataMax * 2]}
              />
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal="true"
                vertical="true"
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="A"
                stroke="#F39034"
                fillOpacity={1}
                fill="url(#colorA)"
              />
              <Area
                type="monotone"
                dataKey="B"
                stroke="#0194EB"
                fillOpacity={1}
                fill="url(#colorB)"
              />
              <Area
                type="monotone"
                dataKey="C"
                stroke="#964FEF"
                fillOpacity={1}
                fill="url(#colorC)"
              />
            </AreaChart>
          </ResponsiveContainer>
        {/* </Container> */}
      </WidgetBody>
    </Widget>
  );
};

export default LineGraph;
