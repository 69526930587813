import React from "react";
import styled from "styled-components";

const StyledSVG = styled.svg`
  &:hover .gradient,
  &:focus .gradient {
    fill: url(#linearGradient); 
  }
`;

const DocumentIcon = ({ active }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 384 427"
      fill="none"
      className="icon"
    >
      <path
            className="gradient"
        d="M281.408 0C347.307 0 384 37.9733 384 103.04V323.413C384 389.547 347.307 426.667 281.408 426.667H102.613C37.76 426.667 0 389.547 0 323.413V103.04C0 37.9733 37.76 0 102.613 0H281.408ZM108.373 293.12C101.973 292.48 95.7867 295.467 92.3733 301.013C88.96 306.347 88.96 313.387 92.3733 318.933C95.7867 324.267 101.973 327.467 108.373 326.613H275.627C284.139 325.76 290.56 318.485 290.56 309.973C290.56 301.227 284.139 293.973 275.627 293.12H108.373ZM275.627 195.819H108.373C99.1787 195.819 91.7333 203.307 91.7333 212.48C91.7333 221.653 99.1787 229.12 108.373 229.12H275.627C284.8 229.12 292.267 221.653 292.267 212.48C292.267 203.307 284.8 195.819 275.627 195.819ZM172.139 99.2H108.373V99.4133C99.1787 99.4133 91.7333 106.88 91.7333 116.053C91.7333 125.227 99.1787 132.693 108.373 132.693H172.139C181.333 132.693 188.8 125.227 188.8 115.819C188.8 106.667 181.333 99.2 172.139 99.2Z"
        // fill="white"
        fill={active ? "url(#linearGradient)" : "white"}
      />
    </StyledSVG>
  );
};

export default DocumentIcon;
