// styles
import GlobalStyles from "@styles/global";
import "react-grid-layout/css/styles.css";
import "./fonts/icomoon/style.css";
import "./fonts/icomoons/style.css";
import "@fonts/icomoon3/style.css";
import "@fonts/fonts-1/style.css";
import "swiper/css";
import "swiper/css/effect-fade";

// components
import { SnackbarProvider } from "notistack";
import { Navigate, Route, Routes } from "react-router-dom";

// utils
import { ThemeProvider, StyleSheetManager } from "styled-components";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// contexts
import { SidebarContextAPI } from "@contexts/sidebarContext";

// hooks
import { useEffect, useState } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useDispatch } from "react-redux";

// actions
import { saveToLocalStorage } from "@store/features/layout";
import Login from "@pages/Login";
import ProtectedRoute from "@utils/ProtectedRoute";
import ForgetPassword from "@widgets/Dox/Login/Forms/ForgetPassword";
import VerifyForgetPassword from "@widgets/Dox/Login/Forms/VerifyForgetPassword";
import VerifyEmail from "@widgets/Dox/VerifyEmail/verify";
import VerifiedEmail from "@widgets/Dox/VerifyEmail/verify";
import DashboardRoute from "DashboardRoute";
import SignUp from "@widgets/Dox/SignUp";
import { useAuth } from "@contexts/AuthProvider";
import SuperDashboardRoute from "SuperAdmin/SDashboardRoute";
import CheckRole from "CheckRole";
import CheckEmail from "@widgets/Dox/VerifyEmail/checkEmail";

const App = () => {
  const auth = useAuth();
  const page = document.documentElement;
  const { isDarkMode, isContrastMode, direction } = useInterfaceContext();
  const logout = () => auth.logOut();
  const [isInactive, setIsInactive] = useState(false);
  let activityTimer = null;
  const theme = createTheme({
    direction: direction,
  });
  const cacheRtl = createCache({
    key: "css-rtl",
    stylisPlugins: [rtlPlugin],
  });

  useDispatch()(saveToLocalStorage());

  useEffect(() => {
    page.setAttribute("dir", direction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    isContrastMode && page.classList.add("contrast");
    preventDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //In activity logout
  const setupInactivityTimeout = () => {
    activityTimer = setTimeout(handleLogout, 20 * 60 * 1000); // 20 minutes
  };

  const resetInactivityTimeout = () => {
    clearTimeout(activityTimer);
    setupInactivityTimeout();
    setIsInactive(false);
  };

  const handleLogout = () => {
    logout();
    // Update the component state to show that the user is inactive
    setIsInactive(true);
  };

  useEffect(() => {
    setupInactivityTimeout();
    const events = ["mousemove", "keydown", "mousedown"];

    const attachEventListeners = () => {
      events.forEach((event) => {
        document.addEventListener(event, resetInactivityTimeout);
      });
    };

    const removeEventListeners = () => {
      events.forEach((event) => {
        document.removeEventListener(event, resetInactivityTimeout);
      });
    };

    attachEventListeners();

    return () => {
      clearTimeout(activityTimer);
      removeEventListeners();
    };
  }, []);

  if (isInactive) {
    return <div>Logout due to inactivity</div>;
  }

  return (
    <CacheProvider value={cacheRtl}>
      <ToastContainer/>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={{ theme: isDarkMode ? "dark" : "light" }}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "top",
              horizontal: direction === "ltr" ? "right" : "left",
            }}
            autoHideDuration={3000}
          >
            <SidebarContextAPI>
              <GlobalStyles />
              <StyleSheetManager
                stylisPlugins={direction === "rtl" ? [rtlPlugin] : []}
              >
                <Routes>
                  <Route path="/" element={<CheckRole />} />
                  {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
                  <Route
                    path="/dashboard/*"
                    element={
                      <ProtectedRoute>
                        <DashboardRoute />
                      </ProtectedRoute>
                    }
                  />

                  {/* <Route path="/" element={<Navigate to="/superAdmin" />} /> */}
                  <Route
                    path="/superAdmin/*"
                    element={
                      <ProtectedRoute>
                        <SuperDashboardRoute />
                      </ProtectedRoute>
                    }
                  />

                  <Route path="/auth/login" element={<Login />} />
                  <Route
                    path="/auth/forget-password"
                    element={<ForgetPassword />}
                  />
                  <Route
                    path="/auth/verifyResetPassword"
                    element={<VerifyForgetPassword />}
                  />
                  <Route path="/auth/signup" element={<SignUp />} />
                  <Route path="/auth/checkEmail" element={<CheckEmail />} />
                  <Route path="/auth/verifyEmail" element={<VerifyEmail />} />
                </Routes>
              </StyleSheetManager>
            </SidebarContextAPI>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default App;
