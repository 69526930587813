import { useState, useRef, useEffect } from "react";
import { LinksList, List, MainItem } from "../newStyle";
import { colors } from "@styles/vars";

// components
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";

// hooks
import useWindowSize from "@hooks/useWindowSize";
import { useSidebarContext } from "@contexts/sidebarContext";

// menu links
import { menu } from "@constants/menu";
import { useAuth } from "@contexts/AuthProvider";
import SettingIcon from "@fonts/menu_icons/settingIcon";
import ThemeToggleSwitch from "@ui/Dox/ThemeToggleBtn";

const Content = ({ data }) => {
  const { toggleSidebar } = useSidebarContext();
  const { width } = useWindowSize();
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItem1, setSelectedItem1] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const elementRef = useRef(null);
  const dashboardRef = useRef(null);
  const helpRef = useRef(null);
  // const isMobile = width < 768;
  const activeStyle = { color: colors.white };
  const auth = useAuth();
  const logout = () => auth.logOut();

  // For selection of documents and training
  const handleCatSelection = (item) => {
    setSelectedItem(item);
    const menuItem = elementRef.current;
    const dashboardEle = dashboardRef.current;
    const helpEle = helpRef.current;

    if (selectedItem1) {
      selectedItem1.classList.remove("active");
    } else if (item === "Review") {
      dashboardEle.classList.remove("active");
    } else {
      menuItem.classList.remove("active");
      dashboardEle.classList.remove("active");
      helpEle.classList.remove("active");
    }
    dashboardEle.classList.remove("active");
  };

  // For selection of dashboard, help/FAQ, and settings
  const handleItemSelection = (e) => {
    e.target.classList.remove("active");
    setSelectedItem1(e.target);
    toggleSidebar();
    setSelectedItem("");
  };

  useEffect(() => {
    if (width < 768 && width !== 0) {
      setIsMobile(true);
    }
  }, [width]);

  return (
    <List as={Accordion}>
      {data.map((item, ind) => {
        return (
          <MainItem
            as={NavLink}
            to={item.link}
            onClick={(e) => handleItemSelection(e)}
            // style={({ isActive }) => (isActive ? activeStyle : undefined)}
            key={item.link}
          >
            <item.icon />
            {/* {width < 768 && item.name} */}
            {isMobile && item.name}
          </MainItem>
        );
      })}
      {isMobile && (
        <div className="logout_wrapper" onClick={logout}>
          {" "}
          <i className="icon icon-logout" />
          Logout
        </div>
      )}
      {isMobile && (
        <div className="theme_btn_wrapper">
          {" "}
          <ThemeToggleSwitch className="" />
        </div>
      )}
    </List>
  );
};

export default Content;
