// styling
import styled from "styled-components/macro";
import { breakpoints, colors, flex, shadow } from "@styles/vars";
import { CardActionArea } from "@mui/material";

// components
import Page from "@layout/Page";
import { Container } from "@components/Widget/style";
import Lottie from "lottie-react";
import Btn from "@ui/Btn";
import { motion } from "framer-motion";
import WidgetsLoader from "@components/WidgetsLoader";
import instance from "@utils/instance";

// hooks
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "@contexts/AuthProvider";
import { useEffect, useState } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useAxios } from "@hooks/dox/useAxios";
// import SAMirrorChart from "./SuperAdminChart/MirrorChart/MirrorChart";
import SAPiechart from "./SuperAdminChart/PieChart/PieChart";
import HistoryIcon from "@mui/icons-material/History";
import globe from "@assets/globe.svg";
// import LastLogin from "@widgets/Dox/LastLogin";
// import SADobuleBarChart from "./SuperAdminChart/DoubleBarChart/DobuleBarChart";
// import SACalendar from "./SuperAdminChart/Calendar/Calendar";
// import Carousal from "./SuperAdminChart/Carousal/Carousal";
// import RadarChart from "./SuperAdminChart/RadarChart/RadarChart";
// import SARadarChart from "./SuperAdminChart/RadarChart/RadarChart";

const FlexContainer = styled(Container)`
  ${flex.row};
  justify-content: left;
  // justify-content: start;
  padding: 24px;
  flex-wrap: wrap;

  ${breakpoints.mobileM} {
    flex-grow: initial;
  }

  ${breakpoints.tablet} {
    flex-grow: inherit;
  }
`;

const MainContainer = styled.div`
  ${flex.row}
  gap: 30vw;
  // background-color: blue;
`;

const LeftColumn = styled.div`
  ${flex.col}
  margin-left:30px;
  gap: 30px;
  // background-color: cyan;
`;

const RightColumn = styled.div`
  ${flex.col}
  gap: 30px;
  // background-color: pink;
`;

const RowWrapper = styled.div`
  width: 850px;
  display: flex;
  gap: 50px;
  flex-direction: row;
  border-radius: 24px;
  // background-color: #ffffff;
`;

const Welcome = styled.div`
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 850px;
  height: 152px;
  background: linear-gradient(
    163.34deg,
    #141426 16.21%,
    rgba(68, 43, 74, 0.99) 76.67%,
    #552e59 95.58%
  );
  border-radius: 24px;
  margin-top: 30px;
  p {
    width: 180px;
    height: 48px;
    padding: 0px 30px;
    // font-family: "Inter";
    /* font-family: "Roboto"; */
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 48px;

    color: #ffffff;
  }

  .welcomeImg {
    position: absolute;
    width: 381.29px;
    height: 281.74px;
    left: 790px;
    top: 12px;
  }
`;

const GraphWrapper = styled.div`
  display: flex;
  height: 308px;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #77777726;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  .secondRow {
  }
`;

const CalendarWrapper = styled.div`
  display: flex;
  height: 230px;
  border-radius: 24px;
  overflow: auto;
  border: none;
  outline: none;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

// const LastLogin = styled.div`
//   height: 308px;
//   border-radius: 24px;
//   background-color: #ffffff;
//   // ${shadow.borderShadow}
//   border: 1px solid #77777726;
//   box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

//   p {
//     padding: 10px 20px;
//     font-family: Inter;
//     font-weight: 600;
//     font-size: 20px;
//   }
// `;
const TotalUsage = styled.div`
  display: flex;
  width: 350px;
  border-radius: 40px;
  background-color: #ffffff;
  border: 1px solid #77777726;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;
const PlansWrapper = styled.div`
  ${flex.col}
  width: 300px;
  gap: 30px;
  border-radius: 24px;
`;

const Plan = styled.button`
  width: 300px;
  height: 92px;
  border-radius: 24px;
  /* font-family: Roboto; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;

  &:nth-child(1) {
    background: linear-gradient(94.24deg, #f39034 3.89%, #ff2f28 95.53%);
  }
  &:nth-child(2) {
    background: linear-gradient(95.37deg, #0194eb 0%, #0141d4 100%);
  }
  &:nth-child(3) {
    background: linear-gradient(93.83deg, #964fef 0.68%, #6008cd 98.87%);
  }
`;
const ImgWrapper = styled.div`
  .welcomeImg {
    position: absolute;
    width: 381.29px;
    height: 281.74px;
    left: 790px;
    top: 12px;
  }

  .welcomeImg2 {
    // position: absolute;
    position: sticky;
    width: 330px;
    height: 350px;
    right: 65px;
    bottom: -330px;
  }
`;

const LastLogin = styled.div`
  height: 308px;
  border-radius: 24px;
  background-color: #ffffff;
  border: 1px solid #77777726;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  p:first-child {
    padding: 20px 0px 0px 25px;
    /* font-family: "Rubik", sans-serif; */
    font-family: "Inter", sans-serif !important;
    font-weight: 600;
    // font-family: Roboto;
    // font-weight: 600;
    font-size: 21px;
  }

  p:nth-child(2) {
    padding: 15px 0px 30px 25px;
    /* font-family: Roboto; */
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    font-size: 20px;
  }

  .clock {
    position: relative;
    top: 4px;
    right: -4px;
  }

  .globe {
    width: 45px;
    height: 50px;
    position: relative;
    top: -84px;
    right: -255px;
  }
`;

const UserDashboard = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const documentType = location.pathname.split("/").splice(-1)[0];
  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoginObj, setLastLoginObj] = useState("");
  // const [searchParams, setSearchParams] = useSearchParams(null);
  const {
    companyId,
    setCompanyId,
    companyName,
    setCompanyName,
    isShowMenu1,
    setIsShowMenu1,
  } = useInterfaceContext();
  const { userLoginList, setUserLoginList } = useState([]);
  // console.log(companyId)
  const { response, loading, error } = useAxios({
    method: "post",
    url: "/admin/fetchDashboard",
    headers: {
      "x-access-token": auth.user.accessToken,
    },
    data: {
      companyId: localStorage.getItem("companyId"),
    },
  });

  // console.log("userDashboard", isShowMenu1);
  setCompanyName(localStorage.getItem("companyName"));
  // setCompanyId(localStorage.getItem("companyId"));
  useEffect(() => {
    setCompanyId(localStorage.getItem("companyId"));
    // setCompanyName();
  }, [companyId]);

  useEffect(() => {
    setIsShowMenu1(false);
  }, [isShowMenu1]);
  // console.log(companyId);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // console.log("compId",companyId,companyName)
  // if (response) {
  //   console.log(response.userLoginList);
  // }

  useEffect(() => {
    if (response) {
      setLastLoginObj(response?.userLoginList[0]);
    }
  },[response]);
  return (
    <>
      {loading ? (
        <WidgetsLoader />
      ) : error ? (
        <h4 style={{ display: "block", textAlign: "center" }}>{error}</h4>
      ) : (
        <Page title={`${localStorage.getItem("companyName")} Dashboard`}>
          <MainContainer>
            <LeftColumn>
              <TotalUsage style={{ height: "350px" }}>
                <SAPiechart value={response.getDocumentCount} />
              </TotalUsage>
            </LeftColumn>

            <RightColumn>
              <LastLogin style={{ width: "325px", height: "100px" }}>
                {/* <p>Last login</p> */}
                <p>
                  Last Login
                  <HistoryIcon className="clock" />
                </p>
                <p>Mon, 25 Sep 2023 14:45 </p>
                <div>
                  <img className="globe" src={globe} alt="img" />
                </div>
              </LastLogin>
            </RightColumn>
          </MainContainer>
          {/* <LastLogin loginObj={response?.userLoginList[0]}/> */}

          {/* <LastLogin style={{ width: "325px", height: "130px" }}>
                <p>
                  Last Login
                  <HistoryIcon className="clock" />
                </p>
                {lastLoginObj && (
                  <p>{`${lastLoginObj.lastLoginDay.slice(0,3)} , ${lastLoginObj.lastLoginDate.slice(8, 10)} ${ months[lastLoginObj.lastLoginDate.slice(5, 7) - 1]} ${lastLoginObj.lastLoginDate.slice(0,4)} ${lastLoginObj.lastLoginTime.slice(0, 5)} `}</p>
                )}
                <div>
                  <img className="globe" src={globe} alt="img" />
                </div>
              </LastLogin> */}
          {/* <LastLogin loginObj={response?.userLoginList[0]}/> */}
          {/* </RightColumn> */}
          {/* <FlexContainer
            as={motion.div}
            i
            nitial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
          >
            <DashWrapper>
              <ChartsWrapper>
                <Charts className="chart">
                  <PieChart value={response.getDocumentCount} />
                </Charts>
                <Charts className="chart">
                  <Plans data={response?.userLoginList.slice(0, 10)} />
                </Charts>
              </ChartsWrapper>
            </DashWrapper>
          </FlexContainer> */}

          {/* <MainContainer>
            <LeftColumn>
              <RowWrapper style={{ height: "266px" }}>
                <Carousal />
              </RowWrapper>
              <RowWrapper className="secondRow" style={{ height: "308px" }}>
                <GraphWrapper style={{ width: "500px" }}>
                  <SAMirrorChart value={response.gstReceivedVsPaid} />
                </GraphWrapper>
                <GraphWrapper style={{ width: "300px" }}></GraphWrapper>
              </RowWrapper>
              <RowWrapper className="secondRow" style={{ height: "308px" }}>
                <GraphWrapper style={{ width: "500px" }}>
                  <SADobuleBarChart />
                </GraphWrapper>
                <GraphWrapper style={{ width: "300px" }}>
                  <SARadarChart />
                </GraphWrapper>
              </RowWrapper>
            </LeftColumn>

            <RightColumn>
              <LastLogin style={{ width: "325px", height: "100px" }}>
                <p>Last login</p>
                <p>Fri , 23 Jun 23 10:45 pm </p>
                <div>
                  <h3></h3>
                  <img src="" alt="" />
                </div>
              </LastLogin>
              <CalendarWrapper style={{ width: "325px", height: "230px" }}>
                <SACalendar className="calendar" />
              </CalendarWrapper>
              <GraphWrapper
                style={{ width: "325px", height: "260px" }}
              ></GraphWrapper>
              <TotalUsage style={{ height: "260px" }}>
                <SAPiechart value={response.getDocumentCount} />
              </TotalUsage>
            </RightColumn>
          </MainContainer> */}
        </Page>
      )}
    </>
  );
};

export default UserDashboard;
