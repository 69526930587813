// styling
import styled from "styled-components/macro";
import { textSizes, light, colors } from "@styles/vars";

const Container = styled.div`
    margin: 0;
    p {
        font-size: ${textSizes[14]};
    }
    .error {
        color: ${colors.red};
    }
    .success {
        color: ${colors.green};
    }
`;

const Message = ({ className = "", children }) => {
    return (
        <Container>
            <p className={className}>{children}</p>
        </Container>
    );
};

export default Message;
