import styled from "styled-components/macro";
import { colors, breakpoints, flex, textSizes, fonts } from "@styles/vars";

const navItem = `
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    font-size: ${textSizes["16"]};
    gap: 18px;
    font-family: ${fonts.body};
    .menu{
      height:30px;
      width:30px;
      margin-left:7px;
        }
    .icon {
        color: ${colors.sidebar_icon};
        transition: color var(--transition);
        // transition: transform .5s;
        // padding-left:10px;
      scale:1.2;
    }
    &:hover, &:focus,
    &.active {
      width:100%;
      font-weight:500;
        .icon {
            color:transparent;
            // font-size:30px;
            // transform: scale(1.1);
            -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(138.29deg, rgba(252, 145, 51, 0.89) 3.37%, rgba(250, 1, 55, 0.79) 49.11%, #B810C5 92.43%);
    fill: url(#linearGradient);
  }
.gradient{
  fill: url(#linearGradient);
  height:30px;
  width:30px;
}

.fillWhite{
  fill: white;
}

}
`;

export const Header = styled.div`
  margin-bottom: 60px;
  margin-right: 25px;

  /* display: flex;
  justify-content: space-between;
  align-items: center; */

  ${breakpoints.tablet} {
    button {
      display: none;
    }
  }
`;

export const List = styled.ul`
  ${flex.col}
  // gap: 18px;
  /* justify-content: space-around;
  gap: 10px; */
  justify-content: flex-start;
  /* gap: 60px; */
  gap: 40px;
  /* overflow: hidden; */

  .logout_wrapper {
    padding: 0 25px;
    .icon-logout {
      margin-right: 25px;
      font-weight: bold;
    }
    &:hover {
      font-size: 16px;
      color: crimson;
    }
  }

  .theme_btn_wrapper {
    margin: 50px 0 0 50px;
  }

  ${breakpoints.mobileM} {
    gap: 20px;
  }

  ${breakpoints.tablet} {
    height: 100%;
    /* gap: 60px; */
    gap: 30px;
    .pin-down {
      margin-top: auto;
    }
  }

  ${breakpoints.laptopL} {
    gap: 40px;
  }
`;

export const MainItem = styled.div`
  ${navItem};
  height: 40px;
  /* padding: 60px 0; */
  // transition: all 0.3s ease-in-out;
  .icon {
    font-size: 16px;
    // transition: all 0.3s ease-in-out;
  }

  width: 100%;
  button {
    ${navItem};
    font-size: large;
    transition: none;
    width: 100%;
    &.active,
    &:hover,
    &:focus {
      font-size: 16px;
      color: crimson;
      // background: white;
      // background: #b4a8a8;
      // padding: 5px 5px 5px 0px;
      padding: 0px;
      height: 40px;
      /* border-radius: 40px 0px 0px 40px;   */
      // border-radius: 40px 0px 80px 40px;
      // border-radius: 80px 0px 80px 0px;
      width: 100%;
      cursor: pointer;
    }
  }

  &:last-child {
    .icon {
      font-size: 22px;
    }
  }

  ${breakpoints.mobileM} {
    border-bottom: 1px solid white;
    padding-left: 20px;
    padding-bottom: 20px;

    &:hover,
    &:focus,
    &.active {
      font-size: 16px;
      color: crimson;
      /* background: white; */
      // padding: 5px 5px 5px 0px;
      padding: 0px;
      height: 40px;
      padding-left: 20px;
      padding-bottom: 20px;
      /* border-radius: 40px 0px 0px 40px; */
      width: 100%;
    }
  }

  ${breakpoints.tablet} {
    border-bottom: none;
    font-size: 16px;
    padding-left: unset;
    padding-bottom: unset;

    &:hover,
    &:focus,
    &.active {
      font-size: 16px;
      color: crimson;
      /* background: white; */
      // padding: 5px 5px 5px 0px;
      padding: 0px;
      height: 40px;
      padding-left: unset;
      padding-bottom: unset;
      /* border-radius: 40px 0px 0px 40px; */
      width: 100%;
    }

    button {
      font-size: 16px;
    }
  }
`;

export const LinksList = styled.ul`
  ${flex.col}
  gap: 16px;
  margin: 16px 0 0 44px;
  font-size: ${textSizes["16"]};
  color: #d8c4d8c9;
  a {
    // transition: color var(--transition);

    &:hover,
    &:focus {
      color: ${colors.white};
    }
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes["14"]};
  }
`;
