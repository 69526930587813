import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";

import WidgetsLoader from "@components/WidgetsLoader";

import AppLayout from "../AppLayout";
import ViewFile from "@pages/Dox/ViewFile";

// pages
import SuperAdminDashboard from "SuperAdmin/SuperAdminDashboard";
import Account from "SuperAdmin/Account/Account";
import ReviewDetails from "SuperAdmin/ReviewDetails";
import UserDashboard from "./UserDashboard";

const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const Review = lazy(() => import("SuperAdmin/Review"));

const SuperDashboardRoute = () => {
  return (
    <Suspense fallback={<WidgetsLoader />}>
      <Routes>
        <Route path="/*" element={<AppLayout />}>
          <Route index element={<Navigate to="/superAdmin/superAdminDashboard" />} />
          <Route path="superAdminDashboard" element={<SuperAdminDashboard />} />
          <Route path="account" element={<Account />} />
          <Route path="review/:docType" element={<Review />} />
          <Route path="userDashboard" element={<UserDashboard />} />
          <Route path="404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/superAdmin/404" replace />} />
        </Route>
        <Route path="/view-file" element={<ReviewDetails />} />
      </Routes>
    </Suspense>
  );
};

export default SuperDashboardRoute;
