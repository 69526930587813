import React from "react";
import Widget from "@components/Widget";
import WidgetHeader from "@components/Widget/WidgetHeader";
import WidgetBody from "@components/Widget/WidgetBody";
import Legend from "@ui/Legend";
import LegendItem from "@ui/Legend/LegendItem";
import { PieChart, Pie, Sector, Cell } from "recharts";

const COLORS = ["#FF6968", "#0F123F"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MultiCircleDonut = ({ value }) => {
  const data = [
    { name: "Active Users", value: value.active },
    { name: "Total Users", value: value.total - value.active },
  ];
  return (
    <Widget
      name="HepatitisChart"
      style={{ boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)" }}
    >
      <WidgetHeader title="ACTIVE vs TOTAL">
        <Legend>
          <LegendItem color="active_users" legend="Active Users" />
          <LegendItem color="total_users" legend="Total Users" />
        </Legend>
      </WidgetHeader>
      <WidgetBody
        style={{
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PieChart width={250} height={390}>
          <Pie
            data={data}
            cx={120}
            cy={200}
            innerRadius={30}
            outerRadius={70}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            key={Math.random()}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </WidgetBody>
    </Widget>
  );
};

export default MultiCircleDonut;
