// styled components
import {
  Container,
  FlexContainer,
  Content,
  LoginSideImage,
  StyledForm,
  LoginLink,
  LoginContainer,
  InputContainer,
  ToggleButton,
} from "./style";

// components
import { useState, useEffect } from "react";
import Btn from "@ui/Btn";
import Message from "@ui/Message";
import LoginFormInput from "@ui/LoginFormInput";
import LoginImage from "@assets/loginImage.jpg";
import toastAlert from "@widgets/Dox/CustomToastAlert";
// import login_quote_bg from "@assets/login_quote_bg.jpeg";
import logoLight from "@assets/logo/logo-light.png";
import { useAuth } from "@contexts/AuthProvider";
import { useNavigate } from "react-router-dom";

import Loader from "@ui/Dox/Loader";
import QuoteCard from "./quotesCard";
import LoginBtn from "@ui/Btn/loginBtn";
//import icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

const initialUser = { email: "", password: "" };

const loginQuotes = [
  "Every journey begins with a single step, and every online adventure starts with a sign-in.",
  "The 'Sign In' process is where your offline self meets its digital counterpart.",
  "Sign-in to access your dreams; the password is determination.",
  "In a world of endless connections, the sign-in button is your bridge to the internet's wonders.",
  "Behind the 'Sign In' door lies a world of opportunities, knowledge, and wonder.",
];

const LogIn = () => {
  const [user, setUser] = useState(initialUser);
  const [error, setError] = useState("");
  //usestate to showpassword
  const [showPassword, setShowPassword] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const changeHandler = (e) => {
    setError("");
    auth.setAuthError("");
    setUser({ ...user, [e.target.id]: e.target.value });
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    var data = user.email;
    var atSign = data.indexOf("@");
    var dotSign = data.lastIndexOf(".");
    if (!user.email || !user.password) {
      toastAlert("Email or Password can't be blank", "error");
    } else if (
      atSign < 1 ||
      dotSign < atSign + 2 ||
      dotSign + 2 >= data.length
    ) {
      toastAlert("Please enter valid email", "error");
    } else {
      await auth.login(user);
    }
  };

  // const roleNavigate = (role) => {
  //   // console.log(role);
  //   // if (role === "test") {
  //   //     navigate("/", { replace: true });
  //   // } else if (role === "test1") {
  //   //     navigate("/profile", { replace: true });
  //   // }
  //   navigate("/dashboard/home", { replace: true });
  // };

  const roleNavigate = (role) => {
    //if you face any issue related to login uncomment below five line
    // if (role === "user") {
    //   navigate("/dashboard/home", { replace: true });
    // } else {
    // navigate("/", { replace: true });
    // }

    navigate("/", { replace: true });
  };

  //function to toggle show password
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    auth.setAuthError("");
    if (auth.user) {
      roleNavigate(auth.user.role);
    }
    // roleNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <Container>
      <Content>
        <div className="white-bg"></div>
        {/* <FlexContainer className="left-image">
         <LoginSideImage src={LoginImage} alt="image" /> 
          <QuoteCard quotes={loginQuotes} />
        </FlexContainer> */}

        <FlexContainer>
          <img
            src={logoLight}
            alt="Docxster-logo"
            className="docxster_logo"
            style={{ marginLeft: "10px" }}
          />
          <LoginContainer>
            <h1>Login</h1>
            <Message className="error">{error ? error : ""}</Message>
            <Message className="error">
              {auth.authError ? auth.authError : ""}
            </Message>
            <StyledForm
              action="#"
              method="post"
              onSubmit={(e) => e.preventDefault()}
            >
              <label className="label">Email</label>
              <LoginFormInput
                style={{
                  bordeRadius: "40px",
                  border: "1px solid #BCBEC0",
                  background: " #FFF",
                }}
                id="email"
                title="Email"
                type="email"
                placeholder="Email"
                value={user.email}
                handler={changeHandler}
              />
              <label className="label">Password</label>
              <InputContainer>
                <LoginFormInput
                  id="password"
                  title="Password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={user.password}
                  handler={changeHandler}
                />
                {user.password.length > 0 && (
                  <ToggleButton
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </ToggleButton>
                )}
              </InputContainer>
              <LoginLink
                to="/auth/forget-password"
                style={{ fontSize: "12px", color: "#5776D3" }}
              >
                Forgot Password?
              </LoginLink>
              <LoginBtn
                text={!auth.isLoading ? "Sign in" : <Loader />}
                handler={loginHandler}
                type="submit"
              />
            </StyledForm>

            <p
              style={{
                color: "#fff",
                margin: "24px 0",
                textAlign: "center ",
                fontSize: "14px",
              }}
            >
              Don't have an account?{" "}
              <LoginLink to="/auth/signup" style={{ color: "#5776D3" }}>
                {" "}
                Register for free
              </LoginLink>
            </p>
          </LoginContainer>
        </FlexContainer>
      </Content>
    </Container>
  );
};

export default LogIn;
