import React from "react";

const BotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="261"
      height="261"
      viewBox="0 0 261 261"
      fill="none"
    >
      <g opacity="0.24" filter="url(#filter0_f_3_3990)">
        <circle
          cx="130.793"
          cy="130.793"
          r="40"
          fill="url(#paint0_linear_3_3990)"
        />
      </g>
      <circle cx="130.793" cy="130.792" r="34.4428" fill="white" />
      <circle
        cx="130.793"
        cy="130.792"
        r="34.4428"
        fill="url(#paint1_linear_3_3990)"
        fill-opacity="0.8"
      />
      <circle cx="130.793" cy="130.792" r="34.4428" stroke="white" />
      <path
        d="M123.367 132.951L126.839 138.449C129.218 141.944 134.909 148.474 135.073 148.714C135.277 149.014 140.927 154.542 145.011 153.542C149.095 152.543 151.323 150.359 152.259 146.945C153.448 142.611 153.482 135.783 153.448 132.951C153.384 127.654 152.693 124.568 147.63 122.089C142.566 119.61 132.351 122.223 128.472 123.256C126.566 123.889 122.591 125.655 121.938 127.654C121.285 129.653 122.619 132.018 123.367 132.951Z"
        fill="url(#paint2_linear_3_3990)"
        fill-opacity="0.38"
      />
      <path
        d="M116.269 120.172C111.105 126.384 109.486 129.012 109.486 134.892C109.486 139.778 109.403 145.795 118.473 150.369C129.613 155.988 149.209 154.573 151.187 153.809C152.768 153.197 153.044 147.503 152.984 144.733C153.95 141.217 150.423 133.507 150.423 133.507C150.423 133.507 148.805 129.494 148.176 127.966L143.53 119.281C143.53 119.281 142.129 117.069 141.312 111.63C140.755 107.924 139.85 103.962 137.291 103.477C136.26 103.282 132.949 102.737 129.613 105.661C126.829 108.1 126.471 107.899 116.269 120.172Z"
        fill="url(#paint3_linear_3_3990)"
        fill-opacity="0.6"
      />
      <path
        d="M139.978 152.053C142.002 153.185 143.187 151.664 143.527 150.763C143.858 149.532 144.165 146.297 142.744 143.204C141.323 140.111 137.072 138.161 135.057 137.204C135.678 140.687 137.031 146.891 138.511 150.364C139.038 151.601 139.447 151.756 139.978 152.053Z"
        fill="url(#paint4_linear_3_3990)"
        fill-opacity="0.64"
      />
      <path
        d="M132.84 127.419C130.697 119.096 129.696 115.795 128.881 113.238C127.808 109.874 125.119 103.411 119.508 105.673C115.019 107.483 119.308 113.705 121.187 116.823C125.014 123.171 130.164 133.52 132.181 138.054C134.072 142.304 137.282 148.382 138.511 150.364C137.031 146.891 135.678 140.687 135.057 137.204L132.84 127.419Z"
        fill="url(#paint5_linear_3_3990)"
        fill-opacity="0.64"
      />
      <path
        d="M121.734 127.995C128.802 128.357 143.422 129.595 146.397 129.964C150.312 130.448 157.934 131.881 156.319 136.62C155.028 140.412 147.417 137.672 143.645 136.515C135.969 134.16 123.592 130.572 117.886 129.728C112.18 128.883 105.95 127.147 103.549 126.384C107.65 127.182 114.769 127.576 118.743 127.685L121.734 127.995Z"
        fill="url(#paint6_linear_3_3990)"
        fill-opacity="0.68"
      />
      <path
        d="M101.433 125.398C99.8494 123.905 101.35 122.789 102.298 122.417C103.614 122.015 107.164 121.41 110.833 122.203C114.502 122.996 117.355 126.18 118.743 127.685C114.769 127.576 107.65 127.182 103.549 126.384C102.088 126.1 101.849 125.79 101.433 125.398Z"
        fill="url(#paint7_linear_3_3990)"
        fill-opacity="0.64"
      />
      <g filter="url(#filter1_f_3_3990)">
        <circle cx="130.794" cy="128.927" r="12.1679" fill="white" />
      </g>
      <g filter="url(#filter2_f_3_3990)">
        <circle cx="130.793" cy="130.176" r="6.16059" fill="white" />
      </g>
      <path
        d="M157.353 118.07C149.669 96.329 118.731 110.838 104.222 120.81C90.216 136.905 131.31 149.124 135.075 148.885C138.841 148.647 165.038 139.811 157.353 118.07Z"
        fill="url(#paint8_linear_3_3990)"
        fill-opacity="0.4"
      />
      <defs>
        <filter
          id="filter0_f_3_3990"
          x="0.792969"
          y="0.792969"
          width="260"
          height="260"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="45"
            result="effect1_foregroundBlur_3_3990"
          />
        </filter>
        <filter
          id="filter1_f_3_3990"
          x="38.6257"
          y="36.7588"
          width="184.336"
          height="184.336"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="40"
            result="effect1_foregroundBlur_3_3990"
          />
        </filter>
        <filter
          id="filter2_f_3_3990"
          x="106.632"
          y="106.016"
          width="48.3213"
          height="48.3213"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9"
            result="effect1_foregroundBlur_3_3990"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3_3990"
          x1="90.793"
          y1="90.793"
          x2="169.509"
          y2="107.568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ED6804" />
          <stop offset="0.492708" stop-color="#E0192A" />
          <stop offset="1" stop-color="#A508A5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_3990"
          x1="95.8499"
          y1="95.8496"
          x2="164.614"
          y2="110.504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ED6804" />
          <stop offset="0.492708" stop-color="#E0192A" />
          <stop offset="1" stop-color="#A508A5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_3990"
          x1="137.829"
          y1="133.569"
          x2="139.689"
          y2="162.099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00E0FF" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_3990"
          x1="140.671"
          y1="89.2969"
          x2="110.934"
          y2="153.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3_3990"
          x1="133.494"
          y1="134.794"
          x2="152.776"
          y2="149.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00CFDC" />
          <stop offset="1" stop-color="#00F0FF" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3_3990"
          x1="134.823"
          y1="120.239"
          x2="125.648"
          y2="122.982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF5E5E" />
          <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3_3990"
          x1="119.811"
          y1="129.339"
          x2="141.062"
          y2="152.638"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#92FF6C" />
          <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3_3990"
          x1="98.0765"
          y1="120.787"
          x2="117.255"
          y2="140.985"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#339EB5" />
          <stop offset="1" stop-color="#3FB4CD" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3_3990"
          x1="128.773"
          y1="110.338"
          x2="122.049"
          y2="151.453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00FFF0" />
          <stop offset="0.729167" stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BotIcon;
