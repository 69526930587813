import styled from "styled-components/macro";
import theme from "styled-theming";
import {
  dark,
  light,
  flex,
  colors,
  shadow,
  textSizes,
  breakpoints,
} from "@styles/vars";

export const FileUploadContainer = styled.div`
  ${flex.col};
  align-items: center;
  padding: 10px 0px;
  /* border: 2px dotted #cc98d9; */
  border-radius: 8px;
  background: #fff;

  ${breakpoints.mobileM} {
    width: 245px;
  }

  ${breakpoints.tablet} {
    width: 400px;
  }

  ${breakpoints.laptop} {
    width: 500px;
  }
`;

export const ImportWrapper = styled.button`
  ${flex.row};
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 305px;
  border: 2px solid #ede2e2;
  background: #fff;
  border-radius: 5px;
  font-size: ${textSizes["18"]};
  cursor: pointer;
  /* border-image: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1; */
  padding: 0 10px;
  .left {
    ${flex.row};
    align-items: center;
  }

  .mail {
    height: 20px;
    width: 30px;
    margin-right: 15px;
    // background:black;
  }

  .icon {
    height: 30px;
    width: 30px;
    margin-right: 15px;
  }
  .arrow {
    height: 15px;
    width: 15px;
    color: #a3a3a3;
  }
  label {
    font-size: ${textSizes["14"]};
    color: #a3a3a3;
  }

  &:hover {
    /* background-color: ${theme("theme", {
      light: light.highlight,
      dark: dark.highlight,
    })}; */
    background-color: #ccc8c8;
    border: none;
  }

  ${breakpoints.mobileM} {
    width: 245px;
  }

  ${breakpoints.tablet} {
    width: 400px;
  }

  ${breakpoints.laptop} {
    width: 500px;
  }
`;

export const Container = styled.div`
  position: relative;
  ${flex.col};
  ${flex.center};
  ${shadow.borderShadow};
  justify-content: space-between;
  border-radius: 15px;
  width: 350px;
  height: 320px;
  margin: 0 auto;
  padding: 20px 10px;
  background-color: ${colors.white};
  opacity: 1;

  border-radius: 10px;
  /* border: 2px solid var(--stroke, #fff); */
  background: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    transition: transform 0.2s ease-in;
    cursor: pointer;
    color: ${theme("theme", {
      light: "black",
      dark: "ffffff",
    })};

    &:hover {
      transform: scale(1.4);
    }
  }
  .text {
    font-size: ${textSizes[14]};
    color: ${colors.gray};
  }
  .success {
    padding: 8px;
    color: ${colors.green};
  }
  .disabled {
    color: #999;
  }
  button {
    // background-color: ${colors.sidebar_bg};
    // color: ${colors.light_gray};
    &:hover,
    &focus {
      //   color: ${colors.gray};
    }
  }
  .dropzone {
    /* border-radius: 15px; */
    border-radius: 8px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* background-color: ${theme("theme", {
      light: light.highlight,
      dark: dark.highlight,
    })}; */

    ${flex.col}
    ${flex.center};
    font-size: ${textSizes["18"]};
    cursor: pointer;
    /* border: 2px dashed transparent; */
    border: 1px dashed #bcafaf;
    transition: border-color var(--transition);
    /* border-color: ${theme("theme", {
      light: colors.light_gray,
      dark: colors.dark,
    })}; */

    .icon {
      opacity: 0.5;
    }

    &:hover,
    &:focus,
    &.active {
      border-width: 3px;
    }

    .hint {
      // display: none;
      color: ${colors.gray};
      color: #727272;
    }
  }
  .d-flexy {
    ${flex.col};
    ${flex.center}
  }

  ${breakpoints.mobileM} {
    .hint {
      font-size: ${textSizes["14"]};
    }

    border-radius: 10px;
    width: 270px;
    height: 450px;
    // height: auto;
  }

  ${breakpoints.tablet} {
    .hint {
      font-size: ${textSizes["16"]};
    }
    border-radius: 15px;
    width: 500px;
    height: 450px;
    // height: auto;
  }

  ${breakpoints.laptop} {
    .hint {
      font-size: ${textSizes["18"]};
    }
    border-radius: 15px;
    // width: 350px;
    width: 600px;
    height: 500px;
  }
`;

export const EmailContainer = styled(Container)`
  width: 310px;
  height: 400px;
  justify-content: start;
  gap: 15px;

  .emailIcon {
    width: 70px;
    height: 50px;
  }
  .queWrapper {
    border: 2px solid #e1c0e1;
    border-radius: 8px;
    padding: 5px 10px;
    margin: 10px 0;
    .queRow {
      ${flex.row};
      align-items: center;
      font-size: ${textSizes["16"]};
      .info {
        height: 15px;
        width: 15px;
        margin-right: 5px;
        color: #cca5e6;
        font-weight: 800;
      }
    }

    p {
      font-size: ${textSizes["12"]};
      padding-left: 20px;
      font-weight: 300;
    }
  }
  .copyToClipboardWrapper {
    width: 288px;
  }
`;

export const PopUp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  /* background-color: rgb(0 0 0 / 12%); */
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    margin: 10px auto;
    font-size: 18px;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    /* color: ${colors.sidebar_bg}; */
    color: #fff;
  }
  .pop-up-body {
    ${flex.col};
    ${flex.center};
    gap: 18px;
    // width: 100%;
    width: 96%;
    padding: 10px;
    height: 185px;
    // height: auto;
  }
`;

export const EmailPopUp = styled(PopUp)`
  z-index: 10002;
  .title {
    font-size: 20px;
  }
  .pop-up-body {
    width: 90%;
    height: 150px;
  }
`;

export const SelectType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  margin: 10px auto;

  label {
    display: inline-flex;
    align-items: center;
    font-size: 17px;
  }
  input {
    margin: 5px;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${flex.center};
  width: 80%;
  gap: 10px;
`;

export const DocUploadPopUp = styled.div`
  width: 200px;
  height: 200px;
  z-index: 10005;
  background-color: rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
`;
