// styling
import styled from "styled-components/macro";
import { colors, textSizes, flex, shadow } from "@styles/vars";
import DoxBtn from "@ui/Dox/DoxBtn";

export const TableSection = styled.div`
  // height: 100px;
  // overflow: scroll;
  padding-top: 10px;
  border-top: 1px dotted;
  margin-top: 25px;

  .table {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    overflow-x: scroll;
    padding-bottom: 20px;
    margin-top: 24px;
  }
  .th,
  .td {
    font-size: 14px;
    width: 150px;
    // height: 24px;
    margin: 0;
  }
  .th {
    font-weight: 500;
    // border-bottom: 1px solid #888;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid;

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0px;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding-right: 16px;
    }
  }
  .td {
    // font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;

    .info,
    input {
      width: 100%;
    }
    input {
      border: 1px solid;
      border-radius: 6px;
      padding: 2px 10px;
    }
  }
`;

export const TableContainer = styled.div`
  padding-top: 10px;
  border-top: 1px dotted;
  margin-top: 25px;

  .checkbox {
    height: 18px;
    width: 18px;
    margin-top: 3px;
  }
`;

export const Table = styled.table`
  padding: 0 10px 24px 0;
`;
export const TableRow = styled.tr`
  margin: 10px 0;
  padding: 20px;
`;
export const TableHead = styled.th`
  font-size: 14px;

  font-weight: 500;
  padding: 0 10px 24px 0;
  // border-bottom: 1px solid;
  margin-bottom: 12px;

  &.qb {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 16px;
  }

  // margin: 0px 20px;
`;
export const TableData = styled.td`
  margin: 0px 20px;
  padding: 0 10px 10px 0;
`;
export const DetailsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100vh;
  // overflow: auto;
  // transition: all 0.5s;
`;

export const BackBtn = styled(DoxBtn)`
  margin: 24px 18px;
`;

export const Content = styled.div`
  height: 100%;
  flex: 1;
  padding: 24px;
  overflow: auto;
  position: relative;
  min-width: 400px;

  img {
    width: 60%;
    margin: 0 auto;
  }
`;

export const CanvasWrapper = styled.div`
  position: relative;
  touch-action: none;
`;
export const SidebarWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 250px;
  max-width: 600px;
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  background: #ffffff;
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 2;

  .resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;
    touch-action: none;

    &:hover {
      width: 3px;
      background: #71147ce6;
    }
  }
`;
export const Sidebar = styled.div`
  width: var(--drawer-width-custom);
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 18px;
  background-color: ${colors.white};
  // box-shadow: -2px 1px 5px 4px rgb(139 138 139 / 37%) !important;
  box-shadow: 0px 0px 5px 2px rgb(139 138 139 / 37%) !important;
  color: ${colors.gray};
  color: #3e4041;
  // box-shadow: 0px 0px 5px 2px rgb(71 69 69 / 14%) !important;
  z-index: 1;
  flex: 1;

  .paging-section {
    overflow: unset;
    margin: 0;
    padding: 4px;
    ${flex.row};
    ${flex.center};
    justify-content: space-between;
  }
`;

export const Paging = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  right: 50%;
  ${flex.row};
  ${flex.center}
  justify-content:end;
  gap: 10px;

  .prev,
  .next {
    ${flex.row};
    ${flex.center};
    cursor: pointer;
    border: 1px solid;
    border: 1px solid #afacac4d;
    box-shadow: 0 0 4px 1px #dddcdc42;
    border-radius: 4px;
  }

  .disable {
    color: ${colors.light_gray};
    pointer-events: none;
  }
`;

export const InvoiceSection = styled.div`
  margin: 16px auto;
  width: 100%;
  padding-bottom: 15px;
  border-top: 1px dotted ${colors.gray};
  overflow: hidden;

  &:first-child {
    border-top: none;
  }
  .details {
    ${flex.col};
    // ${flex.center}
    // margin: 5px auto;
    gap: 6px;
    height: 100%;
    padding-bottom: 20px;
    overflow: scroll;
    padding-right: 10px;
  }
  .info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 124px; */
    margin: 5px 8px 5px 28px;
    font-size: 14px;

    input {
      border: 1px solid ${colors.gray};
      flex: 2;
      // padding: 4px 10px;
      // width: 60%;
      // border-radius: 6px;
    }
    .add {
      border-radius: 11px;
      font-size: 16px;
      margin: 0;
      color: #ffffff;
    }
  }
  .name {
    align-self: start;
    margin-right: 18px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
  }

  .info {
    // font-size: 12px;
    text-align: start;
    // white-space: nowrap;
    overflow: hidden;
    // text-overflow: ellipsis;
    width: 55%;
    border: 1px solid #cfd1d3;
    cursor: pointer;
    max-height: 100px;
  }

  .info-wrapper input,
  .info {
    border-radius: 6px;
    padding: 2px 10px;
  }
  .title {
    font-size: ${textSizes[16]};
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    input {
      border: 1px solid ${colors.gray};
      border-radius: 6px;
    }

    .qb {
      width: 25px;
      height: 25px;
    }

    .download-csv {
      transition: transform 0.2s ease-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .icon {
    margin-right: 6px;
    font-size: 20px;
  }
  button {
    color: ${colors.dark};
  }
`;

export const TableHeaderConatiner = styled.div`
  height: 50px;
  ${flex.row}

  .select_wrapper {
    .select {
      height: 50px;
    }
  }

  .btn_wrapper {
    ${flex.col}
    justify-content: center;

    .btn {
      height: 22px;
      width: 22px;
      border: 2px solid #cfd1d3;
      border-radius: 0 5px 5px 0px;
      text-align: center;
      .icon {
        scale: 0.6;
        color: #fff;
        transition: scale 0.1s ease-out;

        :hover {
          scale: 0.8;
        }
      }
    }

    .deleteBtn {
      background-color: #e0192a;
    }
    .addBtn {
      background-color: #76be00;
    }
  }
`;
