import * as PDFJS from "pdfjs-dist/webpack";
// import * as PDFJS from "pdfjs-dist/legacy/build/pdf.js";

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

//param: file -> the input file (e.g. event.target.files[0])
//return: images -> an array of images encoded in base64
const convertPdfToImages = async (file) => {
  const images = [];
  const data = await readFileData(file);
  const pdf = await PDFJS.getDocument(data).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    let dimensions = page.getViewport(i + 1).viewBox.map((n) => (n / 72) * 300);
    const viewport = page.getViewport({ scale: 4.2 });
    const context = canvas.getContext("2d");
    viewport.height = dimensions[3];
    viewport.width = dimensions[2];

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    console.log(canvas);
    let img = canvas.toDataURL("image/png");
    images.push(img);
  }
  canvas.remove();
  return images;
};

export default convertPdfToImages;
