import React from "react";

const EmailErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66"
      height="66"
      viewBox="0 0 66 66"
      fill="none"
    >
      <rect x="5" y="5" width="56" height="56" rx="28" fill="#FFB3BA" />
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#FFD3D7"
        strokeWidth="10"
      />
      <path
        d="M33 19C25.2685 19 19 25.2685 19 33C19 40.7315 25.2685 47 33 47C40.7315 47 47 40.7315 47 33C47 25.2685 40.7315 19 33 19ZM33 45.25C26.245 45.25 20.75 39.755 20.75 33C20.75 26.245 26.245 20.75 33 20.75C39.755 20.75 45.25 26.245 45.25 33C45.25 39.755 39.755 45.25 33 45.25ZM33 24.25C32.5359 24.25 32.0908 24.4344 31.7626 24.7626C31.4344 25.0908 31.25 25.5359 31.25 26V34.75C31.25 35.2141 31.4344 35.6592 31.7626 35.9874C32.0908 36.3156 32.5359 36.5 33 36.5C33.4641 36.5 33.9092 36.3156 34.2374 35.9874C34.5656 35.6592 34.75 35.2141 34.75 34.75V26C34.75 25.5359 34.5656 25.0908 34.2374 24.7626C33.9092 24.4344 33.4641 24.25 33 24.25ZM31.25 39.972C31.25 40.4361 31.4344 40.8812 31.7626 41.2094C32.0908 41.5376 32.5359 41.722 33 41.722C33.4641 41.722 33.9092 41.5376 34.2374 41.2094C34.5656 40.8812 34.75 40.4361 34.75 39.972C34.75 39.7422 34.7047 39.5146 34.6168 39.3023C34.5288 39.09 34.3999 38.8971 34.2374 38.7346C34.0749 38.5721 33.882 38.4432 33.6697 38.3552C33.4574 38.2673 33.2298 38.222 33 38.222C32.7702 38.222 32.5426 38.2673 32.3303 38.3552C32.118 38.4432 31.9251 38.5721 31.7626 38.7346C31.6001 38.8971 31.4712 39.09 31.3832 39.3023C31.2953 39.5146 31.25 39.7422 31.25 39.972Z"
        fill="#DC2424"
      />
    </svg>
  );
};

export default EmailErrorIcon;
