import styled from "styled-components/macro";
import theme from "styled-theming";
// import login_bg from "@assets/login_bg.jpeg";
import login_bg from "@assets/login_bg.gif";

import {
  dark,
  light,
  flex,
  colors,
  breakpoints,
  textSizes,
} from "@styles/vars";
import { Link } from "react-router-dom";

// export const BrandLogo = styled.div`
//   // position: absolute;
//   background: white;
//   // z-index: 9;
//   width: 120px;
//   height: 120px;

//   img {
//     width: 100%;
//     height: 100%;
//   }
// `;

export const Container = styled.div`
  position: relative;
  ${flex.col};
  ${flex.center};
  height: 100vh;
  background-color: rgba(20, 20, 38, 1);

  ${breakpoints.mobileM} {
    padding: 24px;
  }

  ${breakpoints.laptop} {
    background: url(${login_bg});
    background-size: 94%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(20, 20, 38, 1);
  }
`;

export const FlexContainer = styled.div`
  position: relative;
  ${flex.col};
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #fff;
  width: 100%;
  // .docxster_logo {
  //   width: 209px;
  //   height: 74px;
  //   position: absolute;
  //   top: -26px;
  //   left: 40px;
  // }
  .docxster_logo {
    width: 80vw;
    height: 74px;
    position: absolute;
    top: -26px;
    left: 10px;
  }
  h1 {
    margin: 0 0 20px 0;
    align-self: flex-start;
    font-size: 32px;
  }
  input {
    background-color: #e1e3e63d;
    // margin: 18px 0;
    margin: 3px 0 25px 0;
    border: 1px solid #3c3c3c1a;
  }

  .label {
    // font-size: 12px;
    font-size: 14px;
    display: block;
    margin: 5px 0;
  }

  button {
    max-width: 100%;
    margin: 25px 0 18px 0;
    font-size: ${textSizes["16"]};
    text-transform: uppercase;
  }

  ${breakpoints.mobileM} {
    .docxster_logo {
      width: 189px;
      height: 67px;
      position: absolute;
      top: -40px;
      left: 0px;
    }
  }

  ${breakpoints.tablet} {
    flex: 1;

    .docxster_logo {
      width: 175px;
      height: 60px;
      position: absolute;
      // top: -4px;
      left: 15px;
    }
  }

  ${breakpoints.laptop} {
    .docxster_logo {
      width: 209px;
      height: 74px;
      position: absolute;
      top: -26px;
      left: 40px;
    }
  }

  ${breakpoints.desktop} {
    h1 {
      font-size: 38px;
    }
    .docxster_logo {
      width: 285px;
      height: 101px;
      top: -110px;
      left: 78px;
    }

    input {
      margin: 3px 0 50px 0;
    }
  }
`;

export const LoginContainer = styled.div`
  // width: 325px;
  width: 90vw;
  margin-top: 50px;

  ${breakpoints.mobileS} {
    width: 305px;
  }

  ${breakpoints.desktop} {
    width: 400px;
  }
`;

export const Message = styled.p`
  font-size: ${textSizes["14"]};

  .error {
    color: ${colors.red};
  }
  .success {
    color: ${colors.green};
  }
`;

export const Error = styled.p`
  font-size: ${textSizes["14"]};
  color: ${colors.red};
`;

export const Success = styled.p`
  font-size: ${textSizes["14"]};
  color: ${colors.green};
`;

export const Content = styled.div`
  ${flex.col};
  ${flex.center};
  text-align: left;
  // gap: 24px;
  // padding: 24px;
  width: 100%;

  // width: 998px;
  // height: 563px;

  // width: 538px;
  // height: 563px;

  width: 100%;
  height: 100%;

  // border-radius: 40px;
  // border: 1px solid;
  overflow: hidden;
  background-color: ${theme("theme", {
    light: light.highlight,
    dark: dark.highlight,
  })};
  // background: #141426;
  backdrop-filter: blur(17px) saturate(167%);
  -webkit-backdrop-filter: blur(17px) saturate(167%);
  background-color: rgba(74, 74, 74, 0.3);
  // box-shadow: 0px 0px 8px 2px #afacac50;

  .left-image {
    padding: 0;
    height: 100%;
    display: none;
  }

  ${breakpoints.mobileM} {
    border-radius: 15px;

    .left-image {
      display: none;
    }
    // width: 352px;
    // height: 754px;

    max-width: 345px;
    width: 99%;
    height: 754px;
  }

  ${breakpoints.tablet} {
    // flex-direction: row;
    flex-direction: row-reverse;

    .left-image {
      display: block;
      height: 100%;
    }
    // width: 750px;
    // height: 563px;

    max-width: 375px;
    width: 47%;
    height: 563px;
  }

  ${breakpoints.laptop} {
    // width: 998px;
    // height: 563px;

    // width: 399px;
    height: 563px;
  }

  ${breakpoints.desktop} {
    // width: 1366px;
    // height: 849px;

    // max-width: 438px;
    max-width: none;
    width: 30%;
    min-height: 749px;
    height: 80%;
  }

  .white-bg {
    width: 100%;
    height: 100%;
    background: linear-gradient(150deg, rgba(255, 255, 255, 0.2) 5%, #000);
    position: absolute;
    // top: 7%;
    // left: 35%;
  }
`;

export const LoginSideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledForm = styled.form`
  width: 100%;
  // gap:40px;
`;

export const LoginLink = styled(Link)`
  font-size: ${textSizes["14"]};
  // color: #bcbec0;
  color: #fff;
  margin: 10px 0;
`;

export const InputContainer = styled.button`
  position: relative;
  width: 100%;
  margin:0 !important;
`;

export const ToggleButton = styled.button`
  position: absolute;
  right: 20px;
  top:-8px;
  cursor: pointer;
  color:black;
`;