import React from "react";

const UploadFileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="54"
      viewBox="0 0 80 54"
      fill="none"
    >
      <g filter="url(#filter0_d_500_278)">
        <path
          d="M63.9993 14H43.9993L43.9993 33.6923C43.9993 34.3451 43.5778 34.9713 42.8277 35.4329C42.0775 35.8945 41.0601 36.1538 39.9993 36.1538C38.9384 36.1538 37.921 35.8945 37.1708 35.4329C36.4207 34.9713 35.9993 34.3451 35.9993 33.6923L35.9993 14H15.9993C12.8176 14.002 9.76724 14.7806 7.5175 16.1651C5.26775 17.5495 4.00244 19.4267 3.99927 21.3846L3.99927 38.6154C4.00244 40.5733 5.26775 42.4505 7.5175 43.8349C9.76724 45.2194 12.8176 45.998 15.9993 46H63.9993C67.1809 45.998 70.2313 45.2194 72.481 43.8349C74.7308 42.4505 75.9961 40.5733 75.9993 38.6154L75.9993 21.3846C75.9961 19.4267 74.7308 17.5495 72.481 16.1651C70.2313 14.7806 67.1809 14.002 63.9993 14Z"
          fill="white"
        />
        <path
          d="M44.4993 14.5L44.4993 33.6923C44.4993 34.5773 43.9299 35.3417 43.0897 35.8587C42.246 36.3779 41.1336 36.6538 39.9993 36.6538C38.8649 36.6538 37.7525 36.3779 36.9088 35.8587C36.0686 35.3417 35.4993 34.5773 35.4993 33.6923L35.4993 14.5L15.9996 14.5L44.4993 14.5ZM44.4993 14.5H63.9991H44.4993ZM7.77954 16.5909C9.93576 15.264 12.8912 14.502 15.9993 14.5H63.9993C67.1073 14.502 70.0628 15.264 72.219 16.5909C74.3788 17.92 75.4965 19.6592 75.4993 21.3854L75.4993 38.6146C75.4965 40.3408 74.3788 42.08 72.219 43.4091C70.0627 44.7361 67.1072 45.4981 63.999 45.5H15.9996C12.8914 45.4981 9.93583 44.7361 7.77954 43.4091C5.61989 42.0801 4.50224 40.341 4.49927 38.615L4.49927 21.385C4.50224 19.659 5.61989 17.9199 7.77954 16.5909Z"
          stroke="url(#paint0_linear_500_278)"
        />
      </g>
      <path
        d="M47.4355 6.37986L41.417 0.567281C41.0408 0.204051 40.5306 0 39.9987 0C39.4667 0 38.9565 0.204051 38.5803 0.567281L32.5618 6.37986C32.1964 6.74528 31.9942 7.2347 31.9987 7.74272C32.0033 8.25073 32.2143 8.73668 32.5862 9.09592C32.9582 9.45515 33.4614 9.65892 33.9874 9.66333C34.5134 9.66775 35.0202 9.47246 35.3985 9.11952L37.9925 6.6143L37.9925 29.0625C37.9925 29.5763 38.2039 30.0692 38.5801 30.4325C38.9563 30.7959 39.4666 31 39.9987 31C40.5307 31 41.041 30.7959 41.4172 30.4325C41.7935 30.0692 42.0048 29.5763 42.0048 29.0625L42.0048 6.6143L44.5988 9.11952C44.9772 9.47246 45.4839 9.66775 46.0099 9.66333C46.5359 9.65892 47.0391 9.45515 47.4111 9.09592C47.783 8.73668 47.994 8.25073 47.9986 7.74272C48.0032 7.2347 47.8009 6.74528 47.4355 6.37986Z"
        fill="url(#paint1_linear_500_278)"
      />
      <defs>
        <filter
          id="filter0_d_500_278"
          x="-0.000732422"
          y="14"
          width="80"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_500_278"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_500_278"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_500_278"
          x1="39.9993"
          y1="14"
          x2="39.9993"
          y2="46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#414141" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_278"
          x1="31.9987"
          y1="0"
          x2="48.2602"
          y2="1.78862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6804" />
          <stop offset="0.492708" stopColor="#E0192A" />
          <stop offset="1" stopColor="#A508A5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UploadFileIcon;
