// assets
// import logo from "@assets/logo.svg";
import logo from "@assets/logo/logo-icon.png";
import logoText from "@assets/logo/logo-text-light.png";
// import logoLight from "@assets/logo/logo-light.png";
import logoLight from "@assets/docx_logo.png";

// styling
import styled from "styled-components/macro";
import { colors, fonts, textSizes, breakpoints } from "@styles/vars";
import theme from "styled-theming";

// components
import { NavLink } from "react-router-dom";
import { useInterfaceContext } from "@contexts/interfaceContext";

// utils
import PropTypes from "prop-types";
import { useEffect } from "react";

const Img = styled.img`
  width: 70px;
  height: 90px;
  scale: 2.5;
  width: 100%;
  height: 100%;
  will-change: transform;
  transition: transform var(--transition);
`;

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  /* padding-right: 40px; */

  .logo-wrapper {
    /* padding: 5px 5px 0 5px; */
    height: 48px;
    width: 48px;
    background-color: white;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 9px;
  }

  .logo-icon {
    object-fit: contain;
  }

  .logo-text {
    width: 150px;
    margin-left: -6px;
    margin-bottom: 4px;
    margin-top: 12px;
    height: 26px;
  }

  &:hover,
  &:focus {
    Img {
      // transform: scale(1.1);
    }
  }
`;

const Text = styled.span`
  font-weight: 700;
  font-family: ${fonts.accent};
  font-size: ${textSizes["24"]};

  .highlight {
    color: ${theme("theme", {
      light: colors.blue,
      dark: "inherit",
    })};
  }

  ${breakpoints.tablet} {
    font-size: ${textSizes["32"]};
  }
`;

const Logo = ({ compact, role }) => {
  const { companyId, setCompanyId, isShowMenu1, setIsShowMenu1 } =
    useInterfaceContext();
  // useEffect(() => {
  // setIsShowMenu1(true);
  // }, [isShowMenu1]);
  return (
    <>
      {role === "superAdmin" ? (
        <Wrapper as={NavLink} to="/superAdmin/" className="logo">
          {/* <div className="logo-wrapper">
        <Img src={logo} alt="Medux" className="logo-icon" />
      </div> */}
          {/* <Img src={logoText} alt="Medux" className="logo-text" /> */}
          <Img src={logoLight} alt="Docxster-logo" />
          {/* {!compact ? (
                <Text>
                    Docxsterr<span className="highlight"></span>
                </Text>
            ) : null} */}
        </Wrapper>
      ) : (
        <Wrapper as={NavLink} to="/dashboard" className="logo">
          {/* <div className="logo-wrapper">
        <Img src={logo} alt="Medux" className="logo-icon" />
      </div> */}
          {/* <Img src={logoText} alt="Medux" className="logo-text" /> */}
          <Img src={logoLight} alt="Docxster-logo" />
          {/* {!compact ? (
                <Text>
                    Docxsterr<span className="highlight"></span>
                </Text>
            ) : null} */}
        </Wrapper>
      )}
    </>
  );
};

Logo.propTypes = {
  compact: PropTypes.bool,
};

export default Logo;
