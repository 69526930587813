import styled from "styled-components/macro";
import {
  colors,
  breakpoints,
  flex,
  textSizes,
  fonts,
  shadow,
} from "@styles/vars";
// components
import Page from "@layout/Page";
import { useGetDashData } from "@hooks/dox/useGetDashData";
// import { data } from './../db/cure';
import { dash } from "@db/dashboard";
import { useAxios } from "@hooks/dox/useAxios";
import { useAuth } from "@contexts/AuthProvider";
import WidgetsLoader from "@components/WidgetsLoader";
import { useInterfaceContext } from "@contexts/interfaceContext";
import DobuleBarChart from "@widgets/Dox/DoubleBarChart";
import welcome from "../assets/pana.svg";
import welcome2 from "../assets/rafiki.svg";
import LineGraph from "@widgets/Dox/LineGraph";
import MultiCircleDonut from "@widgets/Dox/MultiCircleDonut";
import WelcomeBoardSvg from "@fonts/menu_icons/welcomeBoard";

const MainContainer = styled.div`
  ${flex.row}
  gap: 30px;

  ${breakpoints.mobileM} {
    ${flex.col}
    ${flex.center}
  }

  ${breakpoints.laptop} {
    ${flex.row}
    justify-content:space-evenly;
  }
`;

const LeftColumn = styled.div`
  ${flex.col}
  gap: 30px;
  align-items: center;
`;

const RightColumn = styled.div`
  ${flex.col}
  gap: 30px;
`;

const WelcomeBoardWrapper = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

const WelcomeSvgWrapper = styled.div`
  &.welcomeBoard {
    position: absolute;
    left: 720px;
    top: 170px;
    top: 156px;
  }

  ${breakpoints.mobileM} {
    &.welcomeBoard {
      position: absolute;
      left: 190px;
      top: 112px;
    }
  }

  ${breakpoints.tablet} {
    &.welcomeBoard {
      position: absolute;
      left: 440px;
      top: 120px;
    }
  }

  ${breakpoints.laptop} {
    &.welcomeBoard {
      position: absolute;
      left: 390px;
      top: 181px;
    }
  }

  ${breakpoints.laptopL} {
    &.welcomeBoard {
      position: absolute;
      left: 700px;
      top: 172px;
    }
  }
`;

const WelcomeBoard = styled.div`
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 152px;
  background: linear-gradient(
    163.34deg,
    #141426 16.21%,
    rgba(68, 43, 74, 0.99) 76.67%,
    #552e59 95.58%
  );
  border-radius: 24px;
  margin-top: 30px;
  p {
    width: 180px;
    height: 48px;
    padding: 0px 30px;
    // font-family: "Inter";
    /* font-family: "Roboto"; */
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 48px;
    color: #ffffff;
  }

  ${breakpoints.mobileM} {
    width: 415px;
    p {
      font-size: 25px;
    }
  }
  ${breakpoints.tablet} {
    width: 720px;
    p {
      font-size: 35px;
    }
  }
  ${breakpoints.laptop} {
    margin-top: 0px;
    width: 630px;
    p {
      font-size: 40px;
    }
  }
  ${breakpoints.laptopL} {
    width: 750px;
  }
`;

const GraphWrapper = styled.div`
  display: flex;
  border-radius: 40px;
`;

const LineGraphWrapper = styled(GraphWrapper)`
  ${breakpoints.mobileM} {
    width: 410px;
    height: 250px;
  }

  ${breakpoints.tablet} {
    width: 720px;
    height: 300px;
  }
  ${breakpoints.laptop} {
    width: 630px;
  }

  ${breakpoints.laptopL} {
    width: 750px;
  }
`;

const BarGraphWrapper = styled(GraphWrapper)`
  ${breakpoints.mobileM} {
    width: 410px;
    height: 250px;
  }
  ${breakpoints.tablet} {
    width: 720px;
    height: 300px;
  }

  ${breakpoints.laptop} {
    width: 630px;
  }

  ${breakpoints.laptopL} {
    width: 750px;
  }
`;

const TotalUsage = styled.div`
  display: flex;
  width: 300px;
  border-radius: 24px;
  background: #ffffff;
`;

const PlansWrapper = styled.div`
  ${flex.col}
  width: 300px;
  gap: 30px;
  border-radius: 24px;
`;

const Plan = styled.button`
  width: 300px;
  height: 92px;
  border-radius: 24px;
  /* font-family: Roboto; */
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;

  &:nth-child(1) {
    background: linear-gradient(94.24deg, #f39034 3.89%, #ff2f28 95.53%);
  }
  &:nth-child(2) {
    background: linear-gradient(95.37deg, #0194eb 0%, #0141d4 100%);
  }
  &:nth-child(3) {
    background: linear-gradient(93.83deg, #964fef 0.68%, #6008cd 98.87%);
  }
`;

const ImgWrapper = styled.div`
  .welcomeImg2 {
    position: sticky;
    width: 330px;
    height: 350px;
    right: 65px;
    bottom: -330px;
  }
`;

const SuperAdminDashboard = () => {
  const auth = useAuth();
  // const { companyId, setCompanyId } = useInterfaceContext();
  // const [activeUsers, setActiveUsers] = useState("");
  // const [paidUsers, setPaidUsers] = useState("");

  // fetch super admin dashboard data
  const { response, loading, error } = useAxios({
    method: "get",
    url: "/admin/fetchSuperAdminDashboard",
    headers: {
      "x-access-token": auth.user.accessToken,
    },
    data: {},
  });

  return (
    <>
      {loading ? (
        <WidgetsLoader />
      ) : error ? (
        <h4 style={{ display: "block", textAlign: "center" }}>{error}</h4>
      ) : (
        <Page title="Superadmin Dashboard">
          <MainContainer>
            <LeftColumn>
              <WelcomeBoardWrapper className="welcomeBox">
                <WelcomeBoard>
                  <p>Welcome</p>
                </WelcomeBoard>
                <WelcomeSvgWrapper className="welcomeBoard">
                  <WelcomeBoardSvg />
                </WelcomeSvgWrapper>
              </WelcomeBoardWrapper>
              <LineGraphWrapper>
                <LineGraph
                  value={response && response.monthlyPaidCompaniesByPlan}
                />
              </LineGraphWrapper>
              <BarGraphWrapper>
                <DobuleBarChart
                  value={response && response.monthlyCompanyAdditions}
                />
              </BarGraphWrapper>
            </LeftColumn>
            <RightColumn>
              <TotalUsage style={{ height: "280px" }}>
                <MultiCircleDonut value={response.paidAccountCompanies} />
              </TotalUsage>
              <PlansWrapper style={{ height: "320px" }}>
                <Plan>Plan A</Plan>
                <Plan>Plan B</Plan>
                <Plan>Plan C</Plan>
              </PlansWrapper>
              <ImgWrapper>
                <img className="welcomeImg2" src={welcome2} alt="img2" />
              </ImgWrapper>
            </RightColumn>
          </MainContainer>
        </Page>
      )}
    </>
  );
};

export default SuperAdminDashboard;
