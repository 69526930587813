// styled components
import { Menu, UserWrapper } from "../panelStyle";

// components
// import Avatar from "@ui/Avatar";
import Avatar from "@mui/material/Avatar";

// utils
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useState } from "react";

// assets
import userPic from "@assets/avatars/no-profile.jpg";
import { useAuth } from "@contexts/AuthProvider";

const CurrentUser = () => {
  const [open, setOpen] = useState(false);
  const handleClickAway = () => setOpen(false);
  const handleClick = () => setOpen(!open);
  const auth = useAuth();
  const src = auth.userInfo.profilePicData
    ? `data:image/png;base64,${auth.userInfo.profilePicData}`
    : userPic;
  const logout = () => auth.logOut();
  const name = auth.userInfo.userData?.name.split(" ")[0];

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <UserWrapper className="user-info" onClick={handleClick}>
        <Avatar
          src={src}
          alt="avatar"
          className="user_profile"
          // sx={{ width: 50, height: 50 }}
        />
        <div className="info" style={{ margin: "0px" }}>
          <span className="h2" style={{ fontSize: "24px", color: "#fff" }}>
            {/* {auth.userInfo.userData?.name} */}
            {auth.userInfo.userData && name.length > 6
              ? `${name.slice(0, 6)}...`
              : name}
          </span>
          {/* <span className="position">
            {auth.userInfo.userData?.designation}
          </span> */}
          <Menu className={open ? "visible" : ""}>
            <button onClick={logout}>
              <i className="icon icon-logout" /> Logout
            </button>
          </Menu>
        </div>
        <button
          className="trigger"
          onClick={handleClick}
          aria-label="Show menu"
        >
          <i className="icon icon-chevron-down" />
        </button>
      </UserWrapper>
    </ClickAwayListener>
  );
};

export default CurrentUser;
