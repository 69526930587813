import { useEffect } from "react";
import { useAuth } from "@contexts/AuthProvider";
import { useNavigate } from "react-router-dom";

const CheckRole = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const roleNavigate = (role) => {
    if (role === "superAdmin") {
      navigate("/superAdmin/account", { replace: true });
    } else if (role === "admin") {
      navigate("/dashboard/home", { replace: true });
    }else if (role === "user") {
      navigate("/dashboard/home", { replace: true });
    } else {
      navigate("/auth/login", { replace: true });
    }
  };

  useEffect(() => {
    auth.setAuthError("");
    if (auth.user) {
      roleNavigate(auth.user.role);
    } else {
      navigate("/auth/login", { replace: true });
    }
  }, [auth.user]);
//   return <></>;
};
export default CheckRole;
