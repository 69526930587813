import { useLocation, useNavigate } from "react-router-dom";

// import InvoiceDetails from "@components/Dox/ViewDetails/DocumentDetails";
// import PanDetails from "@components/Dox/ViewDetails/PanDetails";
// import AdhaarDetails from "@components/Dox/ViewDetails/AdhaarDetails";
// import PODetails from "@components/Dox/ViewDetails/PODetails";
// import BOLDetails from "@components/Dox/ViewDetails/BOLDetails";
// import DrivingLicenseDetails from "@components/Dox/ViewDetails/DrivingLicenseDetails";
// import PassportDetails from "@components/Dox/ViewDetails/PassportDetails";
import InvoiceDetailsNew from "@components/Dox/ViewDetails/DocumentDetails/indexNew";

const ViewFile = () => {
  // eslint-disable-next-line
  const { state } = useLocation();
  const { payload } = state;
// console.log(state);  
  const type = payload.documentSubType;
  return (
    <>
      {/* {type && type === "Invoice" ? (
        // <InvoiceDetails state={state} />
        <InvoiceDetailsNew state={state} />
      ) : type === "BillOfLading" ? (
        <InvoiceDetailsNew state={state} />
      ) : type === "PurchaseOrder" ? (
        <InvoiceDetailsNew state={state} />
      ) : type === "DrivingLicense" ? (
        <InvoiceDetailsNew state={state} />
      ) : type === "Passport" ? (
        <InvoiceDetailsNew state={state} />
      )  : type === "BankStatement" ? (
        <InvoiceDetailsNew state={state} />
      ): type === "KycPan" ? (
        <PanDetails state={state} />
      ) : type === "KycAdhar" ? (
        <AdhaarDetails state={state} />
      ) : (
        <h4>Invalid Document Type</h4>
      )} */}
      <InvoiceDetailsNew state={state} />
    </>
  );
};

export default ViewFile;
