// components
import { Badge } from "@ui/Badge/style";

// styling
import styled from "styled-components/macro";
import theme from "styled-theming";
import { dark, light, flex, colors, breakpoints } from "@styles/vars";

// utils
import PropTypes from "prop-types";

const bg = theme("theme", {
  light: light.bodyBg,
  dark: dark.highlight,
});

export const Button = styled.button`
  /* width: 40px; */
  width: 60px;
  width: 60px;
  aspect-ratio: 1;
  /* background-color: ${colors.white}; */
  background: var(
    --bg,
    linear-gradient(122deg, #141426 4.81%, #3c2945 100.76%)
  );
  border: 1px solid #fff;
  /* background-color: cyan; */
  border-radius: 50%;
  /* color: ${colors.gray}; */
  color: #fff;
  ${flex.col}
  ${flex.center}
  position: relative;
  box-shadow: 0 0 4px 1px #0000001f;
  // transition: color var(--transition), background-color var(--transition);

  &:hover {
    background-color: ${colors.sidebar_bg};
    color: #fff;
  }

  .icon-bell {
    scale: 1.5;
  }

  .badge {
    position: absolute;
  }
  &.active {
    background-color: ${colors.sidebar_bg} !important;
    color: #fff !important;
  }
  &.squares {
    border-radius: 8px;
    border-radius: 50%;
    .badge {
      top: -6px;
      right: -6px;
    }

    // &:focus {
    //   background-color: ${colors.sidebar_bg};
    // }
  }

  &.round {
    border-radius: 50%;
    border: 1px solid #fff;
    .badge {
      top: 0;
      right: 0;
    }
  }

  ${breakpoints.mobileM} {
    width: 45px;
    width: 45px;
  }

  ${breakpoints.laptopL} {
    width: 60px;
    width: 60px;
  }
`;

const ShapeButton = ({
  hasNotification,
  icon,
  handler,
  label,
  shape,
  ...props
}) => {
  return (
    <Button
      className={shape}
      onClick={handler}
      aria-label={label}
      ref={props.ref}
      {...props}
    >
      <i className={`icon-${icon}`}></i>
      {hasNotification && <Badge className="badge" color="#d20f07" />}
    </Button>
  );
};

ShapeButton.propTypes = {
  hasNotification: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  handler: PropTypes.func,
  label: PropTypes.string.isRequired,
  shape: PropTypes.oneOf(["round", "squares ", "squares active"]).isRequired,
};

export default ShapeButton;
