import { useState } from "react";

const Editable = ({
  text,
  name,
  className,
  handler,
  editable = true,
  children,
  style,
  id,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const dispText = text ? text : "NA";

  const handleKey = (e) => {
    if (e.key === "Enter") {
      setIsEdit(false);
      handler(e);
    }
  };

  const onBlurHandle = (e) => {
    handler(e);
    setIsEdit(false);
  };
  const editHandler = () => {
    setIsEdit(true);
  };

  return (
    <>
      {isEdit ? (
        <input
          type="text"
          defaultValue={dispText}
          name={name}
          id={id}
          onKeyDown={handleKey}
          onBlur={onBlurHandle}
          // onBlur={() => setIsEdit(false)}
          autoFocus
        />
      ) : (
        <span className="info" onClick={editable && editHandler} style={style}>
          {dispText}
        </span>
      )}
    </>
  );
};

export default Editable;
