import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ChatContainer = styled.div``;

export const ChatWindow = () => {
  const [email, setEmail] = useState("");
  const iframeRef = useRef(null);
  const navigate = useNavigate();

  const handleIframeMessage = (event) => {
    if(event.origin === "https://chatbot.docxster.ai") {
    //if (event.origin === "http://localhost:3001") {
      const { url } = event.data;
      navigate(url);
    }
  };

  useEffect(() => {
    const tokens = JSON.parse(localStorage.tokens || "{}");
    const userEmail = tokens.email || "";
    setEmail(userEmail);

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [handleIframeMessage]); // Include the function as a dependency

  return (
    <ChatContainer>
      <iframe
        src={`https://chatbot.docxster.ai/?email=${email}`}
        //src={`http://localhost:3001/?email=${email}`}
        height="610"
        width="400"
        frameBorder="0"
        title="chatbot"
        referrerPolicy="origin"
        ref={iframeRef}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      ></iframe>
    </ChatContainer>
  );
};
