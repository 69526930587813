import React, { useState, useEffect } from "react";
import { colors, flex, textSizes } from "@styles/vars";
import { Container, EmailPopUp, ImportWrapper, ProgressWrapper } from "./style";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { useAuth } from "@contexts/AuthProvider";
import Progress from "@ui/Progress";
import styled from "styled-components/macro";
import driveIcon from "@assets/drive_icon.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import instance from "@utils/instance";
import { nanoid } from "nanoid";
import DoxBtn from "@ui/Dox/DoxBtn";
import { toast } from "react-toastify";

const SCOPES = "https://www.googleapis.com/auth/drive.readonly";

const DriveContainer = styled(Container)`
  width: 310px;
  height: 400px;
  justify-content: start;
  gap: 20px;

  .driveIcon {
    width: 50px;
    height: 50px;
  }

  .connectWrapper {
    // align-self: flex-start;
  }

  .signOutWrapper {
    // padding-left: 15px;
  }

  .authBtn {
    padding: 4px;
    border: 2px solid #ede2e2;
    background: blue;
    color: white;
    border-radius: 8px;
  }
  .signOutBtn {
    padding: 4px;
    border: 2px solid #ede2e2;
    background: blue;
    color: white;
    border-radius: 8px;
  }

  p {
    margin: 5px 0;
    font-size: ${textSizes["16"]};
  }
`;

const GoogleDriveImport = ({ mainPopUpHandler }) => {
  const apiKey = "AIzaSyBgvQYtcCcwJgLNYK2DEOuXs5cEuig39LQ";
  const clientId =
    "831650322031-k6k4uvpsf308h0sd3g3ed33hpgjhd6j0.apps.googleusercontent.com";
  const [tokenClient, setTokenClient] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [pickerInited, setPickerInited] = useState(false);
  const [gisInited, setGisInited] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [error, setError] = useState("");
  // const [uploadCount, setUploadCount] = useState(0);
  // const [isUploading, setIsUploading] = useState(false);

  const play = () => {
    // new Audio("/src/assets/answer-tone-2870.mp3").play();
    new Audio(
      "https://phoneky.co.uk/content/mp3tones/tone/2020/classic/1secondton_87a501f05076308.mp3"
    ).play();
  };

  const auth = useAuth();
  const {
    setIsUploadPopUpShow,
    notification,
    setNotification,
    documentType,
    showDrivePopup,
    setShowDrivePopup,
    isUploading,
    setIsUploading,
    uploadCount,
    setUploadCount,
    currentWorkflowId,
  } = useInterfaceContext();

  window.gapiLoaded = () => {
    window.gapi.load("client:picker", initializePicker);
  };
  window.gisLoaded = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: SCOPES,
      callback: "",
    });

    setTokenClient(client);
    setGisInited(true);
  };
  useEffect(() => {
    window.gapiLoaded();
    window.gisLoaded();
  }, [clientId]);

  const initializePicker = async () => {
    await window.gapi.client.load(
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
    );
    setPickerInited(true);
  };
  const handleAuthClick = () => {
    if (!tokenClient) return;
    tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw response;
      }
      setAccessToken(response.access_token);
    };
    if (!accessToken) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      createPicker();
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };
  const handleSignoutClick = () => {
    if (accessToken) {
      window.google.accounts.oauth2.revoke(accessToken);
      setAccessToken(null);
    }
  };
  const createPicker = () => {
    if (!accessToken) return;
    // const view = new window.google.picker.View(
    //   window.google.picker.ViewId.DOCS
    // );
    const view = new window.google.picker.View(
      window.google.picker.ViewId.FOLDERS
    );
    view.setMimeTypes("image/png,image/jpeg,image/jpg");
    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(apiKey)
      // .setAppId(appId)
      .setOAuthToken(accessToken)
      .addView(view)
      // .addView(new window.google.picker.DocsUploadView())
      // .addView(new window.google.picker.ViewId.FOLDERS)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);

    const myDiv = document.getElementById("pickerDiv");
    if (myDiv) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", picker);
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.classList.add("picker");
      myDiv.appendChild(iframe);
    }
  };

  const pickerCallback = async (data) => {
    if (data.action === "cancel") {
      setShowDrivePopup(false);
      mainPopUpHandler();
    } else if (data.action === "loaded") {
      setShowDrivePopup(false);
      mainPopUpHandler();
    } else if (data.action === "picked") {
      setShowDrivePopup(true);
      setIsUploadPopUpShow(false);
      setIsUploading(true);
    }

    if (data.action === window.google.picker.Action.PICKED) {
      const document = data[window.google.picker.Response.DOCUMENTS][0];
      const fileId = document[window.google.picker.Document.ID];
      const res = await window.gapi.client.drive.files.get({
        fileId: fileId,
        fields: "*",
      });

      let files = data.docs;
      let count = 0;
      let notify = [];
      setError("");

      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": auth.user.accessToken,
        },
      };

      // Number of parallel uploads allowed
      const maxParallelUploads = 10;

      const uploadPromises = [];
      try {
        for (let i = 0; i < files.length; i++) {
          if (uploadPromises.length >= maxParallelUploads) {
            // Wait for any one of the current uploads to complete before adding more to the queue.
            await Promise.race(uploadPromises);
          }

          const file = files[i];
          const name = file.name;
          const id = file.id;

          let data = {
            workflowId: currentWorkflowId,
            driveFileId: id,
            driveFileName: name,
            driveAccessToken: accessToken,
          };

          const uploadPromise = instance
            .post("v1/google-drive/process-drive-file", data, config)
            .then((res) => {
              if (res.status === 200) {
                count += 1;
                setUploadCount(Math.round((count / files.length) * 100));
                notify.push({
                  id: nanoid(),
                  type: documentType,
                  msg: `${name} is ready for review.`,
                  tab: "In-Review",
                });
              }
              if (Math.round((count / files.length) * 100) === 100) {
                play();
              }
            })
            .catch((e) => {
              if (e.response.status === 500) {
                // setError("Something went wrong. Try again.");
                toast.error("Something went wrong. Try again.", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            });

          uploadPromises.push(uploadPromise);

          // Remove completed promises to maintain the parallel limit
          uploadPromise.finally(() => {
            const index = uploadPromises.indexOf(uploadPromise);
            if (index !== -1) {
              uploadPromises.splice(index, 1);
            }
          });
        }

        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
      } catch (e) {
        if (e.response.status === 500) {
          // setError("Something went wrong. Try again.");
          toast.error("Something went wrong. Try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } finally {
        if (notify.length > 0) {
          const length = notify.length > 5 ? 0 : 5;
          const oldNotify = !notification.isMarkAsRead
            ? notification.notifications
            : notification.notifications.slice(0, length);
          const notifications = [...notify, ...oldNotify];
          setNotification({
            isMarkAsRead: false,
            notifications: notifications,
          });
        }

        setTimeout(() => {
          setIsUploading(false);
          setShowDrivePopup(false);
          setUploadCount(0);
          mainPopUpHandler();
        }, 1000);
      }
    }
  };
  return (
    <>
      {showDrivePopup && (
        <EmailPopUp
        // style={{ display: showDrivePopup ? "flex" : "none" }}
        >
          <DriveContainer>
            <span className="title">Import via Google Drive</span>
            <span
              className="close-icon"
              onClick={() => setShowDrivePopup(false)}
            >
              <i className={`icon icon-close`}></i>
            </span>
            <img src={driveIcon} alt="drive" className="driveIcon" />
            <div className="connectWrapper">
              <p>Connect to your google drive account</p>
              <DoxBtn
                text={accessToken ? "Import" : "Authorize"}
                icon=""
                className={!isUploading ? "" : "disable"}
                handler={handleAuthClick}
                style={{
                  fontSize: "14px",
                  height: "35px",
                  margin: "0px 10px",
                }}
              />
            </div>
            <div className="signOutWrapper">
              <p>Wants to connect with different account or sign out</p>
              <DoxBtn
                text="Sign Out"
                icon=""
                className={accessToken && !isUploading ? "" : "disable"}
                handler={handleSignoutClick}
                style={{
                  fontSize: "14px",
                  height: "35px",
                  margin: "0px 10px",
                }}
              />
            </div>
            {/* {isUploading && !error && (
              <ProgressWrapper>
                <Progress
                  value={uploadCount}
                  color={colors.sidebar_bg}
                  style={{ width: "50%" }}
                />
                {uploadCount}
              </ProgressWrapper>
            )} */}
          </DriveContainer>
        </EmailPopUp>
      )}
      <ImportWrapper onClick={() => setShowDrivePopup(true)}>
        <div className="left">
          <img src={driveIcon} alt="drive" className="icon" />
          <label>Google Drive</label>
        </div>
        <ArrowForwardIcon className="arrow" />
      </ImportWrapper>
    </>
  );
};
export default GoogleDriveImport;
