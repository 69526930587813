import LogIn from "@widgets/Dox/Login";
import { Helmet } from 'react-helmet';

const Login = () => {
    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <title>Login to Docxster</title>
                <meta content="One click away from document efficiency and automation" name="description" />
                <meta content="Login to Docxster" property="og:title" />
                <meta content="One click away from document efficiency and automation" property="og:description" />
                <meta content="https://uploads-ssl.webflow.com/6527d5e25fcb40892475493c/65fca16cda93150948688c57_Docxster%20Log%20In.png" property="og:image" />
                <meta content="Login to Docxster" property="twitter:title" />
                <meta content="One click away from document efficiency and automation" property="twitter:description" />
                <meta content="https://uploads-ssl.webflow.com/6527d5e25fcb40892475493c/65fca16cda93150948688c57_Docxster%20Log%20In.png" property="twitter:image" />
                <meta property="og:type" content="website" /><meta content="summary_large_image" name="twitter:card" /><meta content="width=device-width, initial-scale=1" name="viewport" /><meta content="Lu4kXBNETjFrY50mTESF268m8i5NLag5DTe8SUEplYM" name="google-site-verification" />
            </Helmet>
            <LogIn />
        </div>
    );
};

export default Login;
