import axios from "axios";
import api from "api";

// Use the imported `api` as the Axios instance
const instance = api;

instance.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  config.headers["x-access-token"] = tokensData.accessToken;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("e", error);
    if (error.response && error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        refreshToken: authData.refreshToken,
      };

      try {
        let apiResponse = await api.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refreshtoken`,
          payload
        );

        const newTokens = {
          ...apiResponse.data,
          id: authData.id,
          email: authData.email,
          role: authData.role
        };
        localStorage.setItem("tokens", JSON.stringify(newTokens));

        // Update the original request with the new access token
        error.config.headers["x-access-token"] = newTokens.accessToken;

        // Retry the original request with the new token
        return api(error.config);
      } catch (refreshError) {
        // If the refresh token request fails, reject the original error
        return Promise.reject(refreshError);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
