import { useState, useEffect } from "react";
import instance from "@utils/instance";
import { useAuth } from "@contexts/AuthProvider";
/**
 fixed :
  - no need to JSON.stringify to then immediatly do a JSON.parse
  - don't use export defaults, because default imports are hard to search for
  - axios already support generic request in one parameter, no need to call specialized ones
**/
export const useAxios = (axiosParams) => {
  const [response, setResponse] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);
  const auth = useAuth();

  const controller = new AbortController();

  const fetchData = async (params) => {
    // console.log(params)
    setError("");
    try {
      const result = await instance.request(params, {
        signal: controller.signal,
      });
      setResponse(result.data);
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setError("Network Connection Error.");
      } else if (error.response.status === 403) {
        console.log("useAxios", error);
        auth.logOut();
      } else {
        setError("Something went wrong, Try again.");
        console.log("useAxios", error);
      }
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    fetchData(axiosParams);

    return () => controller.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // execute once only

  const refetch = () => {
    fetchData(axiosParams);
  };
  return { response, error, loading, refetch };
};
