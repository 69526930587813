import React from "react";

const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        d="M3.27734 8.16699L12.8031 14.835C13.5744 15.375 13.9601 15.6449 14.3797 15.7495C14.7502 15.8419 15.1378 15.8419 15.5084 15.7495C15.9279 15.6449 16.3136 15.375 17.0849 14.835L26.6107 8.16699M8.87734 23.3337H21.0107C22.9709 23.3337 23.951 23.3337 24.6996 22.9522C25.3582 22.6166 25.8936 22.0812 26.2292 21.4226C26.6107 20.6739 26.6107 19.6938 26.6107 17.7337L26.6107 10.267C26.6107 8.30681 26.6107 7.32672 26.2292 6.57803C25.8936 5.91946 25.3582 5.38403 24.6996 5.04847C23.951 4.66699 22.9709 4.66699 21.0107 4.66699H8.87734C6.91716 4.66699 5.93707 4.66699 5.18838 5.04847C4.52981 5.38403 3.99438 5.91946 3.65882 6.57803C3.27734 7.32672 3.27734 8.30681 3.27734 10.267L3.27734 17.7337C3.27734 19.6938 3.27734 20.6739 3.65882 21.4226C3.99438 22.0812 4.52981 22.6166 5.18838 22.9522C5.93707 23.3337 6.91716 23.3337 8.87734 23.3337Z"
        stroke="#E24949"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
