import styled from "styled-components/macro";
import { flex, colors, textSizes } from "@styles/vars";
import { nanoid } from "nanoid";

export const Container = styled.div`
  ${flex.col};
  ${flex.center};
  gap: 18px;
  margin: 18px 0;

  .file-count {
    color: ${colors.gray};
    font-size: 17px;
  }
`;

export const ShowFilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 135px;
  min-width:300px;
  overflow: scroll;
  ${flex.center};
  // justify-content: space-between;

  .file {
    ${flex.col};
    ${flex.center};
    gap: 5px;
    margin: 10px 5px;
    width: 80px;
    // height: 80px;
    overflow: hidden;

    .icon {
      color: ${colors.sidebar_bg};
      font-size: 20px;
    }
    .text {
      color: ${colors.gray};
      font-size: ${textSizes[14]};
      text-align: center;
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const ShowFiles = ({ files }) => {
  return (
    <Container>
      <p className="file-count">{files.length} files selected.</p>
      <ShowFilesWrapper>
        {files.map((file) => (
          <div className="file" key={nanoid()}>
            <i className={`icon icon-file-text`}></i>
            <span className="text">{file.name}</span>
          </div>
        ))}
      </ShowFilesWrapper>
    </Container>
  );
};

export default ShowFiles;
