// styling
import styled from "styled-components/macro";
import { colors, dark, light, textSizes } from "@styles/vars";
import theme from "styled-theming";

// utils
import PropTypes from "prop-types";

export const Input = styled.input`
  height: 50px;
  padding: 0px 16px;
  // border-radius: 8px;
  // border: 1px solid transparent;
  border-radius: 40px;
  // border: 1px solid #bcbec0;
  border: 1px solid red;
  background: #fff;
  color: black;
  font-size: ${textSizes["14"]};
  ${theme("theme", {
    light: `
    background-color: ${light.highlight};
   `,
    dark: `
    background-color: ${dark.highlight};
   `,
  })};
  background: #fff !important;
  transition: border-color var(--transition), box-shadow var(--transition);

  &.error {
    border-color: ${colors.error};
  }

  &:hover {
    box-shadow: ${theme("theme", {
      light: `0 0 0 2px #A2C0D4`,
      dark: `0 0 0 2px ${colors.dark}`,
    })};
  }

  &:focus {
    // box-shadow: 0 0 0 2px ${colors.blue};
    box-shadow: 0 0 0 2px #fff;
  }

  &:placeholder{
    color: "#909AA4",
  }

`;

const LoginField = ({
  type = "text",
  placeholder,
  value,
  handler,
  id,
  className,
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handler}
      id={id}
      className={className || ""}
    />
  );
};

LoginField.propTypes = {
  type: PropTypes.oneOf(["text", "password", "email", "number", "search"]),
  placeholder: PropTypes.string,
};

export default LoginField;
