// styled components
import {
  Container,
  FlexContainer,
  Content,
  LoginSideImage,
  StyledForm,
  LoginLink,
  LoginContainer,
} from "../style";

// components
import { useState, useEffect } from "react";
import axios from "axios";
import Btn from "@ui/Btn";
import Message from "@ui/Message";
import LoginFormInput from "@ui/LoginFormInput";
import ForgetPassImg from "@assets/forget_pass.jpg";
import { useAuth } from "@contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import { isEmail } from "@utils/validator";
import api from "api";
import logoLight from "@assets/logo/logo-light.png";
import Loader from "@ui/Dox/Loader";
import SignUpFormInput from "@ui/LoginFormInput/signup";
import SignUpBtn from "@ui/Btn/signupBtn";
import LoginBtn from "@ui/Btn/loginBtn";
import toastAlert from "@widgets/Dox/CustomToastAlert";
// import { Success } from './../style';

const ForgetPassword = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const changeHandler = (e) => {
    setError("");
    setSuccess("");
    setEmail(e.target.value);
    // console.log(e.target.value);
  };

  const forgetHandler = async () => {
    if (!isEmail(email)) {
      toastAlert("Enter a valid email", "error");
      return;
    }
    const payload = { email: email };
    try {
      setIsLoading(true);
      // const res = await api.post("/auth/forgetPassword", payload);
      const res = await axios.post(`${baseURL}/auth/forgetPassword`, payload);
      toastAlert(res.data.msg, "success");
      setEmail("")
    } catch (e) {
      if (e.response) {
        if (e.response.status === 404) {
          // setError("User does not exist.");
          toastAlert("User does not exist", "error");
        }
      } else if (e.request) {
        console.log(e.request);
      } else {
        console.log(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const roleNavigate = (role) => {
    navigate("/dashboard", { replace: true });
  };

  useEffect(() => {
    auth.setAuthError("");
    if (auth.user) {
      roleNavigate(auth.user.role);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <Container>
      <Content>
        {/* <FlexContainer className="left-image">
          <LoginSideImage src={ForgetPassImg} alt="image" />
        </FlexContainer> */}
        <div className="white-bg"></div>
        <FlexContainer>
          <LoginContainer>
          <img
              src={logoLight}
              alt="Docxster-logo"
              className="docxster_logo"
              style={{marginTop:'-60px',marginLeft:'10px'}}
            />
            <h1>Forgot Password</h1>
            <Message className="error">{error ? error : ""}</Message>
            {success && (
              <div style={{ margin: "10px 0" }}>
                {" "}
                <Message className="success">{success}</Message>{" "}
              </div>
            )}

            <StyledForm
              action="#"
              method="post"
              onSubmit={(e) => e.preventDefault()}
            >
              <LoginFormInput
                id="email"
                title="Email"
                type="email"
                value={email}
                placeholder="Enter email address"
                handler={changeHandler}
              />
              <LoginBtn
                text={!isLoading ? "Continue" : <Loader />}
                handler={forgetHandler}
                type="submit"
              />
            </StyledForm>
          </LoginContainer>
          <LoginLink to="/auth/login">Login?</LoginLink>
        </FlexContainer>
      </Content>
    </Container>
  );
};

export default ForgetPassword;
