// styling
import styled from "styled-components/macro";
import { breakpoints, colors, flex } from "@styles/vars";
import { CardActionArea } from "@mui/material";

// components
import Page from "@layout/Page";
import { Container } from "@components/Widget/style";
import Lottie from "lottie-react";
import Btn from "@ui/Btn";
import { motion } from "framer-motion";
import WidgetsLoader from "@components/WidgetsLoader";
import instance from "@utils/instance";

// hooks
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "@contexts/AuthProvider";
import { useEffect, useState } from "react";
import { useInterfaceContext } from "@contexts/interfaceContext";

const FlexContainer = styled(Container)`
  ${flex.row};
  justify-content: left;
  // justify-content: start;
  padding: 24px;
  flex-wrap: wrap;


  ${breakpoints.mobileM} {
    flex-grow: initial;
  }

  ${breakpoints.tablet} {
    flex-grow: inherit;
  }
`;

// const BoxWrapper = styled.div`
//   ${flex.row};
//   justify-content: space-between;
//   background-color: yellow;
// `;

const Box = styled.div`
  height: 200px;
  line-height: 200px;
  width: 250px;
  margin: 10px 20px;
  text-align: center;
  cursor: pointer;
  // box-shadow: 1px 1px 5px 4px rgb(139 138 139 / 37%) !important;
  box-shadow: 0 1px 8px rgb(20 46 110 / 10%);
  border-radius: 18px;
  background: linear-gradient(
    125.49deg,
    #bea9d0 1.93%,
    #d9cae4 43.61%,
    #fef7ff 96.53%
  );
  color: #3d1242e0;
  font-weight: 500;

  &:hover {
    color: #777;
    transform: scale(0.95);
  }

  &.selected {
    color: #777;
    background: linear-gradient(
      125.49deg,
      #aaa2b1 97.93%,
      #d9cae4 43.61%,
      #fef7ff 96.53%
    );
  }

  ${breakpoints.mobileM} {
    font-size: 0.7rem;
    height: 100px;
    line-height: 100px;
    width: 120px;
    margin: 10px;
    border-radius: 10px;
  }

  ${breakpoints.tablet} {
    font-size: 0.8rem;
    height: 150px;
    line-height: 150px;
    width: 180px;
    margin: 10px 20px;
    border-radius: 15px;
  }

  ${breakpoints.laptop} {
    font-size: 1rem;
    height: 200px;
    line-height: 200px;
    width: 250px;
    margin: 10px 20px;
    border-radius: 18px;
  }
`;

// const Error = styled.p`
//   text-align: center;
//   background: linear-gradient(
//     125.49deg,
//     #bea9d0 1.93%,
//     #d9cae4 43.61%,
//     #fef7ff 96.53%
//   );
//   width: 100%;
//   height: 30px;
//   line-height: 30px;
//   color: #da7979;
//   font-weight: 500;
//   border-radius: 5px;
// `;

const Account = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const location = useLocation();
  const documentType = location.pathname.split("/").splice(-1)[0];
  const [companies, setCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // const [searchParams, setSearchParams] = useSearchParams(null);
  const {
    companyId,
    setCompanyId,
    companyName,
    setCompanyName,
    isShowMenu1,
    setIsShowMenu1,
  } = useInterfaceContext();
  const [isSelected, setIsSelected] = useState(false);

  const getAccountsData = async (body) => {
    if (!auth.isSuperAdmin()) {
      return;
    }
    // call API
    try {
      setIsLoading(true);
      const resp = await instance.post(
        "/admin/listPaidCompanies",
        { documentType: documentType, ...body },
        {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        }
      );
      setCompanies(resp.data.companies);
      setAllCompanies(resp.data.companies.length);
      // console.log(resp.data.companies[0].name);
    } catch (e) {
      // implememnt error handling
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(companyId);
  useEffect(() => {
    getAccountsData();
    // setCompanyId("");
  }, []);

  // const handleClick = (e, id, name) => {
  //   localStorage.setItem("companyName", name);
  //   // localStorage.setItem("companyId", id);
  //   // console.log(id);
  //   // console.log("companyId", localStorage.getItem("companyId"));
  //   setCompanyId(id);
  //   setCompanyName(name);
  //   // setCompanyId(localStorage.getItem("companyId"));
  //   setIsSelected(true);
  //   navigate("/superAdmin/user_dashboard");
  //   // console.log(name)
  // };

  //copy handle click
  const handleClick = (e, id, name) => {
    navigate("/superAdmin/userDashboard");
    localStorage.setItem("companyName", name);
    localStorage.setItem("companyId", id);

    setCompanyId(id);
    setCompanyName(name);
    // setCompanyId(localStorage.getItem("companyId"));
    setIsSelected(true);
    setIsShowMenu1(false);
    // console.log("account", isShowMenu1);
  };
// console.log(response)
  return (
    <Page title="Accounts">
      {!isLoading ? (
        <FlexContainer
          as={motion.div}
          i
          nitial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          viewport={{ once: true }}
        >
          {/* {allCompanies > 0 ? ( */}
          <>
            {companies.map((val) => {
              // console.log(val._id);
              return (
                <Box
                  className={
                    companyId === val._id && isSelected ? "selected" : ""
                  }
                  key={val._id}
                  onClick={(e) => handleClick(e, val._id, val.name)}
                >
                  {val.name}
                </Box>
              );
            })}
          </>
          {/* ) : (
          <Error>No company found</Error>
        )} */}
        </FlexContainer>
      ) : (
        <WidgetsLoader />
      )}
    </Page>
  );
};

export default Account;
