import React from "react";
import { ReactComponent as ReactIcon } from "@assets/download.svg";
const DownloadIcon = ({ handler, className = "" }) => {
  return (
    <span className={className} onClick={handler} style={{ cursor: "pointer" }}>
      <ReactIcon style={{ height: "20px" }} />
    </span>
  );
};

export default DownloadIcon;
