import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import api from "api";
import AddIcon from "@mui/icons-material/Add";
import QBCheckLogo from "@assets/qb_check_logo.png";
import QBErrorLogo from "@assets/qb_error_logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomDropdownWrapper, Dropdown } from "./style";
import { useAuth } from "@contexts/AuthProvider";
import { useInterfaceContext } from "@contexts/interfaceContext";
import { Tooltip } from "@mui/material";

const CustomSearchDropdown = ({docType, showQB, buyerName, handler }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [result, setResult] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const auth = useAuth();
  const dropdownRef = useRef(null);

  const { setQBCustomer } = useInterfaceContext();

  const findCustomer = () => {
    if (buyerName) {
      const regexPattern = new RegExp(`^${buyerName}$`);
      const match =
        customerList &&
        customerList.find((customer) =>
          regexPattern.test(customer.DisplayName)
        );
      if (match) {
        setResult({
          customer: match.DisplayName,
          id: match.Id,
        });
      } else {
        setResult({});
      }
    } else {
      setResult({});
    }
  };

  const addCustomer = async () => {
    try {
      const res = await api.post(
        "/integrations/quickbooksonline/addCustomer",
        { customerName: buyerName },
        {
          headers: {
            "x-access-token": auth.user.accessToken,
          },
        }
      );

      if (res.status == 200) {
        let obj = {
          customer: res.data.givenName,
          id: res.data.Id,
        };
        setResult(obj);

        let newCustomer = {
          DisplayName: res.data.givenName,
          GivenName: res.data.givenName,
          Id: res.data.Id,
          sparse: true,
        };
        customerList.push(newCustomer);
        setCustomerList(customerList);
      }
    } catch (error) {
      console.log("Error :", error);
    }
  };

  const formattedOptions = customerList.map((customer) => ({
    value: customer.DisplayName,
    label: customer.DisplayName,
    id: customer.Id,
  }));

  const handleSelect = (selectedOption) => {
    setResult({
      ...result,
      customer: selectedOption.value,
      id: selectedOption.id,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    handleSelect(selectedOption);
  };

  useEffect(() => {
    // Close the dropdown after any option is selected
    if (selectedOption) {
      handler(result, {});
      setIsOpen(false);
    }
  }, [selectedOption]);

  useEffect(() => {
    findCustomer();
  }, [customerList]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.post(
          "/integrations/quickbooksonline/getCustomer",
          {},
          {
            headers: {
              "x-access-token": auth.user.accessToken,
            },
          }
        );
        if (res.status == 200) {
          setCustomerList(res.data.Customer);
        }
      } catch (error) {
        console.log("Error :", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    findCustomer();
  }, [buyerName]);

  //close the customer list dropdown when click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(result).length > 0) {
      const customer = { val: result.customer, id: result.id };
      if (result.customer === buyerName) {
        setQBCustomer(customer);
      } else {
        setQBCustomer(customer);
      }
    } else {
      setQBCustomer({});
    }
  }, [result, buyerName]);
  return (
    <CustomDropdownWrapper
      style={{
        height: isOpen ? "410px" : "",
        visibility:docType === "Invoice" && showQB && buyerName ? "visible" : "hidden",
      }}

      // onClick={() => setIsOpen(true)}
    >
      <div className="logo" onClick={() => setIsOpen(true)}>
        {Object.keys(result).length > 0 ? (
          <img src={QBCheckLogo} className="qb" alt="QuickBook" />
        ) : (
          <img src={QBErrorLogo} className="qb" alt="QuickBook" />
        )}
      </div>

      {!isOpen && (
        <div className="titleBar" onClick={() => setIsOpen(true)}>
          {Object.keys(result).length > 0 ? (
            <>
              <div style={{ textAlign: "left" }}>
                <Tooltip title={result.customer} placement="top">
                  <p>{result.customer}</p>
                </Tooltip>
              </div>
              <div> {result.id && <p># {result.id}</p>}</div>
            </>
          ) : (
            <>
              <Tooltip title={buyerName} placement="top">
                <span className="buyerName">{buyerName}</span>
              </Tooltip>
              <div className="account_errror">
                {/* <img src={AlertIcon} className="alert" alt="alert" /> */}
                {/* Customer not found */}
                Add as new customer
              </div>
            </>
          )}
        </div>
      )}

      {!isOpen && (
        <div className="btn">
          {!Object.keys(result).length > 0 ? (
            <button
              className="add"
              onClick={addCustomer}
              // style={{ visibility: "hidden" }}
            >
              <AddIcon className="addIcon" />
            </button>
          ) : (
            <button className="arrow" onClick={() => setIsOpen(true)}>
              <KeyboardArrowDownIcon />
            </button>
          )}
        </div>
      )}

      {/* {!isOpen && (
        <div
          className="btn addBtn"
          style={{ position: "absolute", right: "187px" }}
        >
          {!Object.keys(result).length > 0 && (
            <button className="add" onClick={addCustomer}>
              <AddIcon className="addIcon" />
            </button>
          )}
        </div>
      )} */}

      {isOpen && (
        <Dropdown className="dropdown-options" ref={dropdownRef}>
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            options={formattedOptions}
            isSearchable={true}
            menuIsOpen={isOpen}
            placeholder="Select an customer..."
          />
        </Dropdown>
      )}
    </CustomDropdownWrapper>
  );
};

export default CustomSearchDropdown;
