// styling
import styled from "styled-components/macro";
import { colors, textSizes, flex, shadow } from "@styles/vars";

export const PopUpWrapper = styled.div`
  ${flex.col};
  ${flex.center};
  //   gap: 8px;
  position: absolute;
  left: 0;
  top: 0;
  height: 300px;
  width: 260px;
  display: none;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  ${shadow.boxShadow}
  z-index:2;

  .close {
    cursor: pointer;
    align-self: end;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  .body {
    flex: 1;
    width: 100%;
    ${flex.col};
    ${flex.center};
    gap: 14px;

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      width: 100%;
    }

    .select {
      border-radius: 4px;
      align-self: self-start;
      width: 150px;
    }

    .rowColContainer {
      display: flex;
      width: 250px;
      justify-content: space-around;

      .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 150px;
      }

      .rowInput {
        width: 80px;
      }
    }
  }
  .footer {
    ${flex.row};
    ${flex.center};
    width: 100%;
    height: 40px;
    justify-content: space-between;

    .delete {
      color: red;
      cursor: pointer;
      transition: transform 0.3s ease;
      &:hover {
        // transform: scale(1.1);
      }
    }
  }
`;
