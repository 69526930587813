import styled from "styled-components/macro";
import { colors, textSizes, flex, shadow } from "@styles/vars";

export const CustomDropdownWrapper = styled.div`
  width: 300px;
  min-height: 69px;
  // width: 250px;
  // height: 40px;
  border-radius: 10px;
  // background: #d9d9d9;
  background: linear-gradient(
    125deg,
    #bea9d0 1.93%,
    #d9cae4 43.61%,
    #fef7ff 96.53%
  );
  margin: 18px 5px;
  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 8px 0px;

  .logo {
    cursor: pointer;
    .qb {
      width: 50px;
      height: 50px;
    }
  }

  .titleBar {
    width: 160px;
    // padding: 3px;
    // margin: 0px 5px;
    // height: 35px;
    // overflow: hidden;
    font-size: 14px;
    color: #22215b;
    // font-size: 20px;
    font-weight: 600;
    cursor: pointer;

    div:first-child {
      height: 35px;
      // height: auto;
      // max-height: 35px;
      overflow: hidden;
    }

    .alert {
      width: 11.034px;
      height: 9px;
    }

    .account_errror {
      width: 130px;
      // margin-left: 7px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // color: #af0505;
      color: #294200;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      border-radius: 3px;
      // background: #fcb7b7;
      background: #67bf67;
    }

    .buyerName {
      color: #22215b;
      // font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      display: block;
      height: 33px;
      overflow: hidden;
    }
  }

  .btn {
    width: 50px;
    height: 50px;
    .add {
      // width: 47px;
      // height: 39px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #59d2fc;
      // margin: 5px;
      color: white;
      z-index: 5;

      transition: transform 0.2s ease-out;
      &:hover {
        transform: scale(1.1);
      }

      .addIcon {
        // font-size: 40px;
        transform: scale(1.6);
      }
    }

    .arrow {
      width: 50px;
      height: 50px;
      font-size: 40px;
      scale: 1.8;
      transform: rotate(360deg);
      color:  rgba(68, 43, 74, 0.99);
      
    }
  }
`;

export const Dropdown = styled.div`
  width: 220px;
  height: 215px;
  background: white;
  padding: 3px;
  border-radius: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .css-rtl-13cymwt-control {
    width: 220px !important;
  }

  .css-rtl-t3ipsp-control {
    width: 220px !important;
  }

  .css-rtl-1nmdiq5-menu {
    margin-bottom: 30px !important;
  }
`;
