import React from "react";
import styled from "styled-components";

const StyledSVG = styled.svg`
  &:hover .gradient,
  &:focus .gradient {
    fill: url(#paint0_linear_2734_5149);
  }
  &:hover .fillWhite,
  &:focus .fillWhite {
    fill: #fff;
  }
`;

const NewDocManagementIcon = ({ active }) => {
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 37 29"
      fill="none"
      className="icon"
    >
      <path
        className="fillWhite"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.94315 4.3433H18.9595L18.3265 2.14288C18.133 1.50212 17.7317 0.943983 17.186 0.556486C16.6402 0.168988 15.981 -0.0258291 15.3122 0.00275022H3.01402C2.21459 0.00275022 1.4479 0.320323 0.882615 0.885608C0.31733 1.45089 -0.00024414 2.21758 -0.00024414 3.01702V9.25655C-0.00025633 8.60879 0.127821 7.96742 0.376618 7.36934C0.625415 6.77126 0.99002 6.22829 1.44946 5.77165C1.90889 5.31502 2.45409 4.95374 3.05367 4.70861C3.65326 4.46347 4.2954 4.33932 4.94315 4.3433Z"
        fill={active ? "url(#paint0_linear_2734_5149)" : "white"}
      />
      <path
        className="gradient"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1567 29H3.01402C2.21459 29 1.4479 28.6824 0.882615 28.1171C0.31733 27.5518 -0.000244141 26.7851 -0.000244141 25.9857V7.35753C-0.000244141 6.5581 0.31733 5.79141 0.882615 5.22612C1.4479 4.66084 2.21459 4.34326 3.01402 4.34326H33.1567C33.9561 4.34326 34.7228 4.66084 35.2881 5.22612C35.8534 5.79141 36.171 6.5581 36.171 7.35753V25.9857C36.171 26.3815 36.093 26.7735 35.9415 27.1392C35.79 27.5049 35.568 27.8372 35.2881 28.1171C35.0082 28.397 34.6759 28.619 34.3102 28.7705C33.9445 28.922 33.5525 29 33.1567 29Z"
        fill={active ? "url(#paint0_linear_2734_5149)" : "white"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2734_5149"
          x1="-0.000244141"
          y1="4.34326"
          x2="33.8943"
          y2="14.9396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6804" />
          <stop offset="0.492708" stopColor="#E0192A" />
          <stop offset="1" stopColor="#A508A5" />
        </linearGradient>
      </defs>
    </StyledSVG>
  );
};

export default NewDocManagementIcon;
