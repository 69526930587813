import { Navigate } from "react-router";
import { useAuth } from "@contexts/AuthProvider";

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/auth/login" replace />;
    }
    return children;
};

export default ProtectedRoute;
