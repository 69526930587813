import { useLocalStorage } from "@hooks/dox/useLocalStorage";
import { createContext, useContext, useState } from "react";
import axios from "axios";
import instance from "@utils/instance";
import { toast } from "react-toastify";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  // loggedIn user profile info stored in userInfo state
  const [userInfo, setUserInfo] = useState({});
  // token info stored in user state
  const [user, setUser] = useLocalStorage("tokens", null);
  const [authError, setAuthError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const login = async (userData) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = {
      ...userData,
      timeZone:userTimezone
    }
    setAuthError("");
    setIsLoading(true);
    const config = {
      headers: { "Content-Type": "application/json" },
    };

    try {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const loginResp = await axios.post(baseUrl + "/auth/signin", data);
      setUser(loginResp.data);
    } catch (e) {
      if (e.response) {
        if (e.response.statusText === "Unauthorized") {
          // setAuthError("Wrong email or password.");  
          toast.error('Wrong email or password', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
        if (e.response.status === 404) {
          // setAuthError("User does not exist.");
          toast.error('User does not exist', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
        console.log(e.response);
      } else if (e.request) {
        console.log(e.request);
        // setAuthError("Something went wrong. Try again.");
        toast.error('Something went wrong. Try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      } else {
        console.log(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getUserInfo = async () => {
    try {
      const res = await instance.get("/user/getUserProfile", {
        headers: {
          "x-access-token": user.accessToken,
        },
      });
      setUserInfo(res.data);
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        // setError("No Network Connection");
        toast.error('No Network Connection', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
      console.log(e);
    }
  };

  const logOut = () => {
    // setUser(null);
    localStorage.removeItem("tokens");
    window.location.replace("/");
  };

  const isAdmin = () => user.role === "admin";
  const isSuperAdmin = () => user.role === "superAdmin";
  const values = {
    user,
    setUser,
    userInfo,
    setUserInfo,
    getUserInfo,
    login,
    logOut,
    authError,
    setAuthError,
    error,
    isLoading,
    isAdmin,
    isSuperAdmin,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
