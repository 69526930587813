import React from "react";

const AccountIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 238 233"
      fill="none"
      className="icon"
    >
      <defs>
        <linearGradient id="linearGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#ed6804" />
          <stop offset="50%" stopColor="#e0192a" />
          <stop offset="100%" stopColor="#a508a5" />
        </linearGradient>
      </defs>
      <rect
        // className="gradient"
        width="238"
        height="233"
        rx="116.5"
        fill="white"
      />
      <path
        className="gradient"
        d="M193.066 151.748C192.891 162.282 188.57 172.323 181.04 179.692C173.511 187.061 163.38 191.165 152.844 191.113H138.945C133.85 191.125 128.802 190.126 124.096 188.173C119.39 186.22 115.118 183.353 111.527 179.737C106.032 174.318 102.276 167.385 100.738 159.822C99.2001 152.26 99.9498 144.41 102.892 137.275C105.833 130.14 110.834 124.044 117.255 119.763C123.677 115.482 131.228 113.211 138.945 113.24H154.225C164.436 113.239 174.237 117.259 181.506 124.43C188.776 131.602 192.929 141.347 193.066 151.558V151.748Z"
        fill="white"
        stroke="#9A9A9A"
        strokeWidth="8"
        strokeMiterlimit="10"
      />
      <path
        className="gradient"
        d="M146.609 92.6288C160.332 92.6288 171.456 81.5044 171.456 67.7816C171.456 54.0589 160.332 42.9344 146.609 42.9344C132.886 42.9344 121.762 54.0589 121.762 67.7816C121.762 81.5044 132.886 92.6288 146.609 92.6288Z"
        fill="white"
        stroke="#9A9A9A"
        strokeWidth="8"
        strokeMiterlimit="10"
      />
      <path
        className="gradient"
        d="M111.528 179.88C108.658 183.428 105.032 186.291 100.914 188.259C96.7965 190.227 92.2915 191.251 87.7277 191.256H75.7801C67.7713 191.246 60.0823 188.113 54.3486 182.521C48.6149 176.93 45.2889 169.322 45.0781 161.316C45.1155 157.146 45.9737 153.025 47.6038 149.187C49.234 145.35 51.6041 141.87 54.5788 138.949C57.5536 136.027 61.0747 133.72 64.9411 132.159C68.8075 130.598 72.9435 129.814 77.1129 129.852H87.8705C93.766 129.804 99.5505 131.456 104.531 134.612C100.807 141.925 99.4932 150.228 100.776 158.333C102.058 166.439 105.872 173.931 111.671 179.737L111.528 179.88Z"
        fill="white"
        stroke="#9A9A9A"
        strokeWidth="8"
        strokeMiterlimit="10"
      />
      <path
        className="gradient"
        d="M81.6334 113.287C92.4907 113.287 101.292 104.486 101.292 93.6284C101.292 82.7711 92.4907 73.9696 81.6334 73.9696C70.7762 73.9696 61.9746 82.7711 61.9746 93.6284C61.9746 104.486 70.7762 113.287 81.6334 113.287Z"
        fill="white"
        stroke="#9A9A9A"
        strokeWidth="8"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default AccountIcon;
