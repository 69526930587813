// styling
import styled from "styled-components/macro";
import { flex, textSizes,breakpoints } from "@styles/vars";

// components
import Field from "@ui/Field";

// utils
import PropTypes from "prop-types";
import LoginField from "@ui/Field/loginInput";

const Wrapper = styled.div`
  ${flex.col};
  position: relative;
  width: 100%;
  // width: 325px;
  // margin: 5px 0;
  i {
    position: absolute;
    top: 30px;
  }

  ${breakpoints.desktop}{
    width: 400px;
  }
`;

const Label = styled.label`
  font-size: ${textSizes["14"]};
  width: fit-content;
  margin-bottom: 8px;
`;
// const LoginFormInput = ({ id, handler, type = "text", placeholder, value = "", icons }) => {
const LoginFormInput = ({
  id,
  handler,
  type = "text",
  placeholder,
  value = "",
  icons,
}) => {
  return (
    <Wrapper>
      {/* <Label htmlFor={id}>{title}</Label> */}
      <i className={`icon ${icons}`}></i>
      {/* <Field type={type} value={value} id={id} placeholder={placeholder} handler={handler} /> */}
      <LoginField
        type={type}
        value={value}
        id={id}
        placeholder={placeholder}
        handler={handler}
      />
    </Wrapper>
  );
};

LoginFormInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "password", "email", "number"]),
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoginFormInput;
