// styling
import styled from "styled-components/macro";
import { colors, textSizes, fonts, flex, shadow } from "@styles/vars";
import { darken } from "polished";

// utils
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

const Button = styled.button`
  // width: 100%;
  height: 40px;
  font-size: 0.875rem;
  // background-color: ${colors.white};
  padding: 8px 14px;
  ${shadow.borderShadow};
  border-radius: 10px;
  transition: color var(--transition), background-color var(--transition);
  transition: transform 0.2s ease-in;
  /* color: #3e4041;

  background: linear-gradient(
    125.49deg,
    #bea9d0 1.93%,
    #d9cae4 43.61%,
    #fef7ff 96.53%
  ); */
  /* background: var(
    --brand,
    linear-gradient(127deg, #fc9132 0.01%, #fa0136 49%, #b711c4 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: crimson;
  text-transform: capitalize;
  /* color: #3e4041; */
  font-weight: 600;
  /* border-right: 1px solid #e2dddd; */
  /* border-radius: 15px; */
  border: 1px solid #fff;

  background: #fff;

  box-shadow: -9px 9px 10px 0px rgba(0, 0, 0, 0.25);

  font-family: ${fonts.body};

  .icon {
    // color: ${colors.gray};
    color: #3e4041;
    color: crimson;
    transition: color var(--transition);
    // border-right: 1px solid #ddd;
    border-right: 1px solid #837d7d;
    padding-right: 8px;
    margin-right: 8px;
  }
  background: #fff;
  .text {
    // display: none;
  }

  &:hover {
    // background-color: ${colors.white};
    color: #777;
    transform: scale(0.9);

    .icon {
      color: #777;
    }
  }
  &.error {
    background-color: ${colors.error};

    // &:hover, &:focus {
    //   background-color: ${darken(0.1, colors.error)};
    // }
  }

  &.disabled {
    /* background-color: ${colors.gray}; */
    background: #c8c6c6;
    color: #3e4041;
    pointer-events: none;
    box-shadow: none;
    border: none;
  }
  &.disable {
    background: #c8c6c6;
    color: #3e4041;
    pointer-events: none;
    box-shadow: none;
    border: none;
  }
  &.primary {
    background-color: ${colors.sidebar_bg};
    color: #3e4041;
    box-shadow: 0 1px 6px 4px rgb(145 145 146 / 29%);

    .icon {
      color: #3e4041;
    }
  }
`;

const DoxBtn = ({
  text,
  handler,
  type = "button",
  isVisible = true,
  className,
  icon,
  iconStyles,
  style,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Button
          as={motion.button}
          className={className ? className : ""}
          onClick={handler}
          type={type}
          initial={false}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={style}
        >
          {icon && <i className={`icon icon-${icon}`} style={iconStyles} />}{" "}
          {text}
        </Button>
      )}
    </AnimatePresence>
  );
};

DoxBtn.propTypes = {
  text: PropTypes.string.isRequired,
  handler: PropTypes.func,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
};

export default DoxBtn;
