import React, { useState, useEffect } from "react";
import { MainContainer, CentralContainer } from "./style";
import logoLight from "@assets/logo/logo-light.png";
import CheckIcon from "@fonts/menu_icons/checkIcon";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import EmailErrorIcon from "@fonts/menu_icons/errorIcon";
import WidgetsLoader from "@components/WidgetsLoader";

const VerifyEmail = () => {
  const [verified, setVerified] = useState(null);
  const [serachParams] = useSearchParams();
  const id = serachParams.get("id");
  const token = serachParams.get("token");

  const payload = {
    userId: id,
    token: token,
  };

  const verifyEmail = async () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    try {
      const res = await axios.post(`${baseURL}/auth/verifyEmail`, payload);
      if (res.status === 200) {
        setVerified(true);
      }else{
        setVerified(false);
      }
    } catch (e) {
      if (e.response) {
        setVerified(false);
        console.log(e.response);
      } else {
        console.log(e);
        setVerified(false);
      }
    }
  };

  useEffect(() => {
    verifyEmail();
  }, [id, token]);

  return (
    <MainContainer>
      <CentralContainer>
        {verified === null ? (
          <WidgetsLoader />
        ) : (
          <>
            <img
              src={logoLight}
              alt="Docxster-logo"
              className="docxster_logo"
            />

            <div className="content">
              <div className="logo">
                {verified ? <CheckIcon /> : <EmailErrorIcon />}
              </div>

              {verified ? (
                <>
                  <p className="heading">Your email is verified</p>
                  <p className="text-blur">
                    Your email has been successfully verifed.
                  </p>
                  <p className="text-blur">Click below to log in magically.</p>
                </>
              ) : (
                <>
                  <p className="heading">Your email is not verified</p>
                  <p className="text-blur">
                    Your email has not been successfully verified.
                  </p>
                  <p className="text-blur"> Please recheck the email.</p>
                </>
              )}
              <button className="email_btn">
                <Link to="/auth/login">Continue</Link>
              </button>
            </div>
          </>
        )}
      </CentralContainer>
    </MainContainer>
  );
};

export default VerifyEmail;
