import { useState, useRef, forwardRef } from "react";
import styled from "styled-components/macro";

const TextArea = styled.textarea`
  border: 1px solid black !important;
`;

const EditableMultiline = ({
  text,
  name,
  className,
  handler,
  editable = true,
  children,
  style,
  id
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const dispText = text ? text : "NA";

  const handleKey = (e) => {
    if (e.key === "Enter") {
      setIsEdit(false);
      handler(e);
    }
  };

  const onBlurHandle = (e) => {
    setIsEdit(false);
    handler(e);
  };
  const editHandler = () => {
    setIsEdit(true);
  };

  const setHeight = (e) => {
    const inputHeight =
      e.target.scrollHeight > 80 ? "80px" : e.target.scrollHeight + "px";
  };
  return (
    <>
      {isEdit ? (
        <TextArea
          className={className}
          defaultValue={dispText}
          name={name}
          id={id}
          onKeyDown={handleKey}
          onBlur={onBlurHandle}
          autoFocus
          style={style}
          // style={{
          //   border: "1px solid",
          //   // overflowY: "scroll",
          //   // maxHeight: "84px",
          // }}
        ></TextArea>
      ) : (
        <span
          className={className}
          onClick={editable && editHandler}
          style={style}
        >
          {dispText}
        </span>
      )}
    </>
  );
};

export default EditableMultiline;
