import React, { useRef, useState } from "react";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import styled from "styled-components/macro";
import { flex } from "@styles/vars";

const BoxWrapper = styled.div`
  ${flex.row}
  justify-content:space-between;
  align-items: center;
  padding: 5px 10px;
  border: 2px solid #d9bcd9;
  background: #f1e8f1;
  border-radius: 5px;
  margin:5px 0px;
  .copied {
    padding: 4px;
    background: #8519b9;
    border-radius: 4px;
    &:hover {
      scale: 0.9;
    }
  }
  .copy {
    color: #fff;
    height: 20px;
    width: 20px;
  }
  .done {
    color: #fff;
    height: 20px;
    width: 20px;
  }
`;

const CopyToClipboard = ({ value }) => {
  const [isCopied, setIsCopied] = useState(false);
  const mailRef = useRef("");

  const handleCopyClick = () => {
    setIsCopied(true);
    const email = mailRef.current.innerText;
    copy(email);

    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <BoxWrapper>
      <p className="email" ref={mailRef}>
        {value}
      </p>
      <button className="copied" onClick={handleCopyClick}>
        {isCopied ? (
          <LibraryAddCheckIcon className="done" />
        ) : (
          <ContentCopyIcon className="copy" />
        )}
      </button>
    </BoxWrapper>
  );
};

export default CopyToClipboard;
